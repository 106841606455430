import { MutationTree } from 'vuex'
import State, { Catalog, Site } from './types'
import { Division } from './editor-divisions/types'
import { Route } from 'vue-router'
import { blankDivision, blankSite, loadingCatalog, loadingDivision, loadingSite } from './constants'
import { Schedule } from './editor-schedules/types'

export const mutations: MutationTree<State> = {
  setActiveRoute: (state: State, { route }: { route: Route }) => { state.activeRoute = route },
  catalogsLoading(state: State) {
    state.error = false
    state.catalogs = loadingCatalog
  },
  catalogsLoaded(state: State, { catalogs }: { catalogs: Catalog[] }) {
    state.error = false
    state.catalogs = catalogs
  },
  catalogsError(state) {
    state.error = true
    state.catalogs = []
  },
  divisionsLoading(state: State) {
    state.error = false
    state.divisions = loadingDivision
  },
  divisionsLoaded(state: State, { divisions }: { divisions: Division[] }) {
    state.error = false
    state.divisions = divisions
  },
  divisionsError(state) {
    state.error = true
    state.divisions = []
  },
  sitesLoading(state: State) {
    state.error = false
    state.sites = loadingSite
  },
  sitesLoaded(state: State, { sites }: { sites: Site[] }) {
    state.error = false
    state.sites = sites
  },
  schedulesLoaded(state: State, { schedules }: { schedules: Schedule[] }) {
    state.error = false
    state.schedules = schedules
  },
  sitesError(state) {
    state.error = true
    state.sites = []
  },
  siteLoading(state: State) {
    state.error = false
    state.site = blankSite
  },
  siteLoaded(state: State, { site }: { site: Site }) {
    state.error = false
    state.site = site
  },
  siteError(state) {
    state.error = true
    state.site = blankSite
  },
  setDivision(state, { divisionName }) {
    state.division = state.divisions.find(division => division.DivisionName === divisionName) || blankDivision
  },
  setPreloaded(state, { value }) {
    state.preloaded = value
  },
}
