import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import State from './types'
import { RootState } from '../types'
import {
  productLoading,
  imageLoading,
  modelNone,
  activeSectionNone,
  sectionTableNone,
  tableLayoutNone,
} from './constants'
import { activeSectionTabNone } from '../editor-section/constants'

export const state: State = {
  product: productLoading,
  images: [imageLoading],
  Error: false,
  sideBarModel: {
    visible: false,
    model: modelNone,
    tableLayout: tableLayoutNone,
    sectionTable: sectionTableNone,
  },
  showPrintView: false,
  activeSection: activeSectionNone,
  activeSectionTab: activeSectionTabNone,
  activeImage: imageLoading,
}

const namespaced = true

export const product: Module<State, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
