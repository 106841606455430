import Vue from 'vue'
import Router from 'vue-router'
import App from './App.vue'

import UniqueId from 'vue-unique-id'

import './sass/theme.scss'
import './sass/fractional-grid.min.css'

import { BootstrapVue, BootstrapVueIcons, ToastPlugin } from 'bootstrap-vue'
import { Store } from 'vuex'
import State from './store/types'
import { PluginFunction } from 'vue/types/plugin'

import BreakPoints from '@/common/utilities/breakpoints'

import PortalVue from 'portal-vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

export async function createApp({ router, store, plugins }: { router: Router, store: Store<State>, plugins: VuePluginListItem[] }) {
  Vue.config.productionTip = false

  Vue.use(BootstrapVue)
  Vue.use(BootstrapVueIcons)
  Vue.use(ToastPlugin)

  Vue.use(BreakPoints)

  Vue.use(PortalVue)

  Vue.use(UniqueId)

  Vue.use(VueReCaptcha, {
    siteKey: '6LebNf8lAAAAAAhveYgkWOLclg51VcqBG7Geqd1i',
    loaderOptions: {
      autoHideBadge: true,
    },
  })

  if (plugins) {
    plugins.forEach(plugin => Vue.use(plugin.plugin, plugin.options))
  }

  return new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
}

export interface VuePluginListItem {
  plugin: PluginFunction<any>
  options: any
}
