<template>
  <div>
    <div class="d-flex w-100 justify-content-center align-items-center">
      <strong>{{ loadingText }}</strong>
      <b-spinner small type="grow" class="ml-2 spinner"></b-spinner>
      <b-spinner small type="grow" class="spinner"></b-spinner>
      <b-spinner small type="grow" class="spinner"></b-spinner>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class extends Vue {
  @Prop()
  public loadingText!: string
}
</script>

<style>
.spinner {
  height: 0.5rem !important;
  width: 0.5rem !important;
}
</style>
