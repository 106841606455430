import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import State from './types'
import { RootState } from '../types'
import { loadingCache } from './constants'

export const state: State = {
  error: false,
  caches: loadingCache,
}

const namespaced = true

export const cache: Module<State, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
