import { ArrayLoading } from '@/store/types'

import { blankEntityBase, loading, none } from '@/store/constants'
import { ActiveNone } from '../editor/types'

import { active, current, blankEditBase, blankImage, blankDocument } from '@/store/editor/constants'

import {
  Section, SectionTab, SectionTable, SectionList, SectionRichText, ActiveSectionTab,
  TableLayout, TableLayoutColumn,
  CurrentTableLayout,
  SectionImage, SectionSpinSirv, SectionDocument,
} from './types'

import _ from 'lodash'

// these constants are useful so we don't constantly define them over and over

export const activeNone: ActiveNone = _.assign({ }, blankEntityBase, active, none)
// export const currentNone: CurrentNone = _.assign({}, current, { isNone: true }, none)
export const loadingSection: ArrayLoading<Section> = _.assign([], loading)

export const loadingTableLayout: ArrayLoading<TableLayout> = _.assign([], loading)

export const activeNew: ActiveNone = _.assign({}, blankEntityBase, active, none, { Created: true })

// TODO: default to having PCN and Model
export const blankTableLayout: TableLayout = { SectionTableLayoutId: -1, DisplayName: '', Columns: [], hovered: false }

export const blankTableLayoutColumn: TableLayoutColumn = {
  SectionTableLayoutColumnId: -1,
  EntityName: '',
  EntityPropertyName: '',
  DisplayName: '',
  Name: '',
  TableName: '',
  ColumnName: '',
  PropertyName: '',
  ParentPropertyName: '',
  DisplayDescription: '',
  SortBy: false,
  FilterBy: false,
  KeywordSearchBy: false,
  ShowOnMobile: false,
  PropertyType: 'String',
  OrderBy: -1,
  showEdit: false,
  key: '',
}

export const blankSection: Section = _.assign({ SectionId: 0, CategoryId: -1, ProductClassId: -1, ManufacturingSeriesId: -1, ProductId: -1, Name: '', DisplayName: '', MobileDisplayName: '', OrderBy: -1, Visible: true, Tabs: [], Catalogs: [] }, blankEditBase)
export const blankSectionTab: SectionTab = _.assign({ SectionTabId: 0, Name: '', DisplayName: '', MobileDisplayName: '', Items: [], Catalogs: [], OrderBy: -1, Visible: true, active: true }, blankEditBase)
export const blankSectionTable: SectionTable = _.assign({ SectionItemType: 'ModelTable', SectionTableId: -1, DisplayName: '', DetailsPanel: true, Catalogs: [], OrderBy: -1, Visible: true, HideBlankColumns: true, HideTableFilters: false, Models: [], ProductModels: [], TableLayout: blankTableLayout, Cols: 12, key: 'sectionTable--1', HasCart: false, ShowInventory: false, $type: 'SectionTable' }, blankEditBase)
export const blankSectionList: SectionList = _.assign({ SectionItemType: 'List', SectionListId: -1, DisplayName: '', Catalogs: [], OrderBy: -1, Visible: true, PropertyName: 'Items', Items: [], Cols: 12, key: 'sectionList--1', $type: 'SectionTable' }, blankEditBase)
export const blankSectionImage: SectionImage = _.assign({ SectionItemType: 'Image', SectionImageId: -1, DisplayName: '', Catalogs: [], OrderBy: -1, Visible: true, Caption: '', Cols: 12, Width: 0, Height: 0, OriginalWidth: 0, OriginalHeight: 0, key: 'sectionImage--1', ExplodedView: false, $type: 'SectionTable' }, blankEditBase, blankImage)
export const blankSectionDocument: SectionDocument = _.assign({ SectionItemType: 'Document', SectionDocumentId: -1, DisplayName: '', Catalogs: [], OrderBy: -1, Visible: true, Caption: '', Cols: 12, key: 'sectionDocument--1', $type: 'SectionTable' }, blankEditBase, blankDocument)
export const blankSectionRichText: SectionRichText = _.assign({ SectionItemType: 'RichText', SectionRichTextId: -1, DisplayName: '', Catalogs: [], OrderBy: -1, Visible: true, RichText: '', Cols: 12, key: 'sectionRichText--1', $type: 'SectionTable' }, blankEditBase, blankImage)
export const blankSectionSpinSirv: SectionSpinSirv = _.assign({ SectionItemType: 'SpinSirv', SectionSpinSirvId: -1, DisplayName: '', Catalogs: [], OrderBy: -1, Visible: true, Url: '', Cols: 12, key: 'sectionSpinSirv--1', $type: 'SectionTable' }, blankEditBase, blankImage)

// export const sectionNone: Section = _.assign({}, blankSection, none)
// export const sectionTabNone: SectionTab = _.assign({}, blankSectionTab, none)
// export const sectionTableNone: SectionTab = _.assign({}, blankSectionTable, none)
// export const sectionListNone: SectionList = _.assign({}, blankSectionList, none)

export const activeSectionTabNone: ActiveSectionTab = _.assign({}, blankSectionTab, active, none)

export const currentTableLayout: CurrentTableLayout = _.assign({}, blankTableLayout, current, none)

export const loadingSectionTable = _.assign({}, blankSectionTable, loading)
