<template>
  <div class="loading-cover" v-show="isLoading">
    <div class="loading-container-cover">
      <b-spinner
        style="width: 5rem; height: 5rem; border-width: 0.4rem"
        label="Large Spinner"
        class="c-spinner"
      >
      </b-spinner>
      <img :src="site.FaviconPath" alt="white_logo" class="logo" v-if="site.FaviconPath"/>
      <h4 v-if="loadingText" class="text">{{ loadingText }}</h4>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from 'vue-property-decorator'
import MxSite from '@/mixins/site'

@Component
export default class LoadingCover extends Mixins(MxSite) {
  @Prop({ type: Boolean, required: true }) isLoading!: boolean
  @Prop({ type: String }) loadingText!: string

  public logoWhite = require('@/assets/images/tpi_logo_white.png')
}
</script>

<style scoped lang="scss">
.loading-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* semi-transparent black background */
  z-index: 9999; /* ensure the overlay is on top of other elements */
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-container-cover {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
  }

  .c-spinner {
    color: #0b67b2;
  }

  .logo {
    height: 50px;
    width: auto;
    position: absolute;
  }

  .text {
    position: absolute;
    color: #fff;
    padding-top:150px;
  }
}
</style>
