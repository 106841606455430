<template>
  <b-breadcrumb v-if="!(hideBreadcrumbs === true) && catalog.HasBreadcrumb && !screen.lt.md">
    <b-breadcrumb-item
      v-for="(crumb, index) in breaddata"
      :key="index"
      :to="crumb.route"
      :active="!crumb.active">
      {{ crumb.displayName }}
    </b-breadcrumb-item>
  </b-breadcrumb>
</template>

<script lang="ts">

import {
  ManufacturingSeries as BaseManufacturingSeries,
  ProductClass as BaseProductClass,
  Category as BaseCategory,
} from '@/store/types'

import { Category as MenuCategory, ProductClass as MenuProductClass } from '@/store/menu/types'

import { ProductClass as ProductProductClass, ManufacturingSeries as ProductManufacturingSeries, Product } from '@/store/product/types'

import { Component, Watch, Mixins } from 'vue-property-decorator'

import { namespace } from 'vuex-class'

import { Dictionary } from 'vue-router/types/router'
import { blankCategory } from '@/store/editor/constants'

import MxSite from '@/mixins/site'

const nsCommon = namespace('common')
const nsProduct = namespace('product')

const nsMenu = namespace('menu')
@Component({
  components: {
  },
})
export default class extends Mixins(MxSite) {
  public breaddata: any[] = []

  @nsMenu.State('categories')
  public categories!: MenuCategory[]

  @nsProduct.State('product')
  public product!: Product

  @nsCommon.State('hideBreadcrumbs')
  public hideBreadcrumbs?: boolean

  public mounted() {
    this.getBreadcrumb()
  }

  public getBreadcrumb() {
    // let url = ''
    const { name, params: currentParams } = this.$route
    const { product, catalogId, isPreview } = this

    // const routes = []
    let route = {}
    const newParams: Dictionary<string> = {}

    const newBreadData: any[] = []
    let showHome = true
    let displayName = ''
    let params: Dictionary<string> = {}
    let active = false

    let manufacturingSeries: BaseManufacturingSeries | undefined
    let productClass: BaseProductClass | undefined
    let category: BaseCategory | undefined
    // let manufacturingSeries = {}

    switch (name) { // note: yes two switches, this one is for the references, the other one is for the breadcrumb entries
      case 'menu-page-productclass':
      case 'menu-page-category':
        if (currentParams.category) {
          category = this.categories ? this.categories.find(c => c.Name === currentParams.category) : blankCategory

          if (currentParams.productclass && category) {
            productClass = (category as MenuCategory).ProductClasses.find(c => c.Name === currentParams.productclass)
            if (currentParams.series && productClass) {
              manufacturingSeries = (productClass as MenuProductClass).ManufacturingSeries.find(c => c.Name === currentParams.series)
            }
          }
        }
        break
    }

    switch (name) {
      case 'product':
        if (!product || !manufacturingSeries || !productClass || !category) { break }
        active = false
        showHome = false
        if (currentParams.pcnCatalogNumber) {
          newParams.pcnCatalogNumber = currentParams.pcnCatalogNumber
          displayName = currentParams.pcnCatalogNumber
          params = { ...newParams }
          newBreadData.push({ route: { name: 'product', params }, displayName, active })
        }

        if (currentParams.tab) {
          const sectionTab = product.Sections.flatMap(s => s.Tabs).find(t => t.Name === currentParams.tab)
          newParams.tab = currentParams.tab
          displayName = sectionTab ? sectionTab.DisplayName : currentParams.tab
          params = { ...newParams }
          newBreadData.push({ route: { name: 'product', params }, displayName, active })
        }

        newParams.product = product.Name
        displayName = product.DisplayName
        route = this.getTopologyToByNames(catalogId, isPreview, category.Name, productClass.Name, manufacturingSeries.Name, product.Name)

        newBreadData.push({ route, displayName, active })

        // if (!product.ManufacturingSeries) { break }
        manufacturingSeries = product.ManufacturingSeries
        if (manufacturingSeries) {
          productClass = (manufacturingSeries as ProductManufacturingSeries).ProductClass
          if (productClass) {
            category = (productClass as ProductProductClass).Category
          }
        }
        active = true
      /* falls through */
      case 'menu-page-series':
        if (manufacturingSeries) {
          active = false
          newParams.series = manufacturingSeries.Name
          displayName = manufacturingSeries.DisplayName
          params = { ...newParams }
          newBreadData.push({ route: { name: 'menu-page-category', displayName: product.DisplayName, params }, displayName, active })
        }
        showHome = false
        active = true
      /* falls through */
      case 'menu-page-productclass':
        if (category && productClass) {
          newParams.productclass = productClass.Name
          displayName = productClass.DisplayName
          route = this.getTopologyToByNames(catalogId, isPreview, category.Name, productClass.Name)
          newBreadData.push({ route, displayName, active })
        }
        showHome = false
        active = true
      /* falls through */
      case 'menu-page-category':
        if (category) {
          // active = false
          newParams.category = category.Name
          displayName = category.DisplayName
          newBreadData.push({ route: { name: 'home' }, displayName, active })
        }
        showHome = false
        break
      case 'search':
        if (currentParams.keyword) {
          if (currentParams.page) {
            displayName = (currentParams.page ? currentParams.page : '1')
            newBreadData.push({ route: { name: 'search' }, displayName, active })
          }

          displayName = '"' + currentParams.keyword + '"'
          newBreadData.push({ route: { name: 'search' }, displayName, active })
        }

        displayName = 'Search Results'
        newBreadData.push({ route: { name: 'search' }, displayName, active })

        break
      case 'editor-topology-item':
        newBreadData.push({ route: { name: 'editor-topology-item' }, displayName: 'Item', active: false })
        newBreadData.push({ route: { name: 'editor-edit' }, displayName: 'Edit Topology', active: false })
        newBreadData.push({ route: { name: 'editor' }, displayName: 'Editor', active: false })
        break
      case 'editor-topology-section':
        newBreadData.push({ route: { name: 'editor-topology-section' }, displayName: 'Sections', active: false })
        newBreadData.push({ route: { name: 'editor-edit' }, displayName: 'Edit Topology', active: false })
        newBreadData.push({ route: { name: 'editor' }, displayName: 'Editor', active: false })
        break
      case 'editor-topology-models':
        newBreadData.push({ route: { name: 'editor-topology-models' }, displayName: 'Models', active: false })
        newBreadData.push({ route: { name: 'editor-edit' }, displayName: 'Edit Topology', active: false })
        newBreadData.push({ route: { name: 'editor' }, displayName: 'Editor', active: false })
        break
      case 'editor-fields':
        newBreadData.push({ route: { name: 'editor-fields' }, displayName: 'Edit Fields', active: false })
        newBreadData.push({ route: { name: 'editor' }, displayName: 'Editor', active: false })
        break
      case 'editor-reports':
        newBreadData.push({ route: { name: 'editor-reports' }, displayName: 'Reports', active: false })
        newBreadData.push({ route: { name: 'editor' }, displayName: 'Editor', active: false })
        break
      case 'editor-multiple-model-editor':
        newBreadData.push({ route: { name: 'editor-multiple-model-editor' }, displayName: 'Edit Multiple Models', active: false })
        newBreadData.push({ route: { name: 'editor' }, displayName: 'Editor', active: false })
        break
      case 'cart':
        newBreadData.push({ route: { name: 'cart' }, displayName: 'Shopping Cart', active })
        active = true
        break
      case 'checkout':
        newBreadData.push({ route: { name: 'checkout' }, displayName: 'Checkout', active: false })
        newBreadData.push({ route: { name: 'cart' }, displayName: 'Cart', active: true })
        active = true
        break
      case 'order_summary':
        newBreadData.push({ route: { name: 'order_summary' }, displayName: 'Order Summary', active: false })
        active = true
        break
      case 'customer':
        newBreadData.push({ route: { name: 'customer' }, displayName: 'Customer Details', active: false })
        active = true
        break
      case 'new-order':
        displayName = 'New Order'
        newBreadData.push({ route: { name: 'new_order' }, displayName, active: false })
        active = true
        break
      case 'user-profile':
        displayName = 'Profile'
        newBreadData.push({ route: { name: 'user-profile' }, displayName: 'Profile', active: false })
        active = true
        break
      case 'sales-rep-list':
        displayName = 'Sales Reps'
        newBreadData.push({ route: { name: 'sales-rep-list' }, displayName: 'Sales Reps', active: false })
        active = true
        break
      case 'sales-rep-profile':
        displayName = 'Sales Rep'
        newBreadData.push({ route: { name: 'sales-rep-profile' }, displayName: 'Details', active: false })
        newBreadData.push({ route: { name: 'sales-rep-list' }, displayName: 'Sales Reps', active: true })
        active = true
        break
      case 'sales-rep-profile-single':
        displayName = 'Sales Rep'
        newBreadData.push({ route: { name: 'sales-rep-profile-single' }, displayName: 'Sales Rep Details', active: false })
        newBreadData.push({ route: { name: 'user-profile' }, displayName: 'Profile', active: true })
        active = true
        break
      case 'list-inquiry':
        displayName = 'Inquiries'
        newBreadData.push({ route: { name: 'list-inquiry' }, displayName: 'Inquiries', active: false })
        active = true
        break
      case 'inquiry_summary':
        newBreadData.push({ route: { name: 'inquiry_summary' }, displayName: 'Inquiry Summary', active: false })
        newBreadData.push({ route: { name: 'list-inquiry' }, displayName: 'Inquiries', active: true })
        active = true
        break
      case 'new-inquiry':
        displayName = 'New Inquiry'
        newBreadData.push({ route: { name: 'new-inquiry' }, displayName, active: false })
        newBreadData.push({ route: { name: 'list-inquiry' }, displayName: 'Inquiries', active: true })
        active = true
        break
      case 'customers':
        displayName = 'Customers'
        newBreadData.push({ route: { name: 'customers' }, displayName, active: false })
        active = true
    }

    displayName = 'Home'
    if (showHome) {
      newBreadData.push({ route: { name: 'home' }, displayName, active })
    }

    this.breaddata = newBreadData.reverse()
    // const routeItems = this.$route.path.split('/')

    // const isSearch = (routeItems.length > 0 && routeItems[0] === 'search')

    // routeItems.forEach((item, i) => {
    //   if (item !== '' && item !== '0' && item !== '1') {
    //     const name = (isSearch && i === 0) || this.nameLookup[item] ? this.nameLookup[item] : item
    //     const bdata = item.replace(/%20/g, ' ')
    //     url += '/' + bdata
    //     const breadItem = {
    //       name,
    //       page: url,
    //     }
    //     this.breaddata.push(breadItem)

    //   }
    // })
  }

  @Watch('$route.params', { deep: true })
  @Watch('product')
  @Watch('categories')
  public handleReload() {
    this.breaddata = []
    this.getBreadcrumb()
  }
}
</script>
<style lang="scss">
.breadcrumb {
  background-color: #fff !important;
}

@media print {
  .breadcrumb {
    display: none !important;
  }
}
</style>
