<template>
  <b-modal title="Exploded 3D View"
    :visible="activeImage.SectionImageId > 0" size="xl" centered @hide="closeEdit"
    dialog-class="modal-fullscreen" content-class="modal-fullscreen"
    >
    <!-- TODO: replace this? -->
    <div class="drag-container"
      @mousedown="mouseDown" @mouseup="mouseUp" @mousemove="mouseMove"
    >
      <b-img :fluid="fluid" v-b-tooltip.hover
        :src="activeImage.ImagePath + '?width=' + activeImage.OriginalWidth + '&height=' + activeImage.OriginalHeight"
        :alt="'' + activeImage.SectionImageId"
        :width="activeImage.OriginalWidth"
        :height="activeImage.OriginalHeight"
        class="selectDisable"
        draggable="false"
      />
    </div>
    <template v-slot:modal-footer="{ }">
      <b-button size="sm" variant="primary" @click="fluid = !fluid">
        Toggle Zoom <b-icon :icon="fluid ? 'zoom-in' : 'zoom-out'" />
      </b-button>
      <b-button size="sm" variant="primary" @click="closeEdit">
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Component, Vue, Emit } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Image } from '@/store/product/types'
import { blankSectionImage } from '@/store/product/constants'
const nsProduct = namespace('product')

@Component({
})

export default class extends Vue {
  private fluid = true
  private pos = { top: 0, left: 0, x: 0, y: 0 }
  private mouseIsDown = false

  @nsProduct.State('activeImage')
  private activeImage!: Image

  @nsProduct.Mutation('setSelectedImage')
  private setSelectedImage: any

  // @Watch('activeImage.SectionImageId')
  // public ImageActived(SectionImageId: number) {
  //   if (SectionImageId) {
  //     // this.WatchExplodedView = true
  //     return
  //   }
  //   this.WatchExplodedView = false
  // }

  private mouseDown(e: any) {
    const ele = e.target.parentElement
    // TODO: how to get ele?
    this.pos = {
      // The current scroll
      left: ele.scrollLeft,
      top: ele.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    }
    // TODO: add Move and [Up?] listener?
    this.mouseIsDown = true
  }

  private mouseUp() {
    this.mouseIsDown = false
  }

  private mouseMove(e: any) {
    if (!this.mouseIsDown) {
      return
    }

    const ele = e.target.parentElement
    const { pos } = this

    const dx = e.clientX - pos.x
    const dy = e.clientY - pos.y

    // Scroll the element
    ele.scrollTop = pos.top - dy
    ele.scrollLeft = pos.left - dx
  }

  @Emit('closeEdit')
  private closeEdit() {
    // this.WatchExplodedView = false
    this.setSelectedImage({ image: blankSectionImage })
    // return false
  }
}
</script>
<style scoped>
  .selectDisable {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .drag-container {
    cursor: grab;
    overflow: auto;
    height: 80vh!important;
  }

  .modal-fullscreen {
    width: 100% !important;
    height: 100% !important;
    margin: 0!important;
    top: 0;
    left: 0;
    max-width: 100%!important;
  }

</style>
