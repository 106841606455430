import { ActionTree } from 'vuex'
import State, { EntityObject, Editor, EntityObjectLookup, EditorLookup } from './types'
import { RootState } from '../types'
import { Api } from '@/api/Api'
import { blankEditor, blankEntityObject, blankEntityProperty } from './constants'
import SecurityState from '../security/types'

const api = new Api()

export const actions: ActionTree<State, RootState> = {
  preload({ commit, dispatch }, { withEditors }: { withEditors: boolean }) {
    return new Promise<void>(resolve => {
      // note: getEditors dependent on getEntityObjects!
      dispatch('getEntityObjects').then(() => {
        if (withEditors) {
          dispatch('getEditors').then(() => {
            commit('setPreloaded', { value: true })
            resolve()
          })
        } else {
          commit('setPreloaded', { value: true })
          resolve()
        }
      })
    })
  },
  getEntityObjects({ commit }): any {
    return api.fetch({
      url: '/entity',
    })
      .then(response => {
        const entityObjects: EntityObject[] = response.List
        // here we create some circular references back to the entityObjects
        entityObjects.forEach(entityObject => {
          entityObject.idProperties = [] // TODO: merge with blank?
          entityObject.titleProperties = []
          entityObject.Properties.forEach(entityProperty => {
            entityProperty.selectName = entityProperty.DisplayName

            // TODO: but which property on the parent am I?
            // - and what about multiple
            // - care?
            entityProperty.EntityObject = entityObject // Parent relationship
            entityProperty.parentEntityObject = entityObject

            if (entityProperty.IsId) {
              entityObject.idProperties.push(entityProperty)
            }
            if (entityProperty.IsTitle) {
              entityObject.titleProperties.push(entityProperty)
            }

            // this is the child
            if (entityProperty.PropertyEntityName) {
              entityProperty.PropertyEntityObject = entityObjects.find(o => o.EntityName === entityProperty.PropertyEntityName)
              // but what can I put on this object to show that this is it's parent?
              // especially when it can have more than one?
              if (entityProperty.PropertyEntityObject) {
                entityProperty.PropertyEntityObject.parentEntityProperty = entityProperty
              }
            }
          })
        })

        // shortcuts for ease of use
        const entityObjectLookup: EntityObjectLookup = {
          model: entityObjects.find(o => o.EntityName === 'Model') || blankEntityObject,
          modelDetail: entityObjects.find(o => o.EntityName === 'ModelDetail') || blankEntityObject,
          category: entityObjects.find(o => o.EntityName === 'Category') || blankEntityObject,
          productClass: entityObjects.find(o => o.EntityName === 'ProductClass') || blankEntityObject,
          manufacturingSeries: entityObjects.find(o => o.EntityName === 'ManufacturingSeries') || blankEntityObject,
          product: entityObjects.find(o => o.EntityName === 'Product') || blankEntityObject,
          sectionTableLayout: entityObjects.find(o => o.EntityName === 'SectionTableLayout') || blankEntityObject,
        }

        commit('entityObjectsLoaded', entityObjects)
        commit('setEntityObjectLookup', entityObjectLookup)

        return entityObjects
      }).catch(() => {
        commit('entityObjectsError')
      })
  },
  getEditors({ state, commit }): any {
    const securityState = (this.state as any).security as SecurityState
    return api.fetchV2({
      url: '/entity/editor',
      user: securityState.currentUser,
    })
      .then(response => {
        const editors: Editor[] = response.List

        // here we create some circular references back to the entityObjects and entityProperties!
        editors.forEach(editor => {
          editor.EntityObject = state.entityObjects.find(o => o.EntityName === editor.EntityName) || blankEntityObject
          editor.fields = []
          editor.Sections.forEach(editorSection => {
            editorSection.Editor = editor
            editorSection.open = editorSection.SectionType !== 'Modal'
            editorSection.Fields.forEach(editorField => {
              // note that this EntityProperty is using editor.EntityObject and not the entityName from the EditorField Record
              // - if this is every different (I have it in both places in order to use a foreign key) then update this.
              editor.fields.push(editorField)

              // TODO: I want to add this - but it breaks everything, no idea why
              // if (editorField.EntityProperty.IsTitle) {
              //   editor.titleFields.push(editorField)
              // }

              // TODO: return this and get rid of this!
              editorField.Values = editorField.Values || []

              editorField.Section = editorSection
              editorField.EntityProperty = editor.EntityObject.Properties.find(o => o.EntityPropertyName === editorField.EntityPropertyName) || blankEntityProperty
              editorField.PropertyEditor = editors.find(o => o.EditorName === editorField.PropertyEditorName)

              if (editorField.DisplayName === null || editorField.DisplayName === undefined) { // because blank is OK
                editorField.DisplayName = editorField.EntityProperty.DisplayName
              }
              // TODO: entityField.DisplayDescription is always undefined, because it doesn't exist - do we change this?
              editorField.DisplayDescription = editorField.DisplayDescription || editorField.EntityProperty.DisplayDescription
              // // Can we get EditorField.Values to include from an object?
              // // we could do it on the backend, right?
              // if (editorField.EntityProperty.PropertyType === 'Object') { // and List?
              //   // how to know which pre-load to use?
              // }
            })
          })
        })

        // shortcuts for ease of use
        const editorLookup: EditorLookup = {
          model: editors.find(o => o.EditorName === 'Model') || blankEditor,
          multipleModelEditor: editors.find(o => o.EditorName === 'MultipleModelEditor') || blankEditor,
          imageFolder: editors.find(o => o.EditorName === 'ImageFolder') || blankEditor,
          documentFolder: editors.find(o => o.EditorName === 'DocumentFolder') || blankEditor,
          schedule: editors.find(o => o.EditorName === 'Schedule') || blankEditor,
          site: editors.find(o => o.EditorName === 'Site') || blankEditor,
          catalog: editors.find(o => o.EditorName === 'Catalog') || blankEditor,
          division: editors.find(o => o.EditorName === 'Division') || blankEditor,
          category: editors.find(o => o.EditorName === 'Category') || blankEditor,
          productClass: editors.find(o => o.EditorName === 'ProductClass') || blankEditor,
          manufacturingSeries: editors.find(o => o.EditorName === 'ManufacturingSeries') || blankEditor,
          product: editors.find(o => o.EditorName === 'Product') || blankEditor,
          entity: editors.find(o => o.EditorName === 'Entity') || blankEditor,
          editor: editors.find(o => o.EditorName === 'Editor') || blankEditor,
          editorSection: editors.find(o => o.EditorName === 'EditorSection') || blankEditor,
          editorField: editors.find(o => o.EditorName === 'EditorField') || blankEditor,
          permissions: editors.find(o => o.EditorName === 'Permissions') || blankEditor,
          modelDetails: editors.find(o => o.EditorName === 'Model-Details') || blankEditor,
          multipleModelChangeRequest: editors.find(o => o.EditorName === 'MultipleSectionTableCR') || blankEditor,
        }

        commit('editorsLoaded', editors)
        commit('setEditorLookup', editorLookup)

        return editors
      }).catch(() => {
        commit('editorsError')
      })
  },
}
