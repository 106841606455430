import _ from 'lodash'

import { ArrayLoading, Catalog, Division, EntityBase, Loading, None, ResponseArray, Site } from './types'

export const blankLoading: Loading = { loading: false }

export const loading: Loading = { loading: true }

export const none: None = _.assign({ isNone: true, Name: 'none', DisplayName: 'None', Visible: true, OrderBy: 0 })

export const blankArrayBase: ResponseArray<EntityBase> = _.assign({ List: [], Count: 0, Page: 0, PageSize: 20, Successful: true, Total: 0, TotalPages: 0, loading: false })

export const blankEntityBase: EntityBase = _.merge({ open: false, key: '', isNone: false }, blankLoading)

export const noneEntityBase: EntityBase = _.merge({}, blankEntityBase, none)

export const blankDivision: Division = _.merge({
  DivisionName: '',
  DisplayName: '',
  OEM: false,
  error: false,
}, blankEntityBase)

export const loadingDivision: ArrayLoading<Division> = _.assign([], loading)

export const loadingCatalog: ArrayLoading<Catalog> = _.assign([], loading)

export const loadingSite: ArrayLoading<Site> = _.assign([], loading)

export const blankSite: Site = { SiteId: -1, DivisionName: 'TPI', DisplayName: 'TPI', LogoPath: '', LogoLink: '', FaviconPath: '', Name: '', BrowserTitle: '', disabled: false, ShowInventory: false, EnableExport: false, ManageDocuments: 0, EnableChangeRequest: false, HasPricing: false, ShowCatalog: false }

export const blankCatalog: Catalog = { CatalogId: -1, Name: '', DisplayName: '', HasBreadcrumb: false, CartEnabled: false, ProductImageEnabled: false, FeatureListEnabled: false }

export const blankResponseArray: ResponseArray<any> = _.assign([], { loading: true, Count: 0, Page: 0, PageSize: 0, Successful: true, Total: 0, TotalPages: 0, List: [] })
