<template>
  <b-list-group-item
    @click="clearResults"
    :to="getTo(result)"
    class="text-left click"
  >
    <img v-if="result.ModelImageId" class="float-md-left mr-2" :src="'/id/' + result.ModelImageId + '?width=80&height=80'" width="80" height="80" />
    <img v-else-if="result.ProductImageId" class="float-md-left mr-2" :src="'/id/' + result.ProductImageId + '?width=80&height=80'" width="80" height="80" />
    <img v-else class="float-md-left mr-2" :src="'/id/' + result.CategoryImageId + '?width=80&height=80'" width="80" height="80" />
    <p class="mb-1 font-weight-bold">
      {{result.ProductDisplayName}}
      <b-badge variant="light" v-if="result.PcnCatalogNumber" style="font-size: 10px">Model</b-badge>
      <b-badge variant="light" v-else-if="!result.SectionTableId" style="font-size: 10px">Product</b-badge>
      <b-badge variant="light" v-else style="font-size: 10px">Table</b-badge>
      <br />
      <b-badge variant="light" style="font-size: 10px;">{{result.CategoryDisplayName}} &gt; {{result.ProductClassDisplayName}} &gt; {{result.ManufacturingSeriesDisplayName}}</b-badge>
    </p>
    <p class="mb-1">
      <template v-if="result.SectionDisplayName">
        {{result.SectionDisplayName}} &gt;
      </template>
      <template v-if="result.SectionTabName">
        {{result.SectionTabDisplayName}} &gt;
      </template>
      <template v-if="result.SectionTableDisplayName">
        {{result.SectionTableDisplayName}}
      </template>
    </p>
    <!-- <p class="mb-1" v-if="result.PcnCatalogNumber">
      {{result.PcnCatalogNumber}}
    </p> -->
  </b-list-group-item>
</template>
<script lang="ts">
import { Component, Prop, Emit, Mixins } from 'vue-property-decorator'
import { SearchResult } from '@/store/search/types'
import MxSite from '@/mixins/site'

@Component({
  components: {
  },
})
export default class extends Mixins(MxSite) {
  @Prop()
  public result!: SearchResult

  @Emit('clearResults')
  private clearResults() { }

  private getTo(item: SearchResult) {
    const { catalogId, isPreview } = this
    return this.getTopologyToByNames(
      catalogId,
      isPreview,
      item.CategoryName,
      item.ProductClassName,
      item.ManufacturingSeriesName,
      item.ProductName,
      item.SectionName,
      item.SectionTabName,
      item.SectionTableDisplayName,
      item.PcnCatalogNumber,
    )
  }
}
</script>
<style scoped>

</style>
