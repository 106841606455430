<template>
  <div id="input-container">
    <div class="row mx-0 input-container-row">
      <div class="input-group search-input-group" style="position: relative">
        <b-form-input
          type="text"
          class="form-control search-input"
          placeholder="Search for..."
          @input="searchResults"
          @keydown.enter.prevent="searchClicked"
          @focus="searchFocus"
          v-model="keyword"
        />
        <span class="input-group-btn">
          <b-button class="search-btn" variant="default" @click="searchClicked">
            <span class="search-text">Search</span>
            <span class="fa fa-search search-icon" aria-hidden="true"></span>
          </b-button>
        </span>
      </div>
      <b-list-group class="result-list" v-if="results.Count > 0">
        <search-result v-for="result in results.List" :key="result.SectionTableModelId"
         :result="result"
          @clearResults="clearResults"
        />
      </b-list-group>
      <span
        class="fa fa-search search-icon-mobile"
        @click="showSearchModal = true"
        aria-hidden="true"
      ></span>
      <b-modal
        id="search-modal"
        v-model="showSearchModal"
        title="Search"
        hide-footer
        centered
        size="xl"
      >
        <div class="input-group">
          <input
            type="text"
            class="form-control search-input"
            placeholder="Search for..."
            :value="keyword"
            @keydown.enter.prevent="searchClicked"
          />
          <span>
            <b-button
              class="search-btn"
              variant="default"
              @click="searchClicked"
            >
              <span class="fa fa-search search-icon" aria-hidden="true"></span>
            </b-button>
          </span>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { debounce } from 'typescript-debounce-decorator'
import { SearchResult } from '@/store/search/types'

import SearchResultComponent from './search-result.vue'

import { blankResponseArray } from '../../store/constants'
import { ResponseArray } from '@/store/types'

import MxSite from '@/mixins/site'

const nsSearch = namespace('search')

@Component({
  components: {
    'search-result': SearchResultComponent,
  },
})
export default class SearchInput extends Mixins(MxSite) {
  @nsSearch.Action('performSearch')
  public performSearch: any

  public showSearchModal = false
  public keyword = ''
  public results: ResponseArray<SearchResult> = blankResponseArray

  public searchClicked(e: any) {
    this.showSearchModal = false
    this.clearResults()

    if (!this.keyword) {
      this.keyword = e.target.value
    }

    // this.$router.push({ name: 'search', params: { keyword: this.keyword }, query: { catalogId: '' + catalogId } })
    console.log('this.keyword', this.keyword)
    this.$router.push(this.getSearchTo(this.keyword, this.catalogId, this.isPreview))
  }

  public searchFocus(e: any) {
    this.searchResults(e.target.value)
  }

  @debounce(500)
  public searchResults(keyword: string) {
    this.keyword = keyword
    const { catalogId } = this
    if (keyword === '') return
    this.performSearch({ catalogId, keyword, page: 1, pageSize: 20 }).then((results) => {
      this.results = results
    })
  }

  public mounted() {
    document.addEventListener('click', this.clearResults)
  }

  public beforeUnmount() {
    document.removeEventListener('click', this.clearResults)
  };

  public clearResults() {
    Vue.set(this, 'results', [])
  }
}
</script>

<style scoped>
#input-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.click {
  cursor: pointer;
}

.input-container-row {
  text-align: center;
  width: 100%;
}
.search-input {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.search-btn {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #6ca3d0 !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: white;
}

.search-btn:hover {
  background-color: #063d6a !important;
  color: white;
}

.search-icon {
  display: none;
}

.search-icon-mobile {
  display: none;
  font-size: 1.5rem;
  color: white;
}

.result-list {
  position: absolute;
  top: 40px;
  height: 40vh;
  width: 40vw;
  right: 0;
  overflow: auto;
}

@media (max-width: 767px) {
  .search-input-group {
    display: none;
  }

  .input-container-row {
    width: auto;
  }

  .search-icon {
    display: block;
    font-size: 1.5rem;
  }

  .search-text {
    display: none;
  }

  .search-icon-mobile {
    display: block;
  }

}
</style>
