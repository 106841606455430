import { BaseApi } from '@/api/BaseApi'
import { IFetchOptions } from '@/types/IFetchOptions'

export const HttpMethod = {
  Post: 'POST',
  Get: 'GET',
  Put: 'PUT',
}

export class FetchApi extends BaseApi {
  constructor() {
    super()
  }

  public async fetch(args: IFetchOptions): Promise<any> {
    const header: HeadersInit = {
      'Content-Type': 'application/json charset=utf-8',
    }
    if (args.user && args.user.SecurityToken) {
      header['X-Security-Token'] = args.user.SecurityToken
    }
    const reqOptions: RequestInit = {
      method: 'GET',
      headers: header,
    }
    // TODO: check if object is an array, if so make list

    if (args.obj) {
      const payload: any = args.payload ? args.payload : {}
      if (Array.isArray(args.obj)) {
        payload.List = args.obj
      } else {
        payload.Data = args.obj
      }

      reqOptions.method = 'POST'
      reqOptions.mode = 'cors'
      reqOptions.cache = 'no-cache'
      reqOptions.credentials = 'same-origin'
      reqOptions.redirect = 'follow'
      reqOptions.referrer = 'no-referrer'
      reqOptions.body = JSON.stringify(payload)
    }

    if (args.body) {
      reqOptions.method = 'POST'
      reqOptions.mode = 'cors'
      reqOptions.cache = 'no-cache'
      reqOptions.credentials = 'same-origin'
      reqOptions.redirect = 'follow'
      reqOptions.referrer = 'no-referrer'
      reqOptions.body = args.body
    }

    if (args.method) { // override the method
      reqOptions.method = args.method
    }

    const response = await fetch(`${this.baseUrl}${args.url}`, reqOptions)
    return await response.json()
  }

  public async fetchV2(args: IFetchOptions): Promise<any> {
    const header: HeadersInit = args.body
      ? { }
      : {
          'Content-Type': 'application/json charset=utf-8',
        }

    if (args.user && args.user.BearerToken) {
      header.Authorization = `Bearer ${args.user.BearerToken}`
    } else if (args.user && args.user.SecurityToken) {
      header['X-Security-Token'] = args.user.SecurityToken
    }

    const reqOptions: RequestInit = {
      method: args.method,
      headers: header,
    }
    // TODO: check if object is an array, if so make list

    if (args.obj) {
      reqOptions.mode = 'cors'
      reqOptions.cache = 'no-cache'
      reqOptions.credentials = 'same-origin'
      reqOptions.redirect = 'follow'
      reqOptions.referrer = 'no-referrer'
      reqOptions.body = JSON.stringify(args.obj)
    }

    if (args.body) {
      reqOptions.body = args.body
    }

    if (args.method) { // override the method
      reqOptions.method = args.method
    }

    const response = await fetch(`${this.baseUrl}${args.url}`, reqOptions)

    let parsedResponse : any = {}

    try {
      parsedResponse = await response.json()
    } catch (error) {
      parsedResponse = {}
    }

    parsedResponse.Successful = response.ok
    return parsedResponse
  }

  // TODO: JM: Fix V2
  public async fetchList(args: IFetchOptions): Promise<any> {
    const header: HeadersInit = {
      'Content-Type': 'application/json charset=utf-8',
    }

    if (args.user && args.user.BearerToken) {
      header.Authorization = `Bearer ${args.user.BearerToken}`
    }
    const reqOptions: RequestInit = {
      method: args.method,
      headers: header,
    }
    // TODO: check if object is an array, if so make list

    if (args.obj) {
      reqOptions.mode = 'cors'
      reqOptions.cache = 'no-cache'
      reqOptions.credentials = 'same-origin'
      reqOptions.redirect = 'follow'
      reqOptions.referrer = 'no-referrer'
      reqOptions.body = JSON.stringify(args.obj)
    }

    if (args.method) { // override the method
      reqOptions.method = args.method
    }

    const response = await fetch(`${this.baseUrl}${args.url}`, reqOptions)

    let parsedResponse : any = {}

    try {
      parsedResponse = await response.json()
    } catch (error) {
      parsedResponse = {}
    }

    return { Successful: response.ok, value: parsedResponse }
  }
}
