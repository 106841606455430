<template>
  <div>
    <b-card no-body v-if="section">
      <b-card-header header-tag="h3" v-if="section.DisplayName">{{ section.DisplayName }}</b-card-header>
      <b-tabs card :nav-class="section.Tabs.length === 1 && section.Tabs[0].DisplayName === '' ? 'd-none' : ''">
        <b-tab v-for='(sectionTab, tabIndex) in section.Tabs' :key="'sectionTab-' + sectionTab.SectionTabId"
          :title="sectionTab.DisplayName"
          :active="sectionTab.active"
          @click="selectTab({ section, sectionTab, tabIndex })">
          <b-container fluid>
            <b-row no-gutters>
              <!-- TODO: make this part of the TABLE -->
              <!-- sideBarModel.visible ? '6': '' -->
              <!-- <b-col :cols="sectionItem.Cols"
              :class="sideBarModel.visible ? 'd-none d-md-block' : ''"
            > -->
              <b-card :class="'col-12 col-sm-' + sectionItem.Cols" no-body class="table-card" v-for="sectionItem in sectionTab.Items" :key="'sectionItem-card-' + sectionItem.key">
                <b-card-header :key="'sectionItem-header-' + sectionItem.key"
                  class="py-1" header-tag="h3" v-if="sectionItem.DisplayName">
                  {{ sectionItem.DisplayName }}
                </b-card-header>
                <b-card-body :key="'sectionTable-body-' + sectionItem.key"
                  v-if="sectionItem.SectionTableId" class="p-1 w-auto">
                  <section-table :product="product" :sectionTable="sectionItem" :key="'sectionTable-table-' + sectionItem.key"></section-table>
                </b-card-body>

                <b-card-body :key="'sectionList-body-' + sectionItem.key"
                  v-if="sectionItem.SectionItemType == 'List' && sectionItem.PropertyName && product[sectionItem.PropertyName] && product[sectionItem.PropertyName].length > 0" class="p-1 w-auto">
                  <ul class='text-list'>
                    <template v-for="(listItem, listIndex) in product[sectionItem.PropertyName]">
                      <li :key="'list-item-' + sectionItem.key + '-' + listIndex" v-if="listItem.trim() !== ''">
                        {{ listItem }}
                      </li>
                    </template>
                  </ul>
                </b-card-body>
                <b-card-body :key="'sectionList-body-' + sectionItem.key" v-if="sectionItem.SectionItemType == 'List' && sectionItem.PropertyName == 'Items' && sectionItem.Items && sectionItem.Items.length > 0" class="p-1 w-auto">
                  <ul class='text-list'>
                    <template v-for="(listItem, listIndex) in sectionItem.Items">
                      <li :key="'list-item-' + sectionItem.key + '-' + listIndex" v-if="listItem.trim() !== ''">
                        {{ listItem }}
                      </li>
                    </template>
                  </ul>
                </b-card-body>
                <b-card-body :key="'sectionImage-body-' + sectionItem.key" v-if="sectionItem.SectionItemType == 'Image'" class="p-1 w-auto text-center" @click="setSelectedImage({ image: sectionItem })">
                  <b-img fluid v-b-tooltip.hover :src="sectionItem.ImagePath + '?width=' + sectionItem.Width + '&height=' + sectionItem.Height" :alt="'' + sectionItem.ImageId" :width="sectionItem.Width" :height="sectionItem.Height" :title="sectionItem.ExplodedView ? 'Click to watch the Exploded 3D view' : ''" />
                  <b-icon icon="zoom-in" class="zoom-icon" scale="3" />
                  <b-card-text>
                    {{ sectionItem.Caption }}
                  </b-card-text>
                </b-card-body>
                <b-card-body :key="'sectionRichText-body-' + sectionItem.key" v-if="sectionItem.SectionItemType == 'RichText'" class="p-1 w-auto">
                  <div v-html="sectionItem.RichText"></div>
                </b-card-body>
                <b-card-body :key="'sectionSpinSirv-body-' + sectionItem.key" v-if="sectionItem.SectionItemType == 'SpinSirv'" class="p-1 w-auto">
                  <div class="Sirv" :data-src="sectionItem.Url"></div>
                </b-card-body>
                <b-card-body :key="'sectionDocument-body-' + sectionItem.key" v-if="sectionItem.SectionItemType == 'Document'" class="p-1 w-auto">
                  <img src="//www.weebly.com/weebly/images/file_icons/pdf.png" width="36" height="36" style="float: left; position: relative; left: 0px; top: 0px; margin: 0 15px 15px 0; border: 0;">
                  {{ sectionItem.Caption }}
                  <br>
                  <b-link :href="sectionItem.DocumentPath" target="new">
                    Download File
                  </b-link>
                </b-card-body>
              </b-card>
            </b-row>
          </b-container>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Product, Section, SectionTab, SideBarItem } from '../store/product/types'
import SectionTable from './section-table.vue'
import LoadingText from '@/components/utility/loading-text.vue'

const nsProduct = namespace('product')

@Component({
  components: {
    'section-table': SectionTable,
    'loading-text': LoadingText,
  },
})

export default class extends Vue {
  @Prop()
  public product!: Product | null

  @Prop()
  public section!: Section | null

  @nsProduct.Mutation('setSelectedImage')
  private setSelectedImage: any

  @nsProduct.Mutation('clearActiveModel')
  private clearActiveModel: any

  @nsProduct.State('sideBarModel')
  private sideBarModel!: SideBarItem

  @nsProduct.Action('setActiveTab')
  private setActiveTab: any

  private selectTab({ section, sectionTab }: { section: Section, sectionTab: SectionTab }) {
    const { params, query } = this.$route
    params.section = section.Name
    params.tab = sectionTab.Name

    this.$router.replace({ params, query })

    this.setActiveTab({ section, sectionTab })
  }
}
</script>
<style scoped>
.zoom-icon {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

/* .table-card {
  overflow-x: auto;
  min-height: 800px;
} */

/* NOTE: I am not happy with this targeting...but the way the PortalVue
   works the CSS wasn't letting me do the print view properly
   I may just have missed something obvious or there may be a better way to target the
   .detail-container only on the non-print view with these settings..but for now */

.body-section {
  padding: auto 0px;
  width: 100%;
}

.prop65-text {
  font-size: 0.75rem;
}

h3 {
  line-height: 1.5;
  font-size: 20px;
}
</style>
