<template>
  <div class="container-fluid p-0" :class="{ 'menu-mobile': screen.lt.md }">
    <div class="row mx-0">
      <div class="col-12 p-0" v-if="screen.lt.md">
        <b-card no-body v-if="activeProductClass && $route.name != 'menu-page-category'">
          <b-card-header class="tab-header text-center my-0 py-2" header-tag="h4"
            @click="$router.push(getTopologyTo(catalogId, isPreview, activeCategory))">
            {{ activeProductClass.DisplayName }}
            <b-button class="close" variant="link">x</b-button>
          </b-card-header>
          <b-card-text>
            <b-card-group flush>
              <b-container fluid>
                <b-row no-gutters style="width: 100%;">
                  <b-card class="col-sm-6 col-md-4 col-lg-4" no-body v-for="series in activeProductClass.ManufacturingSeries" :key="series.ManufacturingSeriesId">
                    <b-card-header>
                      <!-- <b-card-title tag="h3"> -->
                      {{ series.DisplayName }}
                      <!-- </b-card-title> -->
                    </b-card-header>
                    <b-list-group flush>
                      <b-list-group-item v-for="product in series.Products" :key="product.ProductId"
                        :to="getTopologyTo(catalogId, isPreview, activeCategory, activeProductClass, series, product)">
                        {{ product.DisplayName }}
                        <b-img thumbnail fluid v-if="product.Image" :src="product.Image.ImagePath + '?width=80&height=80'" :alt="product.Image.AltText" class="float-right" style="max-width:80px;" @error="event => _loadErrorImage(event, '80', '80')"></b-img>
                      </b-list-group-item>
                    </b-list-group>
                  </b-card>
                </b-row>
              </b-container>
            </b-card-group>
          </b-card-text>
        </b-card>
        <b-card no-body v-else>
          <b-card-header class="tab-header text-center my-0 py-2" header-tag="h4"
            @click="$router.push({ name: 'catalog' })">
            {{ activeCategory.DisplayName }}
            <b-button class="close" variant="link">x</b-button>
          </b-card-header>
          <template v-for="(productClass) in activeCategory.ProductClasses">
            <!-- v-if="$route.params.tab != sectionTab.DisplayName" -->
            <b-link block class="px-2 py-1" :key="productClass.ProductClassId"
              :to="getTopologyTo(catalogId, isPreview, activeCategory, productClass)">
              <b-card-header header-tag="header" class="border" role="tab" >
                {{ productClass.DisplayName }}<span class="ml-2 fa fa-caret-right"></span>
              </b-card-header>
            </b-link>
          </template>
        </b-card>
      </div>
      <div class="col-12 p-0" v-else>
        <b-card no-body>
          <b-tabs card v-if="activeCategory">
            <b-tab :active="activeProductClass && productClass.ProductClassId == activeProductClass.ProductClassId" v-for="(productClass) in activeCategory.ProductClasses" :key="productClass.ProductClassId" @click="productClass.ProductClassId != activeProductClass.ProductClassId ? $router.push(getTopologyTo(catalogId, isPreview, activeCategory, productClass)) : false" class="series-list" :title="productClass.DisplayName">
              <b-card-text>
                <b-card-group flush>
                  <b-container fluid>
                    <b-row no-gutters style="width: 100%;">
                      <b-card class="col-sm-6 col-md-4 col-lg-4" no-body v-for="series in productClass.ManufacturingSeries" :key="series.ManufacturingSeriesId">
                        <b-card-header>
                          <!-- <b-card-title tag="h3"> -->
                          {{ series.DisplayName }}
                          <!-- </b-card-title> -->
                        </b-card-header>
                        <b-list-group flush>
                          <b-list-group-item v-for="product in series.Products" :key="product.ProductId" :to="getTopologyTo(catalogId, isPreview, activeCategory, productClass, series, product)">
                            {{ product.DisplayName }}
                            <b-img thumbnail fluid v-if="product.Image" :src="product.Image.ImagePath + '?width=80&height=80'" :alt="product.Image.AltText" class="float-right" style="max-width:80px;" @error="event => _loadErrorImage(event, '80', '80')"></b-img>
                          </b-list-group-item>
                        </b-list-group>
                      </b-card>
                    </b-row>
                  </b-container>
                </b-card-group>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import MenuState, { Category, ProductClass } from '@/store/menu/types'
// import { Dictionary } from 'vue-router/types/router'
import { blankCategory } from '@/store/editor/constants'
import { isMobile, handleResize } from '@/common/helpers/responsive-helper'

import MxSite from '@/mixins/site'

const nsMenu = namespace('menu')

@Component({
  name: 'menu-page',
  components: {

  },
})
export default class extends Mixins(MxSite) {
  @nsMenu.State('categories')
  public state!: MenuState

  @nsMenu.Getter('categories')
  public categories!: Category[]

  @nsMenu.State('activeCategory')
  public activeCategory!: Category

  @nsMenu.Mutation('setCategoryActive')
  public setCategoryActive!: any

  @nsMenu.State('activeProductClass')
  public activeProductClass!: ProductClass

  @nsMenu.Mutation('setProductClassActive')
  public setProductClassActive!: any

  public isMobile = isMobile()

  public mounted() {
    if (!this.state || !this.state.categories) {
      // this.setCategoryActive({ name: this.$route.params.category })
      // this.setProductClassActive({ name: productClass })
      handleResize((isMobile) => {
        this.isMobile = isMobile
      })
      this.intializeRoute()
    }
  }

  @Watch('$route.query.catalogId')
  @Watch('$route.query.isPreview')
  @Watch('$route.params')
  public onRouteChanged() {
    // this.setCategoryActive({ name: this.$route.params.category })
    // if (oldParams.category === newParams.category) {
    this.intializeRoute()
    // this.setProductClassActive({ name: this.$route.params.productclass })
    // }
  }

  public intializeRoute() {
    this.setCategoryActive({ name: this.$route.params.category })

    const categoryName = this.$route.params.category
    let productClassName = this.$route.params.productclass

    // if the productclass parameter is mising, default to the first one
    if (!productClassName && !this.isMobile) {
      const category = this.categories ? this.categories.find(c => c.Name === categoryName) : blankCategory
      if (category) {
        if (category.ProductClasses.length > 0) {
          productClassName = category.ProductClasses[0].Name
          this.$router.replace(this.getTopologyToByNames(this.catalogId, this.isPreview, categoryName, productClassName))
        }
      }
    }
    this.setProductClassActive({ name: productClassName })
  }
}

</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: block;
  margin: 0 10px;
}

span.active {
  font-weight: bold;
}

span.tree-toggle:before {
  content: "\25B7";
}

span.tree-toggle.open:before {
  content: "\25FF";
}

span.tree-toggle.empty:before {
  color: #282828;
}

.menu-mobile .card-header {
  border: 0 none;
}

.menu-mobile .mobile-productclass {
  font-weight: 700;
  padding: 0.75rem
}

.menu-mobile .mobile-productclass.collapsed {
  background-color: #666 !important;
  background-image: none !important;
  border: 0 none !important;
  ;
  border-radius: 0 !important;
  transition: background-color .3s;
}

.menu-mobile .btn-secondary .fa {
  float: right;
  font-size: 1.2rem;
  padding-top: 3px;
}

.menu-mobile .collapsed .fa {
  transform: rotate(0);
  transition: all ease-in .2s;
}
</style>
