// TODO: two different - this was actually the field one!
export interface EffectivePermission {
  ModuleId: number
  CanRead: boolean
  CanUpdate: boolean
  CanCreate: boolean
  CanDelete: boolean
  CanExecute: boolean
}

export enum ResourcePermissionType {
  Unknown = 0,
  Owner = 1,
  Read = 2,
  ReadAndWrite = 3,
}

export enum ResourceType {
  Unknown = 0,
  SalesRep = 1,
  Customer = 2,
}

export interface ResourcePermission {
  Id: string;
  ResourceName: string;
  PermissionType: number;
  Type: number;
}

export interface User {
  Id?: number // TODO: always existing?
  Name: string
  LastName: string
  Username: string
  HasAccess: boolean
  SecurityToken?: string
  BearerToken?: string
  EffectivePermissions: EffectivePermission[]
  Role: string
  TokenExpiration?: number
  ResourcePermissions: ResourcePermission[]
  loading?: boolean
  AuthProviderType: string
  Email: string
}

export interface ILoginRequest {
  Username: string
  Password: string
  Scope: string;
}

export interface IRecaptchaRequest {
  Challenge: string
}

export interface IPasswordResetRequest extends IRecaptchaRequest {
  Username: string
}

export interface ISignupRequest extends IRecaptchaRequest {
  Name: string
  LastName: string
  Email: string
  Password: string
  InvitationCode: string
  SalesRepIds: string[]
  CustomerNumbers: string[]
  Scope?: string
}

export interface IUpdatePasswordWithCodeRequest {
  Id: string
  Code: string
  NewPassword: string
}

export interface IChangePasswordRequest {
  UserName: string
  OldPassword: string
  NewPassword: string
}

// TODO: this should be in "editor" and "editor" should be "editor-edit"
export default interface State {
  error: boolean
  currentUser: User
  passwordExpired: boolean
  resetUsername: string
}

export interface IHttpError {
  Code: number
  CodeString: number
  Message: string
}

export interface ISecurity {
  currentUser: User
  error: boolean
}
