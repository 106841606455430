<template>
  <div id="app">
    <component :is="'style'" type="text/css" v-if="isCatalogSite">
@media (min-width: 1024px) {
  body{
    padding-top: 125px;
  }
}
    </component>
    <loading-overlay :isLoading="showLoadingOverlay"></loading-overlay>
    <browser-title></browser-title>
    <menu-bar></menu-bar>
    <div class="container-fluid p-0 p-md-3">
      <breadcrumbs />
      <router-view />
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import MenuBar from '@/components/utility/menu-bar.vue'
import Breadcrumbs from '@/components/breadcrumbs.vue'
import BrowserTitle from '@/components/browser-title.vue'
import Footer from '@/components/utility/footer.vue'
import LoadingOverlay from '@/components/utility/loading-overlay.vue'
import { namespace } from 'vuex-class'

const nsCommon = namespace('common')

@Component({
  components: {
    'menu-bar': MenuBar,
    'breadcrumbs': Breadcrumbs,
    'browser-title': BrowserTitle,
    'app-footer': Footer,
    LoadingOverlay,
  },
})
export default class App extends Vue {
  @nsCommon.State('showLoadingOverlay')
  public showLoadingOverlay!: boolean

  get isCatalogSite() {
    return process.env.VUE_APP_TPI_ROLE === 'site' || (this.$route.query.catalogId ? +this.$route.query.catalogId > 0 : false)
  }
}
</script>

<style>
html,
body {
  height: 100%;
}

body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  padding-top: 60px;
}

@media print {
  body {
    padding-top: 1rem;
  }
}
</style>

<style lang="scss" scoped>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* padding-left: 16px;
  padding-right: 16px; */
}
#logo-img {
  margin-bottom: 1rem;
}
/* #nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
