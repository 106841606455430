<template>
  <div v-if="activeOrder.OrderId === 0" class="d-flex justify-content-center">
    <b-card title="404" class="p-5">
      <b-card-text>Error - Order not found</b-card-text>
    </b-card>
  </div>
  <div v-else>
    <b-card>
      <!-- Row 1 -->
      <b-row class="align-items-center pb-3 my-1">
        <b-col md="8">
          <h5>
            <span
              v-if="_hasHistory()"
              class="mr-4 primary-color back-btn"
              @click="$router.back()"
            >
              <b-icon icon="arrow-left"></b-icon>
            </span>
            <i class="fa fa-truck fa-lg mr-2"></i>TPI Order Summary
          </h5>
        </b-col>
        <b-col md="4 my-1" style="text-align: right;">
          <span class="rounded border p-2" style="background-color: #d6eaf8">
            <b>{{ activeOrder.OrderStatus }}</b>
          </span>
        </b-col>
      </b-row>

      <!-- Row 2 -->
      <b-row
        class="justify-content-between align-items-center p-1 border-y"
        style="
          background-color: #e7f0f8;
          border-color: #a6c2ef;
          margin-left: -1.25rem;
          margin-right: -1.25rem;
        "
      >
        <b-col><b>Order Details</b></b-col>
        <!-- <b-col v-if="orderStatus.PENDING.toLocaleLowerCase() === activeOrder.OrderStatus.toLocaleLowerCase()">
          <b-button variant="link" class="float-right" style="color: #c0392b"
            >Cancel Order</b-button
          >
        </b-col> -->
      </b-row>

      <!-- Row 4 -->
      <b-row class="my-3">
        <b-col md="4" class="info-section" style="border-color: #a6c2ef">
          <b-col class="info-section-header" sm="border-top">Information</b-col>
          <b-row class="my-2 border-top pt-2">
            <b-col cols="6">Sale Order # :</b-col>
            <b-col cols="6"
              ><u
                ><b>{{ activeOrder.SalesOrderNumber }}</b></u
              ></b-col
            >
          </b-row>
          <b-row class="my-2">
            <b-col cols="6">Sales Order Date :</b-col>
            <b-col cols="6">{{ formatDate(activeOrder.OrderDate) }}</b-col>
          </b-row>
          <b-row class="my-2" v-if="_isSalesRepSite(site.Name) || _isCustomerSite(site.Name) || _isCustomerAdminSite()">
            <b-col cols="6">PO Number :</b-col>
            <b-col cols="6">{{
              activeOrder.CustomerPurchaseOrderNumber
            }}</b-col>
          </b-row>
          <b-row class="my-2" v-if="(_isSalesRepSite(site.Name) || _isCustomerSite(site.Name) || _isCustomerAdminSite()) && (activeOrder.CreatorUsername || activeOrder.Creator)">
            <b-col cols="6">Creator :</b-col>
            <b-col cols="6"><span :title="activeOrder.CreatorUsername">{{
              activeOrder.Creator ? activeOrder.Creator : activeOrder.CreatorUsername
            }}</span></b-col>
          </b-row>
          <b-row class="my-2" v-if="(_isSalesRepSite(site.Name) || _isCustomerSite(site.Name) || _isCustomerAdminSite()) && (activeOrder.SalesRepresentatives && activeOrder.SalesRepresentatives.length > 0)">
            <b-col cols="6">Sales Representative:</b-col>
            <b-col cols="6">
              <span v-for="(salesRep, index) in activeOrder.SalesRepresentatives" :key="salesRep">{{ salesRep }}<template v-if="index < activeOrder.SalesRepresentatives.length - 1">, </template></span>
            </b-col>
          </b-row>
          <b-row
            class="my-2"
            v-if="
              activeOrder.Deliveries == null ||
              activeOrder.Deliveries.length == 0
            "
          >
            <b-col cols="6">Expected Shipping Date :</b-col>
            <b-col cols="6" v-if="activeOrder.ExpectedShippingDate">{{
              formatDate(activeOrder.ExpectedShippingDate)
            }}</b-col>
          </b-row>
          <b-row class="my-2" v-else>
            <b-col cols="6">Tracking:</b-col>
            <b-col cols="6"><b-button variant="link" class="p-0" @click="tabIndex = OrderSummaryTabs.Deliveries">View Deliveries</b-button></b-col>
          </b-row>
        </b-col>

        <b-col md="2" class="info-section" style="border-color: #a6c2ef">
          <b-col class="info-section-header">Value</b-col>
          <b-row class="my-2 border-top pt-2">
            <b-col cols="6">Subtotal :</b-col>
            <b-col cols="6" class="text-right" lg="text-right">{{
              formatCost(activeOrder.Total)
            }}</b-col>
          </b-row>
          <b-row class="my-2" v-if="activeOrder.TaxCalculated">
            <b-col cols="6">Sales Tax :</b-col>
            <b-col cols="6" class="text-right">{{
              formatCost(activeOrder.Tax || 0)
            }}</b-col>
          </b-row>
          <b-row class="my-2">
            <b-col cols="6"
              >{{
                activeOrder.TaxCalculated
                  ? 'Grand Total'
                  : 'Estimated Total Before Tax'
              }}
              :</b-col
            >
            <b-col cols="6 font-weight-bold" class="text-right">{{
              formatCost(grandTotal)
            }}</b-col>
          </b-row>
        </b-col>

        <b-col
          md="3"
          class="info-section"
          style="border-color: #a6c2ef"
          v-if="activeOrder.BillToParty"
        >
          <b-col class="info-section-header">Bill To</b-col>
          <b-row class="my-2 border-top pt-2">
            <b-col cols="6">Party :</b-col>
            <b-col cols="6">{{ activeOrder.BillToParty.Name }}</b-col>
          </b-row>
          <b-row class="my-2">
            <b-col cols="6">Phone # :</b-col>
            <b-col cols="6">{{ activeOrder.BillToParty.Phone }}</b-col>
          </b-row>
          <b-row class="my-2">
            <b-col cols="6">Address :</b-col>
            <b-col cols="6">{{
              `${activeOrder.BillToParty.Address1}, ${activeOrder.BillToParty.Address2}
              ${activeOrder.BillToParty.City}, ${activeOrder.BillToParty.State} ${activeOrder.BillToParty.Zip}
              `
            }}</b-col>
          </b-row>
          <b-row class="my-2" v-if="(_isSalesRepSite(site.Name) || _isCustomerAdminSite()) && activeOrder.AuthorizationNumber">
            <b-col cols="6">Authorization No :</b-col>
            <b-col cols="6">
              {{ activeOrder.AuthorizationNumber }}
            </b-col>
          </b-row>
        </b-col>

        <b-col md="3" class="info-section" v-if="activeOrder.ShipToParty">
          <b-col class="info-section-header">Ship To</b-col>
          <b-row class="my-2 border-top pt-2">
            <b-col cols="6">Party :</b-col>
            <b-col cols="6">{{ activeOrder.ShipToParty.Name }}</b-col>
          </b-row>
          <b-row class="my-2">
            <b-col cols="6">Phone # :</b-col>
            <b-col cols="6">{{ activeOrder.ShipToParty.Phone }}</b-col>
          </b-row>
          <b-row class="my-2">
            <b-col cols="6">Address :</b-col>
            <b-col cols="6">{{
              `${activeOrder.ShipToParty.Address1}, ${activeOrder.ShipToParty.Address2}
              ${activeOrder.ShipToParty.City}, ${activeOrder.ShipToParty.State} ${activeOrder.ShipToParty.Zip}
              `
            }}</b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-tabs align="left" v-model="tabIndex">
        <b-tab title="Items">
          <template #title>
            Items ({{ totalSelectedQty }})
          </template>
          <div style="margin: -1.25rem">
            <item-table
              class="my-3"
              :fields="fields"
              :items="items"
              :selectedRows="selectedRows"
              :selectedItems="selectedItems"
              :disableInput="true"
            />
          </div>
        </b-tab>
        <b-tab title="Files" v-if="site.ManageDocuments">
          <template #title>
            Files <span v-if="!documentsLoading">({{ orderDocuments.length }})</span><span v-else><i class="fa fa-spinner fa-pulse"></i></span>
          </template>
          <div style="item-table-container my-3" v-show="documentsLoading">
              <h2><i class="fa fa-spinner fa-pulse"></i></h2>
          </div>
          <div style="margin: -1.25rem" v-show="!documentsLoading">
            <div class="item-table-container my-3">
              <b-table
                :fields="fileFields"
                :items="orderDocuments"
                responsive
                hover
                borderless
                sticky-header
                class="item-table"
                style="min-height: 200px;"
              >
                <template v-slot:cell(Name)="{ item }">
                  <b-link :href="'/api/order/document/' + item.Id" target="_blank">
                    {{ item.Name }}<template v-if="item.SectionCount == 1">{{ item.FileExtension }}</template>
                  </b-link>
                  <span v-if="item.SectionCount > 1"> ({{ item.SectionCount }} Files)</span>
                </template>
              </b-table>
              <b-button variant="primary" @click="() => { showOrderDocuments = true }" class="ml-3" v-if="site.ManageDocuments >= 2">
                <template v-if="site.ManageDocuments == 2">Upload files</template>
                <template v-else-if="site.ManageDocuments == 3">Manage files</template>
              </b-button>
              <b-modal v-model="showOrderDocuments" title="Order Documents" size="xl" ok-only>
                <order-documents :orderDocuments="orderDocuments" :showFileUpload="site.ManageDocuments >= 2" :showClipUnclip="site.ManageDocuments === 3" :showDelete="site.ManageDocuments === 3"
                @upload="upload"
                @clip="clip"
                @delete="deleteFile"
                @unclip="unclip"
                ></order-documents>
                <template #modal-footer>&nbsp;</template>
              </b-modal>
            </div>
          </div>
        </b-tab>
        <b-tab :title="`Comments (${activeOrder.Comments.length})`" v-if="_isSalesRepSite(site.Name) || _isCustomerAdminSite()">
          <b-row class="my-2">
            <b-col cols="12" v-for="(comment, index) in activeOrder.Comments" :key="'comment-'+index">
              <i>{{ comment }}</i>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab v-if="activeOrder.Deliveries.length > 0" :title="`Deliveries (${activeOrder.Deliveries.length})`" >
          <b-row class="my-2">
            <b-col cols="12" md="4" class="pb-2" v-for="(delivery) in activeOrder.Deliveries" :key="'delivery-'+delivery.DeliveryId">
              <b-card class="h-100">
                <b-card-title><h5>{{ delivery.Carrier }}</h5></b-card-title>
                <b-card-sub-title>
                  <template v-if="delivery.ShippingInformation && delivery.ShippingInformation.TrackingUpdatesList?.length > 0">
                    <div class="d-flex align-items-center">
                      <span>Tracking: </span>
                      <b-button
                        @click="() => setSelectedShippingInformation(delivery.ShippingInformation, delivery.TrackingNumber)"
                        variant="link p-0 ml-1"
                        :key="delivery.DeliveryId"
                        >{{ delivery.TrackingNumber }}
                      </b-button>
                    </div>
                  </template>
                  <template v-else>
                    Tracking: {{ delivery.TrackingNumber }}
                  </template>
                </b-card-sub-title>
                <b-card-text>
                  <div v-if="delivery.ShippingInformation && delivery.ShippingInformation.TrackingUpdatesList?.length > 0">
                    Status: <b>{{ delivery.ShippingInformation.TrackingUpdatesList[0]?.Status }}</b> <br/>
                    Last Updated: {{ formatDeliveryDate(delivery.ShippingInformation.TrackingUpdatesList[0]?.Date.toString()) }}
                  </div>
                </b-card-text>
                <b-card-text>
                  Items:
                  <ul v-for="(item, i) in delivery.Items" :key="delivery.DeliveryId+'=item-'+i">
                    <li><b>{{ item.Quantity }}</b> <b-icon icon="x" class="color-primary"></b-icon> {{ item.ShortText }} (<b>{{ item.Material }}</b>)</li>
                  </ul>
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card>
    <div class="d-flex justify-content-center">
      <b-button
        block
        variant="outline-primary"
        class="primary-outline-btn my-3"
        @click="$router.push({ name: 'home' })"
      >
        Close summary
      </b-button>
    </div>
    <order-tracking
     :shippingInformation="selectedShippingInformation"
     :trackingNumber="selectedTrackingNumber"
     @close="() => { selectedShippingInformation = undefined;  selectedTrackingNumber = ''}"></order-tracking>
  </div>
</template>

<script lang="ts">
import ItemTable from '@/components/table/item-table.vue'
import LoadingText from '@/components/utility/loading.vue'
import { CartItem } from '@/store/cart/types'
import { OrderStatus } from '@/store/orders/constants'
import { IOrder, ShippingInformation, IOrderDocument } from '@/store/orders/types'
import moment from 'moment'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import OrderTracking from '@/components/order-tracking/order-tracking.vue'
import OrderDocuments from '@/components/orders/order-documents.vue'
import { namespace } from 'vuex-class'
import MxSite from '@/mixins/site'
import { isCustomerAdminSite } from '@/helpers/sites.helper'

const nsOrders = namespace('orders')

@Component({
  components: {
    ItemTable,
    LoadingText,
    'order-tracking': OrderTracking,
    'order-documents': OrderDocuments,
  },
})
export default class OrderSummary extends Mixins(MxSite) {
  @nsOrders.State('activeOrder')
  public activeOrder!: IOrder

  @nsOrders.State('orderDocuments')
  public orderDocuments!: IOrderDocument[]

  @nsOrders.State('documentsLoading')
  public documentsLoading!: boolean

  @nsOrders.State('documentsError')
  public documentsError!: string

  public selectedShippingInformation: ShippingInformation | any = { isBlank: true }

  public selectedTrackingNumber = ''

  public showOrderDocuments = false

  @nsOrders.Action('setActiveOrder')
  private setActiveOrder: any

  @nsOrders.Action('fetchOrder')
  private fetchOrder: any

  @nsOrders.Action('fetchDocuments')
  private fetchDocuments: any

  @nsOrders.Action('uploadDocuments')
  public uploadDocuments: any

  @nsOrders.Action('clipDocuments')
  public clipDocuments: any

  @nsOrders.Action('unclipDocuments')
  public unclipDocuments: any

  @nsOrders.Action('deleteDocuments')
  public deleteDocuments: any

  public items: CartItem[] = []
  public orderStatus = OrderStatus
  public tabIndex = 0

  public OrderSummaryTabs: any = {
    Items: 0,
    Files: 1,
    Comments: 2,
    Deliveries: 3,
  }

  public get isMobile() {
    return window.innerWidth <= 1024
  }

  public upload(files: any) {
    this.showOrderDocuments = false
    this.uploadDocuments({ orderNumber: this.activeOrder.SalesOrderNumber, files })
  }

  public clip(documentIds: any) {
    this.showOrderDocuments = false
    this.clipDocuments({ orderNumber: this.activeOrder.SalesOrderNumber, documentIds })
  }

  public unclip(documentId: any) {
    this.showOrderDocuments = false
    this.unclipDocuments({ orderNumber: this.activeOrder.SalesOrderNumber, documentId })
  }

  public deleteFile(documentId: any) {
    this.showOrderDocuments = false
    this.deleteDocuments({ orderNumber: this.activeOrder.SalesOrderNumber, documentId })
  }

  public fileFields = [
    {
      key: 'checkbox',
      label: '',
      thClass: 'responsive-col-size text-left',
      tdClass: 'responsive-col-size text-left',
    },
    {
      key: 'Name',
      label: 'Name',
      thClass: 'responsive-col-size text-left',
      tdClass: 'responsive-col-size text-left',
    },
    {
      key: 'CreateDate',
      label: 'CreateDate',
      thClass: 'text-left',
      tdClass: 'responsive-col-size text-left',
      thStyle: 'resp-th-30',
      formatter: (val: any) => {
        return this.formatDate(val)
      },
    },
    {
      key: 'Size',
      label: 'Size (kb)',
      thClass: 'text-left',
      tdClass: 'responsive-col-size text-left',
      thStyle: 'resp-th-30',
      formatter: (val: any) => {
        return (val / 1024).toFixed(0)
      },
    },
  ]

  public fields = [
    {
      key: 'details',
      label: 'Toggle Details',
      thClass: 'responsive-col-hide text-left',
      tdClass: 'responsive-col-hide text-left',
      thStyle: { width: '10%' },
    },
    {
      key: 'imgThumbnail',
      label: '',
      thClass: 'responsive-col text-left',
      tdClass: 'responsive-col text-left',
      thStyle: { width: '5%' },
    },
    {
      key: 'pcnCatalogNumber',
      label: 'PCN',
      thClass: 'text-left',
      tdClass: 'responsive-col-size text-left',
      thStyle: 'resp-th-30',
    },
    {
      key: 'fingoodTpiMfgMod',
      label: 'Model #',
      thClass: 'text-left',
      tdClass: 'responsive-col-size text-left',
      thStyle: 'resp-th-40',
    },
    {
      key: 'description',
      label: 'Description',
      thClass: 'responsive-col text-left',
      tdClass: 'responsive-col text-left',
      thStyle: { width: '20%' },
    },
    {
      key: 'status',
      label: 'Status',
      thClass: 'responsive-col text-left',
      tdClass: 'responsive-col text-left',
      thStyle: { width: '10%' },
    },
    {
      key: 'price',
      label: 'Unit Price',
      thClass: 'responsive-col text-right',
      tdClass: 'responsive-col text-right',
      thStyle: { width: '10%' },
      formatter: (val: any) => {
        if (val == null) {
          return ''
        } else if (isNaN(val)) {
          return val
        } else {
          return `$${parseFloat(val).toFixed(2)}`
        }
      },
    },
    {
      key: 'quantity',
      label: 'Qty',
      thClass: 'responsive-col text-right',
      tdClass: 'responsive-col text-right',
      thStyle: { width: '10%', minWidth: '80px' },
    },
    {
      key: 'totalPrice',
      label: 'Total Price',
      thClass: 'text-right',
      tdClass: 'responsive-col-size text-right',
      thStyle: 'resp-th-20',
      formatter: (val: any) => {
        if (val == null) {
          return ''
        } else if (isNaN(val)) {
          return val
        } else {
          return `$${parseFloat(val).toFixed(2)}`
        }
      },
    },
  ]

  public selectAll = false

  public selectedRows: CartItem[] = []
  public loading = false

  public get grandTotal() {
    return this.activeOrder.Total + (this.activeOrder.Tax || 0.0)
  }

  @Watch('activeOrder')
  public handleOrderAdded() {
    this.items = [...this.activeOrder.Items]
    this.selectedRows = [...this.activeOrder.Items]
    if (this.activeOrder.Deliveries.length > 0 && this.activeOrder.Deliveries[0].ShippingInformation && this.activeOrder.Deliveries[0].ShippingInformation.TrackingUpdatesList?.length > 0) {
      this.tabIndex = this.OrderSummaryTabs.Deliveries
    }
  }

  @Watch('documentsError')
  public displayDocumentError() {
    if (this.documentsError) {
      this.toastError(this.documentsError)
    }
  }

  created() {
    window.scrollTo({
      top: 0,
      behavior: 'instant', // Optional: Add smooth scrolling animation
    })
    this.loading = true
    this.fetchOrder(this.$route.params.invoiceNo).then(async () => {
      this.items = [...this.activeOrder.Items]
      this.selectedRows = [...this.activeOrder.Items]
      this.loading = false
      if (this.activeOrder.Deliveries.length > 0 && this.activeOrder.Deliveries[0].ShippingInformation && this.activeOrder.Deliveries[0].ShippingInformation.TrackingUpdatesList?.length > 0) {
        this.tabIndex = this.OrderSummaryTabs.Deliveries
      }
    })
    if (this.site.ManageDocuments) {
      this.fetchDocuments(this.$route.params.invoiceNo)
    }
  }

  public get selectedItems(): CartItem[] {
    return this.items.filter((item) => this.selectedRows.includes(item))
  }

  public get totalSelectedQty(): number {
    return this.selectedItems.reduce((total, item) => total + item.quantity, 0)
  }

  public get totalSelectedPrice(): number {
    return this.selectedItems.reduce(
      (total, item) => total + item.quantity * item.price,
      0,
    )
  }

  public formatCost(cost: number): string {
    return `$${cost.toFixed(2)}`
  }

  public formatDate(date) {
    return moment(date).format('YYYY-MM-DD')
  }

  public setSelectedShippingInformation(information: ShippingInformation, trackingNumber: string) {
    this.selectedTrackingNumber = trackingNumber
    this.selectedShippingInformation = information
  }

  public formatDeliveryDate(date: string) {
    return moment.utc(date).local()
  }

  public _isCustomerAdminSite() {
    return isCustomerAdminSite(this.role)
  }
}
</script>

<style lang="scss">
.action-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px 4px 8px;
  background-color: #f4f7f9;
  position: sticky;
  top: 0;
  z-index: 10;

  .selected-info {
    display: flex;
    align-items: center;
    gap: 20px;

    .items {
      width: 15vh;
      text-align: center;
      border-right: 1px solid #a6c2ef;
    }
  }
  .actions {
    float: right;
  }
}

.back-btn {
  cursor: pointer;
}

.info-section {
  border-top: 1px solid #dee2e6 !important;
  padding-top: 0.5rem;
  margin-bottom: 1rem;
  @media (min-width: 767px) {
    border-right: 1px solid #dee2e6 !important;
    border-top: none !important;
    padding-top: 0;
    margin-bottom: 0;
  }
  .info-section-header {
    color: #7c97b6;
    font-weight: bold;
    font-size: small;
  }
}
</style>
