import { Component } from 'vue-property-decorator'
import { State, namespace } from 'vuex-class'
import { RawLocation } from 'vue-router'
import MxSecurity from '@/mixins/security'

import { PropertyType, FormatType, Site, EntityBase, Catalog, Category, ProductClass, ManufacturingSeries, Product } from '@/store/types'
import { Division } from '@/store/editor-divisions/types'
import { ILayoutColumn } from '@/store/product/types'
import { isCustomerSite, isSalesRepSite, isCustomerAdminSite } from '@/helpers/sites.helper'
import { loadErrorImage } from '@/helpers/image.helper'
import { EntityObject, EntityObjectLookup } from '@/store/entity/types'
import _, { get } from 'lodash'
import { blankCatalog } from '@/store/constants'

const nsCommon = namespace('common')
const nsEntity = namespace('entity')
const nsTree = namespace('tree')

@Component
export default class extends MxSecurity {
  public propertyType = PropertyType // this is needed in order to reference the enum from the template
  public formatType = FormatType // this is needed in order to reference the enum from the template
  public hideBreadcrumb?: boolean // this is needed in order to reference the enum from the template

  @nsCommon.Action('setOverlayShowing')
  public setOverlayShowing!: any

  @nsCommon.Action('hideBreadcrumbDisplay')
  public hideBreadcrumbDisplay!: any

  @State('site')
  public site!: Site

  @State('catalogs')
  public catalogs!: Catalog[]

  @State('divisions')
  public divisions!: Division[]

  @State('sites')
  public sites!: Site[]

  @nsTree.State('treeLoading')
  public treeLoading!: boolean

  @nsTree.Action('setTreeLoading')
  public setTreeLoading: any

  @nsTree.Mutation('setReloadTree')
  public setReloadTree: any

  @nsTree.State('reloadTree')
  public reloadTree!: boolean

  @nsEntity.State('entityObjectLookup')
  public entityObjectLookup!: EntityObjectLookup

  // not just for Model, but things like Description can do this too?
  public getObjectValue = (obj: any, column: ILayoutColumn) => {
    return (obj.formattedValue ? obj.formattedValue[column.key] : undefined) || get(obj, column.key) || ''
  }

  public isValuePopulated(obj: any, column: ILayoutColumn) {
    const value = this.getObjectValue(obj, column)
    return value.length > 0
  }

  // // propertyTitleFields or make getPropertyTitles?
  public getTitles(entityObject: EntityObject, obj: EntityBase) {
    // I feel like I'm mixing two things here
    // - what has an editorField vs. what has an editor only
    // if (this.editorField) {
    //   return this.editorField.PropertyEditor?.titleProperties.map(c => obj[c.PropertyName])
    // } else {
    // TODO: find wher editor is undefined and use something else instead!
    return entityObject.titleProperties.map(c => obj[c.PropertyName])
    // }
  }

  public getTitleByEntityObject(entityObject: EntityObject, obj: EntityBase) {
    return this.getTitles(entityObject, obj).join(', ') || ''
  }

  public getComponentIdByEntityObject(entityObject: EntityObject, obj: EntityBase) {
    // TODO: wrong level?
    // - yes - we need to go get the next level of entityObject!
    return entityObject.idProperties.map(c => obj[c.PropertyName]).join(':') || ''
  }

  get screen() { return (this as any).$breakpoints.screen }

  get scrollY() { return (this as any).$breakpoints.scrollY }

  get role() {
    return process.env.VUE_APP_TPI_ROLE
  }

  public _isCustomerSite(site: string) {
    return isCustomerSite(site)
  }

  public _isCustomerAdminSite(role: string) {
    return isCustomerAdminSite(role)
  }

  public _isSalesRepSite(site: string) {
    return isSalesRepSite(site)
  }

  public _hasHistory() {
    return window.history.length > 1
  }

  public _loadErrorImage(event: ErrorEvent, width: string, height: string) {
    loadErrorImage(event, width, height)
  }

  public get catalogId() {
    return this.$route.query.catalogId ? +this.$route.query.catalogId : this.site.CatalogId
  }

  public get catalog() {
    if (this.catalogs.length === 1) {
      return this.catalogs[0]
    }
    return this.catalogs.find(catalog => catalog.CatalogId === this.catalogId) ?? blankCatalog // || this.site.CatalogId
  }

  public get isPreview() {
    return this.$route.query.isPreview?.toString() === 'true' || this.isEditor
  }

  public get isEditor() {
    return this.site?.Name === 'editor' // || this.site.CatalogId
  }

  public getCatalogTo() {
    const { isPreview, catalog } = this
    if (catalog) {
      const { CatalogId: catalogId } = catalog
      return {
        name: 'catalog',
        query: { catalogId, isPreview },
      }
    } else {
      return { name: 'catalog' }
    }
  }

  public getTopologyToByNames(
    catalogId?: number,
    isPreview?: boolean,
    category?: string,
    productClass?: string,
    manufacturingSeries?: string,
    product?: string,
    section?: string,
    sectionTab?: string,
    sectionTable?: string,
    catalogNumber?: string,
  ): RawLocation {
    const params: any = {}
    const query: any = {}

    let name = 'catalog'

    if (category) {
      name = 'menu-page-category'
      params.category = category
    }

    if (productClass) {
      name = 'menu-page-productclass'
      params.productclass = productClass
    }

    if (manufacturingSeries) {
      name = 'menu-page-productclass'
      params.series = manufacturingSeries // note: not used?
    }

    if (product) {
      name = 'product'
      params.product = product
    }

    if (section) {
      params.section = section
    }

    if (sectionTab) {
      params.tab = sectionTab
    }

    if (sectionTable) {
      query.sectionTable = sectionTable
    }

    if (catalogNumber) {
      query.catalogNumber = catalogNumber
    }

    // Only add a CatalogId if we're on a site that isn't already this catalogId
    // - it will default in the action
    if (catalogId && this.site.CatalogId !== catalogId) {
      const strIsPreview = isPreview ? 'true' : undefined // remove isPreview if it's false
      query.catalogId = catalogId.toString()
      query.isPreview = strIsPreview
    }

    return {
      name,
      params,
      query,
    }
  }

  public getTopologyTo(
    catalogId?: number,
    isPreview?: boolean,
    category?: Category,
    productClass?: ProductClass,
    manufacturingSeries?: ManufacturingSeries,
    product?: Product,
  ): RawLocation {
    return this.getTopologyToByNames(
      catalogId,
      isPreview,
      category?.Name,
      productClass?.Name,
      manufacturingSeries?.Name,
      product?.Name,
    )
  }

  public getSearchTo(
    keyword: string,
    catalogId?: number,
    isPreview?: boolean,
  ): RawLocation {
    const params: any = {}
    const query: any = {}
    const name = 'search'

    if (keyword.trimEnd().length > 0) {
      params.keyword = keyword
    }

    // Only add a CatalogId if we're on a site that isn't already this catalogId
    // - it will default in the action
    if (catalogId && this.site.CatalogId !== catalogId) {
      const strIsPreview = isPreview ? 'true' : undefined // remove isPreview if it's false
      query.catalogId = catalogId.toString()
      query.isPreview = strIsPreview
    }

    return {
      name,
      params,
      query,
    }
  }

  public togglePreview() {
    const { query } = this.$route
    const newQuery = _.assign({}, query)
    newQuery.isPreview = (!query.isPreview).toString()
    this.$router.replace({ query: newQuery })
  }
}
