import { Route } from 'vue-router'

export enum PropertyType {
  String = 'String',
  List = 'List',
  Object = 'Object',
  Table = 'Table',
  DivisionModelNumber = 'DivisionModelNumber',
  Image = 'Image'
}

export enum EntityLevel {
  None = -1,
  All = 0,
  Category = 1,
  ProductClass = 2,
  ManufacturingSeries = 3,
  Product = 4,
  Model = 5,
  Image = 6,
  Schedule = 7,
  Site = 8,
  Division = 9,
}

// note: List here could have been ListComponent?
export enum FormatType {
  Delimited = 'Delimited',
  Component = 'Component',
  List = 'List',
  Number = 'Number',
}

export interface BooleanState {
  [key: number]: boolean
}

export interface BooleanStringState {
  [key: string]: boolean
}

export interface ObjectStringState {
  [key: string]: EntityBase
}

export interface AnyStringState {
  [key: string]: any
}

export interface Loading {
  loading: boolean
}

export interface None {
  isNone: boolean
  // Name: string
  // DisplayName: string
  // Visible: boolean
  // OrderBy: number
}

export interface EntityBase extends Loading, None {
  open: boolean
  key: string
  Id?: string // TODO: have to add this to all Response types!
  [key: string]: any
  instanceKey?: string
}

export interface ResponseArray<T> {
  List: T[]
  loading: boolean
  Count: number
  Page: number
  PageSize: number
  Successful: boolean
  Total: number
  TotalPages: number
}

export interface ManufacturingSeries extends EntityBase {
  ManufacturingSeriesId: number
  DisplayName: string
  Name: string
}

export interface ProductClass extends EntityBase {
  ProductClassId: number
  DisplayName: string
  Name: string
}

export interface Category extends EntityBase {
  CategoryId: number
  DisplayName: string
  Name: string
}

export interface Product extends EntityBase {
  ProductId: number
  DisplayName: string
  Name: string
}

export interface Catalog {
  CatalogId: number
  Name: string
  DisplayName: string
  HasBreadcrumb: boolean
  CartEnabled: boolean
  ProductImageEnabled: boolean
  FeatureListEnabled: boolean
}
export interface Site {
  SiteId: number
  Name: string
  DisplayName: string
  DivisionName: string
  CatalogId?: number
  LogoPath: string
  LogoLink: string
  FaviconPath: string
  disabled: boolean
  BrowserTitle: string
  ShowInventory: boolean
  ManageDocuments: number
  EnableExport: boolean
  EnableChangeRequest: boolean
  HasPricing: boolean
  ShowCatalog: boolean
}

export interface Division {
  DivisionName: string
  DisplayName: string
  OEM: boolean
}

export interface Schedule {
  ScheduleId : number
  Name: string
  Frequency: string
  FrequencyValue: number
  FrequencyDate: Date
  Enabled: boolean
  ParameterJson : string
}

export interface ArrayLoading<T> extends Array<T>, Loading {}

interface RootState {
  activeRoute: Route | null
  role: string
  error: boolean
  divisions: Division[]
  catalogs: Catalog[]
  sites: Site[]
  schedules: Schedule[]
  division: Division
  site: Site
  preloaded: boolean
  isLoginAllowed: boolean
  apiPrefix: string | undefined
}

export default RootState
export { RootState }
