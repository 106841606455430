import { ArrayLoading } from '@/store/types'
import { loading } from '@/store/constants'

import {
  Cache,
} from './types'

import _ from 'lodash'

export const loadingCache: ArrayLoading<Cache> = _.assign([], loading)
