import { render, staticRenderFns } from "./features-list.vue?vue&type=template&id=6630dfc6"
import script from "./features-list.vue?vue&type=script&lang=ts"
export * from "./features-list.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.12_css-loader@6.11.0_webpack@5.93.0__lodash@4.17.21__xmvfxktgm343a47uft5qnbk3jm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports