import {
  SearchResult,
  VisibleModelSearchRequest,
} from './types'
import _ from 'lodash'
import { ArrayLoading, ResponseArray } from '../types'
import { Model } from '../product/types'
import { blankResponseArray } from '../constants'

export const loadingResults: ArrayLoading<SearchResult> = _.assign([], { loading: true })
export const loadingVisibleModelSearchResult: ResponseArray<Model> = _.assign({}, blankResponseArray)

export const blankVisibleModelSearchRequest : VisibleModelSearchRequest = {
  Keyword: '',
  Site: '',
  PageSize: 10,
  Page: 1,
  Start: 1,
}
