import { Module } from 'vuex'
import { blankResponseArray } from '../constants'
import { RootState } from '../types'
import { actions } from './actions'
import { blankOrder, blankSearchFilters } from './constants'
import { getters } from './getters'
import { mutations } from './mutations'
import State from './types'
import _ from 'lodash'

export const state: State = {
  error: false,
  activeOrder: _.cloneDeep(blankOrder),
  page: 1,
  pageSize: 8,
  orders: blankResponseArray,
  preloaded: false,
  isSubmittingOrder: false,
  submissionError: '',
  documentsError: '',
  currentOrder: _.cloneDeep(blankOrder),
  validationErrors: {},
  shippingCosts: [],
  documentsLoading: false,
  orderDocuments: [],
  searchFilters: _.cloneDeep(blankSearchFilters),
}

const namespaced = true

export const orders: Module<State, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
