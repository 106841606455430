<template>
    <b-container fluid>
      <b-row class="mx-3">
        <b-col cols="12" class="p-0">
          <b-form-group size="sm" label="Documents" label-cols="4" label-cols-lg="2" label-size="sm" v-if="showFileUpload">
            <b-row>
              <file-upload
                  v-model="files"
                  style="position: relative; min-height: 100px; overflow: hidden;"
                  class="col-11"
              ></file-upload>
              <b-col md="1">
                <b-button variant="primary" @click="uploadFiles()">Upload</b-button>
              </b-col>
            </b-row>
          </b-form-group>
          <b-row class="item-table-container" v-if="showClipUnclip || showDelete">
            <b-table
                :fields="fileFields"
                :items="documents"
                responsive
                hover
                borderless
                sticky-header
                class="item-table"
                style="min-height: 200px;"
              >
                <template v-slot:cell(Name)="{ item }">
                  <b-link :href="'/api/order/document/' + item.Id" target="_blank">
                    {{ item.Name }}<template v-if="item.SectionCount == 1">{{ item.FileExtension }}</template>
                  </b-link>
                  <span v-if="item.SectionCount > 1"> ({{ item.SectionCount }} Files)
                    <b-btn variant="outline-primary" @click="unclipFiles(item.Id)">Unclip</b-btn>
                  </span>
                </template>
                <template v-slot:cell(Selected)="{ item }">
                  <b-form-checkbox v-model="item.Selected" v-if="showClipUnclip">
                  </b-form-checkbox>
                </template>
                <template v-slot:cell(deleteFile)="{ item }">
                  <b-button variant="outline-danger" @click="confirmDeleteFile(item.Id)" v-if="showDelete">
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                  </b-button>
                </template>
              </b-table>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" style="text-align: center;">
          <b-button variant="outline-primary" v-if="selectedCount > 1" @click="clipFiles()">Clip</b-button>
        </b-col>
      </b-row>
    </b-container>
    <!-- TODO: two column -->

</template>
<script lang="ts">
import { Component, Prop, Mixins, Emit } from 'vue-property-decorator'
import { IOrderDocument } from '@/store/orders/types'
import draggable from 'vuedraggable'
import moment from 'moment'
import FileUpload from '@/components/utility/file-upload.vue'

import MxSite from '@/mixins/site'

@Component({
  components: {
    draggable,
    'file-upload': FileUpload,
  },
})
export default class extends Mixins(MxSite) {
  @Prop({ default: false })
  public showFileUpload!: boolean

  @Prop({ default: false })
  public showClipUnclip!: boolean

  @Prop({ default: false })
  public showDelete!: boolean

  @Prop({ default: [] })
  private orderDocuments!: IOrderDocument[]

  public documents: IOrderDocument[] = []

  public files: File[] = []

  public newFileId = -1

  mounted() {
    this.documents = [...this.orderDocuments]
  }

  get selectedCount() {
    const { documents } = this
    return documents.filter(doc => doc.Selected).length
  }

  @Emit('filesSelected')
  public filesSelected(): File[] {
    if (this.files && this.files.length > 0) {
      return this.files
    } else {
      return []
    }
  }

  public rowClicked(item: any) {
    item.Selected = !item.Selected
  }

  @Emit('clip')
  public clipFiles(): number[] {
    const { documents } = this
    const documentIds = documents.filter(doc => doc.Selected).map(doc => doc.Id)
    return documentIds
  }

  @Emit('unclip')
  public unclipFiles(id: number): number {
    return id
  }

  public confirmDeleteFile(id: number) {
    if (confirm('Are you sure you want to delete this file?')) {
      this.deleteFile(id)
    }
  }

  @Emit('delete')
  public deleteFile(id: number): number {
    return id
  }

  @Emit('upload')
  public uploadFiles(): File[] {
    return this.files
  }

  @Emit('close')
  public close() {
    // just emit
  }

  public fileFields = [
    {
      key: 'Selected',
      label: '',
      thClass: 'responsive-col-size text-left',
      tdClass: 'responsive-col-size text-left',
      thStyle: 'width:30px',
    },
    {
      key: 'Name',
      label: 'Name',
      thClass: 'responsive-col-size text-left',
      tdClass: 'responsive-col-size text-left',
    },
    {
      key: 'CreateDate',
      label: 'CreateDate',
      thClass: 'text-left',
      tdClass: 'responsive-col-size text-left',
      thStyle: 'resp-th-30',
      formatter: (val: any) => {
        return this.formatDate(val)
      },
    },
    {
      key: 'Size',
      label: 'Size (kb)',
      thClass: 'text-left',
      tdClass: 'responsive-col-size text-left',
      thStyle: 'resp-th-30',
      formatter: (val: any) => {
        return (val / 1024).toFixed(0)
      },
    },
    {
      key: 'deleteFile',
      label: '',
    },
  ]

  public formatDate(date) {
    return moment(date).format('YYYY-MM-DD')
  }
}

</script>
<style scoped>

</style>
