import { Api } from '@/api/Api'
import { ActionTree } from 'vuex'
import { RootState } from '../types'
import State, { ICountry, ICountryState } from './types'

const api = new Api()

export const actions: ActionTree<State, RootState> = {
  fetchCountries({ commit }): any {
    return api
      .fetch({
        url: '/common/countries',
      })
      .then((response) => {
        const payload: ICountry[] = response.List
        commit('countriesLoaded', payload)
      })
      .catch(() => {
        commit('countryError')
      })
  },

  fetchCountryStates({ commit }, countryAlphaCode: string): any {
    return api
      .fetch({
        url: '/common/country_states?countryAlphaCode=' + countryAlphaCode,
      })
      .then((response) => {
        const payload: ICountryState[] = response.List
        commit('countryStatesLoaded', payload)
      })
      .catch(() => {
        commit('countryStatesError')
      })
  },

  setOverlayShowing({ commit }, isShowing: boolean) {
    return new Promise(resolve => {
      commit('overlayLoadingSet', isShowing)
      resolve(isShowing)
    })
  },

  hideBreadcrumbDisplay({ commit }) {
    return new Promise(resolve => {
      commit('hideBreadcrumbSet', true)
      resolve(true)
    })
  },

  resetBreadcrumbDisplay({ commit }) {
    return new Promise(resolve => {
      commit('hideBreadcrumbSet', undefined)
      resolve(undefined)
    })
  },
}
