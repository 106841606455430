import { Vue, Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import {
  LayoutProperty,
  LayoutProperties,
  Topology,
  Category,
  Role,
  ValidationParameters,
  LayoutPropertyColumn,
  ValidationParametersEntity,
} from '@/store/editor/types'

import { ProductModel } from '@/store/editor-model/types'

import MxSite from '@/mixins/site'

import { BooleanStringState, EntityBase, EntityLevel } from '@/store/types'
import { blankLayoutProperty } from '@/store/editor/constants'
import { Editor, EditorField, EditorLookup } from '@/store/entity/types'

const nsEditor = namespace('editor')
const nsEditorState = namespace('editor-state')
const nsEntity = namespace('entity')

@Component
export default class extends MxSite {
  public entityLevel = EntityLevel // this is needed in order to reference the enum from the template

  @nsEditor.Getter('categories')
  public categories!: Category[]

  @nsEditor.Getter('treeCategories')
  public treeCategories!: Category[]

  @nsEditor.Getter('activeItemId')
  public activeItemId!: number

  @nsEditor.Getter('activeTopologyFull')
  public activeTopologyFull!: Topology

  @nsEditor.Getter('currentTopologyFull')
  public currentTopologyFull!: Topology

  @nsEditor.Getter('activeItemTopologyUrl')
  public activeItemTopologyUrl!: string

  @nsEditor.Getter('getColumnValid')
  public getColumnValid!: any

  @nsEditor.Getter('getColumnValidOrUnvalidated')
  public getColumnValidOrUnvalidated!: any

  @nsEditor.State('layoutProperties')
  public layoutProperties!: LayoutProperties

  @nsEntity.State('editorLookup')
  public editorLookup!: EditorLookup

  @nsEditor.Getter('getRealLayoutProperty')
  public getRealLayoutProperty!: any

  @nsEditor.State('roles')
  public roles!: Role[]

  @nsEditor.Action('updateFieldValue')
  public updateFieldValue!: (validationParameters: ValidationParameters) => Promise<boolean>

  @nsEditor.Action('updateFieldValueByEntityProperty')
  public updateFieldValueByEntityProperty!: (validationParametersEntity: ValidationParametersEntity) => Promise<boolean>

  @nsEditor.Mutation('setPropertyArrayOrder')
  public setPropertyArrayOrder!: (parameters: { objList: EntityBase[], originalObjList: EntityBase[], oldIndex: number, newIndex: number, editorField?: EditorField }) => Promise<void>

  @nsEditor.Action('toggleEditorSectionOpen')
  public toggleEditorSectionOpen!: any

  @nsEditor.Action('setEditorSectionOpen')
  public setEditorSectionOpen!: any

  @nsEditorState.State('collapseOpen')
  public collapseOpen!: BooleanStringState

  @nsEditorState.Action('toggleCollapseState')
  public toggleCollapseState!: any

  @nsEditorState.Mutation('setCollapseState')
  public setCollapseState!: any

  // @nsEditor.Mutation('setCurrentTopologySectionTable')
  // public setCurrentTopologySectionTable: any

  @nsEditor.State('editorLoading')
  public editorLoading!: boolean

  @nsEditor.Action('setEditorLoading')
  public setEditorLoading!: any

  @nsEditor.State('defaultSectionTableColumns')
  public defaultSectionTableColumns!: LayoutPropertyColumn[]

  @nsEditor.Mutation('setDefaultSectionTableColumns')
  public setDefaultSectionTableColumns: any

  @nsEditor.Mutation('setUpdated')
  public setUpdated!: any

  get staticMobileFields(): string[] {
    return ['PCN_CATALOGNUMBER', 'FingoodTpiMfgMod', 'DivisionModelNumbers', 'TotalList']
  }

  get modelEditor(): Vue & { showDetails: (item: ProductModel) => void, addModel: () => void } {
    return this.findRefByName('modelEditor')
  }

  public getPropertiesId(properties: LayoutProperty[]): LayoutProperty | undefined {
    return properties.find(p => p.IsId)
  }

  public getChildLayoutPropertyByEntityPropertyName(layoutProperty: LayoutProperty, entityPropertyName: string): LayoutProperty {
    let ret = layoutProperty.Properties.find(p => p.EntityPropertyName === entityPropertyName)

    if (ret === undefined) {
      layoutProperty.Sections.every(s => {
        ret = s.Properties.find(p => p.EntityPropertyName === entityPropertyName)
        return !ret
      })
    }
    return ret || blankLayoutProperty
  }

  public getChildLayoutPropertyByPropertyName(layoutProperty: LayoutProperty, propertyName: string): LayoutProperty {
    let ret = layoutProperty.Properties.find(p => p.PropertyName === propertyName)

    if (ret === undefined) {
      layoutProperty.Sections.every(s => {
        ret = s.Properties.find(p => p.PropertyName === propertyName)
        return !ret
      })
    }
    return ret || blankLayoutProperty
  }

  public findRefByName(refName: string): any {
    let obj = this as any // as Vue
    while (obj) {
      if (obj.$refs[refName]) {
        return obj.$refs[refName]
      }
      obj = obj.$parent
    }
    return undefined
  }

  public viewItemChangeLog() {
    const { $router, activeTopologyFull } = this
    const query: any = {}
    if (activeTopologyFull.category.CategoryId) {
      query.categoryId = activeTopologyFull.category.CategoryId + ''
    }

    if (activeTopologyFull.productClass.ProductClassId) {
      query.productClassId = activeTopologyFull.productClass.ProductClassId + ''
    }

    if (activeTopologyFull.manufacturingSeries.ManufacturingSeriesId) {
      query.manufacturingSeriesId = activeTopologyFull.manufacturingSeries.ManufacturingSeriesId + ''
    }

    if (activeTopologyFull.product.ProductId) {
      query.productId = activeTopologyFull.product.ProductId + ''
    }
    $router.push({ name: 'log-viewer', query })
  }

  public viewModelChangeLog({ pcnCatalogNumber }: { pcnCatalogNumber: string }) {
    const { $router } = this
    const query: any = { keyword: pcnCatalogNumber }
    $router.push({ name: 'log-viewer', query })
  }

  // TODO: remove LayoutProperty when we're ready!
  public isFieldDisabled(layoutPropertyOrEditorField: EditorField) {
    return !layoutPropertyOrEditorField?.EffectivePermission.CanUpdate
  }

  // getTitle(item: EntityBase, editorField: EditorField) {
  //   if (editorField) {
  //     const titleProperties = this.getTitleProperties(editorField)
  //     return titleProperties?.map(c => item[c.EntityProperty.PropertyName]).join(': ') || ''
  //   } else { // TODO: this.titleProperty is the old way!
  //     return this.titleProperty ? item[this.titleProperty.PropertyName] : ''
  //   }
  // }

  // getId(item: EntityBase) {
  //   return this.idProperty ? item[this.idProperty.PropertyName] : ''
  // }

  public blurDisplayName(fallbackItem: any, item: any) {
    if (item.DisplayName === '' || item.DisplayName === fallbackItem.DisplayName) {
      Vue.set(item, 'DisplayName', null)
    }
  }

  public focusDisplayName(fallbackItem: any, item: any) {
    if (!item.DisplayName || item.DisplayName === '' || item.DisplayName === null) {
      Vue.set(item, 'DisplayName', fallbackItem.DisplayName)
    }
  }

  public blurMobileDisplayName(item: { DisplayName: string, MobileDisplayName: string }) {
    if (item.MobileDisplayName === '' || item.MobileDisplayName === item.DisplayName) {
      Vue.set(item, 'MobileDisplayName', null)
    }
  }

  public focusMobileDisplayName(item: { DisplayName: string, MobileDisplayName: string }) {
    if (!item.MobileDisplayName || item.MobileDisplayName === '' || item.MobileDisplayName === null) {
      Vue.set(item, 'MobileDisplayName', item.DisplayName)
    }
  }

  public getBlankEntityObject(editor: Editor) {
    const obj: any = {}
    editor.Sections.forEach(editorSection => {
      editorSection.Fields.forEach(editorField => {
        obj[editorField.EntityProperty.PropertyName] = editorField.DefaultValue
      })
    })
    return obj
  }

  public getEditorFieldDefaultValue(editorField: EditorField) {
    if (editorField.PropertyEditor) { // this means it's an object
      return this.getBlankEntityObject(editorField.PropertyEditor)
    } else {
      return editorField.DefaultValue
    }
  }

  public sortByProperty(prop) {
    return function(a, b) {
      if (a[prop].toUpperCase() > b[prop].toUpperCase()) {
        return 1
      } else if (a[prop].toUpperCase() < b[prop].toUpperCase()) {
        return -1
      }
      return 0
    }
  }
}
