import { EntityLevel, ArrayLoading, BooleanStringState } from '@/store/types'

import {
  Category, ProductClass, ManufacturingSeries, Product, Image, Topology,
  Document,
  Active, Current, ActiveNone, CurrentTopology,
  CurrentCategory, CurrentProductClass, CurrentManufacturingSeries, CurrentProduct,
  ActiveCategory, ActiveProductClass, ActiveManufacturingSeries, ActiveProduct, ActiveImage,
  SectionTable,
  LayoutProperty,
  EditBase, MultiLineOptions, Role, DivisionModel, ModelAssociationForAccesssoryAndReplacementPart, ActiveDocument, SectionTableModel, TopologyNone, TopologyBase,
} from './types'
import _ from 'lodash'
import { blankEntityBase, loading, none } from '@/store/constants'
import { EffectivePermission } from '../security/types'

export const blankEditBase: EditBase = _.assign({ Updated: false, Created: false, Deleted: false, open: false, key: '', error: false }, blankEntityBase)

// these constants are useful so we don't constantly define them over and over
export const active: Active = _.assign({ entityLevel: EntityLevel.None, isNone: false, id: -1, Name: '', DisplayName: '', Visible: true, OrderBy: 0, Created: false }, blankEditBase)
export const current: Current = _.assign({ isNone: false, Name: '', DisplayName: '', Visible: true, OrderBy: 0 }, blankEditBase)

export const activeNone: ActiveNone = _.assign({}, blankEntityBase, active, none)

// export const currentNone: CurrentNone = _.assign({}, current, { isNone: true }, none)
export const loadingImage: ArrayLoading<Image> = _.assign([], loading)
// export const loadingSpecifications: ArrayLoading<Specification> = _.assign([], loading)
// export const loadingFeatures: ArrayLoading<Feature> = _.assign([], loading)
// export const loadingProductNotes: ArrayLoading<ProductNote> = _.assign([], loading)

export const activeNew: ActiveNone = _.assign({}, blankEntityBase, active, none, loading)

export const blankTopology: TopologyBase = _.assign({ Name: '', DisplayName: '', Visible: true, IsPreview: true, OrderBy: 0, Catalogs: [] }, blankEditBase)

// note: these should not also need blankEditBase but I'm getting an error
export const blankCategory: Category = _.assign({ CategoryId: 0, ProductClasses: [], Images: [], Divisions: [] }, blankTopology, blankEditBase)
export const blankProductClass: ProductClass = _.assign({ ProductClassId: 0, ManufacturingSeries: [], Divisions: [] }, blankTopology, blankEditBase)
export const blankManufacturingSeries: ManufacturingSeries = _.assign({ ManufacturingSeriesId: 0, TpiMfgSeries: '', Products: [], Divisions: [] }, blankTopology, blankEditBase)
export const blankProduct: Product = _.assign({ ProductId: 0, Name: '', DisplayName: '', Description: '', Visible: true, OrderBy: 0, Sections: [], Tables: [], Notes: [], Specifications: [], Features: [], Images: [], Divisions: [], Sites: [] }, blankTopology, blankEditBase)

export const noneTopology: TopologyNone = _.assign({}, blankTopology, none)
export const noneCategory: Category = _.assign({}, blankCategory, noneTopology)
export const noneProductClass: ProductClass = _.assign({}, blankProductClass, noneTopology)
export const noneManufacturingSeries: ManufacturingSeries = _.assign({}, blankManufacturingSeries, noneTopology)
export const noneProduct: Product = _.assign({}, blankProduct, noneTopology)

// export const blankSection: Section = _.assign({ SectionId: -1, CategoryId: -1, ProductClassId: -1, ManufacturingSeriesId: -1, ProductId: -1, DisplayName: '', OrderBy: -1, Visible: true, active: true, Tabs: [] }, blankEditBase)
// export const blankSectionTab: SectionTab = _.assign({ SectionTabId: -1, DisplayName: '', Tables: [], OrderBy: -1, Visible: true, active: true }, blankEditBase)
export const blankSectionTable: SectionTable = _.assign({ CategoryId: -1, ProductClassId: -1, ManufacturingSeriesId: -1, ProductId: -1, SectionId: -1, SectionTabId: -1, SectionTableId: -1, SelectName: '', OrderBy: -1, active: true, Visible: true, IsPreview: true, key: 'sectionTable--1' }, blankEditBase)
export const blankSectionTableModel: SectionTableModel = _.assign({ SectionId: -1, SectionTabId: -1, SectionTableId: -1, SectionTableModelId: -1, SelectName: '', OrderBy: -1, active: true, Visible: true, key: 'sectionTableModel--1' }, blankEditBase)

export const blankEffectivePermission: EffectivePermission = _.assign({ ModuleId: -1, CanRead: false, CanUpdate: false, CanCreate: false, CanDelete: false, CanExecute: false }, blankEditBase) // even through you can't edit it (yet)

export const blankImage: Image = _.assign({ ImageId: -1, Path: '', ImageName: '', ImagePath: '', OrderBy: -1, Height: 0, Width: 0, Caption: '', AltText: '', IsThumbnail: false, ExplodedView: false, IsDeleted: false }, blankEditBase)
export const blankDocument: Document = _.assign({ DocumentId: -1, Path: '', DocumentPath: '', OrderBy: -1, Caption: '' }, blankEditBase)

export const blankLayoutProperty: LayoutProperty = _.merge({
  EntityName: '',
  EntityPropertyName: '',
  ParentEntityName: '',
  ParentEntityPropertyName: '',
  LayoutPropertyId: -1,
  ParentPropertyName: '',
  PropertyName: '',
  PropertyType: '',
  Name: '',
  DisplayName: '',
  DisplayDescription: '',
  SortBy: false,
  FilterBy: false,
  KeywordSearchBy: false,
  IsMappable: false,
  EntityType: '',
  Properties: [],
  FormatType: '',
  FormatValue: '',
  Sections: [],
  Values: [],
  IsId: false,
  IsTitle: false,
  DefaultValue: '',
  DivisionOverideEntityPropertyName: null,
  selectName: 'Choose a Field',
}, blankEntityBase)

export const blankLayoutPropertyEditorSection = _.merge({
  EditorSection: '',
  Properties: [],
}, blankEntityBase)

export const blankRole: Role = _.assign({ RoleId: -1, Name: '' }, blankEditBase)

export const blankDivisionModel: DivisionModel = _.assign({ DivisionModelId: -1, Division: '', PcnCatalogNumber: '', ModelNumber: null, Pcn: null, Barcode: null, Series: null, OEM: false }, blankEditBase)

export const blankModelAssociationForAccesssoryAndReplacementPart: ModelAssociationForAccesssoryAndReplacementPart = _.assign({ PcnCatalogNumber: '', FingoodTpiMfgMod: '', ReplacementPart: false, Accessory: false }, blankEditBase)

// note: object loadings, as opposed to Array loadings need to be after the blanks
export const loadingLayoutProperty: LayoutProperty = _.assign([], { loading: true }, blankLayoutProperty)

export const loadingProduct: Product = _.assign({}, blankProduct, { loading: true })

export const loadingRole: Role[] = _.assign([], { loading: true }, blankRole)

export const activeCategoryNone: ActiveCategory = _.assign({}, blankCategory, active, none)
export const activeProductClassNone: ActiveProductClass = _.assign({}, blankProductClass, active, none)
export const activeManufacturingSeriesNone: ActiveManufacturingSeries = _.assign({}, blankManufacturingSeries, active, none)
export const activeProductNone: ActiveProduct = _.assign({}, blankProduct, active, none)

export const activeImageNone: ActiveImage = _.assign({}, blankImage, active, none)
export const activeDocumentNone: ActiveDocument = _.assign({}, blankDocument, active, none)

export const currentCategoryNone: CurrentCategory = _.assign({}, blankCategory, current, none)
export const currentProductClassNone: CurrentProductClass = _.assign({}, blankProductClass, current, none)
export const currentManufacturingSeriesNone: CurrentManufacturingSeries = _.assign({}, blankManufacturingSeries, current, none)
export const currentProductNone: CurrentProduct = _.assign({}, blankProduct, current, none)

export const clearCurrentTopology: CurrentTopology = {
  category: currentCategoryNone,
  productClass: currentProductClassNone,
  manufacturingSeries: currentManufacturingSeriesNone,
  product: currentProductNone,
}

export const clearTopology: Topology = {
  category: _.assign({}, blankCategory, none),
  productClass: _.assign({}, blankProductClass, none),
  manufacturingSeries: _.assign({}, blankManufacturingSeries, none),
  product: _.assign({}, blankProduct, none),
}

export const collapseOpenDefault: BooleanStringState = {}

collapseOpenDefault['edit-edit'] = true
collapseOpenDefault['edit-topology'] = false
collapseOpenDefault['edit-images'] = false
collapseOpenDefault['edit-features'] = false
collapseOpenDefault['edit-specifications'] = false
collapseOpenDefault['edit-productnotes'] = false
collapseOpenDefault['edit-catalogs'] = false

collapseOpenDefault['edit-site-logo'] = false
collapseOpenDefault['edit-site-favicon'] = false

export const entityTypeOptions = ['Non-Accessory', 'Accessory', 'Replacement Part']

export const colorMultiLineOptions: MultiLineOptions = {
  IdColumn: 'ModelColorId',
  TextColumn: 'Color',
  OrderColumn: 'OrderBy',
}

export const descriptionMultiLineOptions: MultiLineOptions = {
  IdColumn: 'ModelDescriptionId',
  TextColumn: 'Description',
  OrderColumn: 'OrderBy',
}

export const noteMultiLineOptions: MultiLineOptions = {
  IdColumn: 'ModelNoteId',
  TextColumn: 'Text',
  OrderColumn: 'OrderBy',
}

export const labelDescriptionMultiLineOptions: MultiLineOptions = {
  IdColumn: 'Ordinal',
  TextColumn: 'Description',
  OrderColumn: 'Ordinal',
}

export const richEditorConfig = {
  toolbar: {
    items: [
      'heading', '|',
      'fontSize', 'fontFamily', 'fontColor', '|',
      'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', '|',
      'bulletedList', 'numberedList', '|',
      'specialCharacters', '|',
      'link', 'alignment',
      // 'indent', 'outdent', '|',
      'blockQuote', 'insertTable', 'undo', 'redo', '|',
      'removeFormat',
    ],
  },
  link: {
    decorators: {
      openInNewTab: {
        mode: 'manual',
        label: 'Open in a new tab',
        attributes: {
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
    },
  },
  language: 'en',
  image: {
    toolbar: [
      'imageTextAlternative',
      'imageStyle:full',
      'imageStyle:side',
    ],
  },
  table: {
    contentToolbar: [
      'tableColumn',
      'tableRow',
      'mergeTableCells',
      'tableCellProperties',
      'tableProperties',
    ],
  },
  licenseKey: '',
}
