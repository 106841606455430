import { MutationTree } from 'vuex'
import State, { CartItem } from './types'
import { v4 as uuidv4 } from 'uuid'
import { blankCartItem } from './constants'

export const mutations: MutationTree<State> = {
  setActiveCartItem(state, { activeCartItem }: { activeCartItem: CartItem }) {
    state.activeCartItem = activeCartItem
  },

  saveCartItem(state, { cartItem }: { cartItem: CartItem }) {
    if (cartItem.id || state.activeCartItem.id) {
      const index = state.activeCart.items.findIndex(
        (x) => x.id === state.activeCartItem.id,
      )
      if (index >= 0) {
        state.activeCart.items.splice(index, 1, {
          ...cartItem,
          id: state.activeCartItem.id,
          totalPrice: cartItem.quantity * cartItem.price,
        })
      }
    } else {
      state.activeCart.items.push({ ...cartItem, id: uuidv4() })
    }
    state.activeCartItem = blankCartItem
  },

  updateCartItem(state, { cartItem }: { cartItem: CartItem }) {
    const index = state.activeCart.items.findIndex(
      (x) => x.id === state.activeCartItem.id,
    )
    if (index >= 0) {
      state.activeCart.items.splice(index, 1, {
        ...cartItem,
        id: state.activeCartItem.id,
        totalPrice: cartItem.quantity * cartItem.price,
      })
    }
  },

  deleteCartItem(state, cartItem: CartItem) {
    state.activeCart.items = state.activeCart.items.filter((x) => {
      return x.id !== cartItem.id
    })
  },

  updateCartItemQty(state, cartItem: CartItem) {
    const item = state.activeCart.items.find((x) => x.id === cartItem.id)
    if (item) {
      item.quantity = cartItem.quantity
      item.totalPrice = cartItem.quantity * item.price
    }
  },

  deleteCartItems(state, cartItems: CartItem[]) {
    state.activeCart.items = state.activeCart.items.filter((item) => {
      return !cartItems.some((cartItem) => cartItem.id === item.id)
    })
  },

  cartItemsCleared(state) {
    state.activeCart.items = []
  },
}
