import { ActionTree } from 'vuex'
import { RootState } from '../types'
import { blankCartItem } from './constants'
import State, { CartItem } from './types'

export const actions: ActionTree<State, RootState> = {
  setActiveCartItem(
    { state, commit },
    { cartItem }: { cartItem: CartItem },
  ): any {
    // If the cart Item exists
    if (state.activeCart.items.find((i) => i.id === cartItem.id)) {
      commit('setActiveCartItem', { activeCartItem: { ...cartItem } })
    }
  },

  saveCartItem({ commit }, { cartItem }: { cartItem: CartItem }): any {
    commit('saveCartItem', { cartItem })
  },

  updateCartItem({ commit }, { cartItem }: { cartItem: CartItem }): any {
    commit('updateCartItem', { cartItem })
    commit('setActiveCartItem', { activeCartItem: blankCartItem })
  },

  deleteCartItem({ commit }, cartItem: CartItem): any {
    commit('deleteCartItem', cartItem)
    commit('setActiveCartItem', { activeCartItem: blankCartItem })
  },

  updateCartItemQty({ commit }, cartItem: CartItem): any {
    commit('updateCartItemQty', cartItem)
  },

  setBlankActiveCartItem({ commit }): any {
    commit('setActiveCartItem', { activeCartItem: blankCartItem })
  },

  deleteCartItems({ commit }, cartItems: CartItem[]) {
    commit('deleteCartItems', cartItems)
  },

  clearCartItems({ commit }) {
    commit('cartItemsCleared')
  },
}
