import { Category, ManufacturingSeries, Model, Product, ProductClass, SectionTable, Series } from './types'
import _ from 'lodash'

export const blankModel: Model = _.assign({
  PcnCatalogNumber: '',
  Visible: true,
  FingoodTpiMfgMod: '',
  TotalList: 0,
  Tranepcn: '',
  Raypcn: '',
  Ecommerce: false,
  International: false,
  Descriptions: [],
  ModelDimensions: [],
  Notes: [],
  ProductModels: [],
  ModelProductClassTypes: [],
  DivisionModels: [],
  Series: '',
  SapModelDescription: '',
  Details: {},
  ModelAssociations: [],
  LabelDescriptions: [],
  ChildAssociations: [],
  ParentAssociations: [],
  CustomBuild: false,
  ReplacementPart: false,
  Accessory: false,
  FactoryInstalled: false,
  FieldInstalled: false,
  Quantity: 0,
})

export const blankSectionTable: SectionTable = { SectionTableId: -1, DisplayName: '', OrderBy: 0, Visible: true, Models: [] }
export const blankCategory: Category = { CategoryId: -1, DisplayName: '', ProductClasses: [] }
export const blankProductClass: ProductClass = { ProductClassId: -1, DisplayName: '', ManufacturingSeries: [] }
export const blankManufacturingSeries: ManufacturingSeries = { ManufacturingSeriesId: -1, DisplayName: '', Products: [] }
export const blankProduct: Product = { ProductId: -1, DisplayName: '', Sections: [] }
export const blankSeries: Series = { SeriesId: -1, DisplayName: '', ManufacturingSeries: [] }
