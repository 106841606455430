<template>
  <div>
    <b-modal
      v-model="modalQuantityVisible"
      centered
      no-close-on-backdrop
      no-close-on-esc
      :size="isMobile ? 'xl' : 'md'"
      hide-footer
      @hide="Cancel"
    >
      <div class="modal-container">
        <h5>
          {{ activeCartItem.fingoodTpiMfgMod }} -
          {{ formatCost(activeCartItem.price) }}
        </h5>
        <div class="text-center">
          <b-img
            v-if="activeCartItem.imgThumbnail"
            class="text-center"
            fluid
            :src="activeCartItem.imgThumbnail + '?width=100&height=100'"
          />
        </div>
        <small>{{ activeCartItem.description }}</small>
        <h4>Quantity</h4>
        <div class="quantity-toggle">
          <button
            @click="decrement()"
            class="button-quantity"
            :disabled="quantity === 1"
          >
            <b-icon icon="dash-circle" size="lg" aria-hidden="true"></b-icon>
          </button>
          <input
            type="text"
            :value="quantity"
            readonly
            class="input-quantity"
          />
          <button @click="increment()" class="button-quantity">
            <b-icon icon="plus-circle" size="lg" aria-hidden="true"></b-icon>
          </button>
        </div>
        <b-button
          @click="saveQuantity(activeCartItem)"
          variant="info background-color-primary rounded-border"
          >ADD TO CART</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { Prop, Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import MxSite from '@/mixins/site'
import { CartItem } from '@/store/cart/types'
import { isMobile, handleResize } from '@/common/helpers/responsive-helper'

const nsCart = namespace('cart')

@Component({
  components: {},
})
export default class extends Mixins(MxSite) {
  @Prop()
  public modalQuantityVisible! : boolean

  @Prop()
  public item!: any

  @Prop()
  public useParentObj!: boolean

  @nsCart.State('activeCartItem')
  public activeCartItem!: CartItem

  @nsCart.Action('setActiveCartItem')
  private setActiveCartItem: any

  @nsCart.Action('setBlankActiveCartItem')
  private setBlankActiveCartItem: any

  @nsCart.Action('saveCartItem')
  private saveCartItem: any

  public quantity = 1

  public isMobile = isMobile()

  public created() {
    handleResize((isMobile) => {
      this.isMobile = isMobile
    })
  }

  public increment() {
    this.quantity++
  }

  public decrement() {
    if (this.quantity === 0) {
      this.toastWarning('Negative quantity not allowed')
    } else {
      this.quantity--
    }
  }

  public saveQuantity(cartItem: CartItem) {
    cartItem.quantity = this.quantity + this.activeCartItem.quantity
    cartItem.totalPrice = cartItem.quantity * cartItem.price
    this.saveCartItem({ cartItem })
      .then(() => {
        this.$emit('closeModal')
        this.setBlankActiveCartItem({})
        this.toastSuccess('Added to Cart', 'b-toaster-bottom-center')
        this.quantity = 1
      })
      .catch(() => {
        // TODO: where is this?
        // this.selectedCartItem = blankCartItem
        this.toastError('Unable to add item to cart')
        this.quantity = 1
      })
  }

  public Cancel() {
    this.$emit('closeModal')
    this.setBlankActiveCartItem({})
  }

  public formatCost(val) {
    if (val == null) {
      return ''
    } else if (isNaN(val)) {
      return val
    } else {
      return `$${parseFloat(val).toFixed(2)}`
    }
  }
}
</script>

<style>
pre {
  background: #eee;
  padding: 1rem;
  border-radius: 5px;
}

.quantity-toggle {
  display: flex;
  justify-content: center;
}

.input-quantity {
  border: 0;
  width: 2.5rem;
  text-align: center;
  padding: 0 0.5rem;
  font-size: 24px;
}

.button-quantity {
  border: none;
  color: #85b3d8;
  background-color: #fff;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 36px;
}

.button-quantity:hover {
  color: #0b67b2;
}

.p-quantity {
  margin: 10px;
}

.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
  text-align: center;
  height: 40vh;
}

.cart-btn {
  border-radius: 100% !important;
  width: 35px;
  height: 35px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1024px) {
  .modal-container {
    height: auto !important;
  }
}
</style>
