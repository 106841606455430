import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import State from './types'
import { RootState } from '../types'

export const state: State = {
  version: 0,
  categories: undefined, // Category[] ?
  Error: false,
  activeCategory: undefined,
  activeProductClass: undefined,
  hidden: false,
}

const namespaced = true

export const menu: Module<State, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
