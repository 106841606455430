import { GetterTree } from 'vuex'
import State from './types'
import { RootState } from '../types'

export const getters: GetterTree<State, RootState> = {
  itemsQuantity({ activeCart }) {
    let countItems = 0
    activeCart.items.forEach(x => {
      countItems = countItems + x.quantity
    })
    activeCart.totalItemsCount = countItems
    return activeCart.totalItemsCount
  },

  cart({ activeCart }) {
    let subTotal: number
    subTotal = 0
    activeCart.items.forEach(x => {
      subTotal = subTotal + x.totalPrice
    })

    activeCart.subTotal = subTotal
    activeCart.taxesCost = subTotal * activeCart.taxPercentage
    activeCart.shippingCost = activeCart.totalItemsCount > 0 ? 80 : 0 // Harcoded meanwhile
    activeCart.total = subTotal + activeCart.shippingCost + activeCart.taxesCost

    return activeCart
  },
}
