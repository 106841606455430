<template>
  <b-navbar
    type="dark"
    class="parts-nav background-color-primary px-0"
    fixed="top">
    <div class="flex-column w-100">
      <b-row class="align-items-center">
        <b-col cols="4" v-if="site.LogoPath && catalogs.length == 1">
          <div class="float-left brand-logo mx-2">
            <b-navbar-brand :href="site.LogoLink">
              <img :src="isMobile ? site.FaviconPath : site.LogoPath" alt="white_logo" class="logo" />
            </b-navbar-brand>
          </div>
        </b-col>
        <b-col cols="4" class="d-flex align-items-center justify-center nav-height" v-else>
          <span v-if="catalogs.length > 1 && !isMobile" class="ml-2">
            <b-dropdown
              variant="default"
              style="background-color: #6ca3d0; border-radius: 5px;"
              right
            >
              <template #button-content>
                <span style="color: #FFF;">Catalog: {{ catalog?.DisplayName }}
                  <template v-if="isPreview && isEditor">[Preview]</template>
                </span>
              </template>
              <b-dropdown-item  v-for="catalog in catalogs.filter(c => c.CatalogId !== catalogId)" :key="catalog.CatalogId" :to="catalogRedirect(catalog.CatalogId, isPreview)">
                <span class="text-wrap">{{ catalog.DisplayName }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </b-col>
        <b-col cols="4" class="d-flex align-items-center justify-content-center">
          <search-input></search-input>
        </b-col>
        <b-col cols="4"  class="d-flex align-items-center justify-content-end">
          <div class="mx-3">
            <CartComponent v-if="catalog.CartEnabled"></CartComponent>
          </div>
          <div class="menu-toggle text-center mx-3">
            <a href="#" style="color: white" @click="menuActive = !menuActive">
              <i class="fa fa-bars bar-icon"></i>
            </a>
          </div>
        </b-col>
        <!-- <b-col cols="3"> </b-col> -->
      </b-row>
      <nav class="navigation">
        <!-- container -->
        <div class="container">
          <!-- responsive-nav -->
          <div :class="['responsive-nav', { active: menuActive }]">
            <!-- NAV -->
            <ul class="main-nav nav navbar-nav">
              <li v-if="catalogs.length == 1" style="height: 57.6px" class="d-flex align-items-center justify-content-center border-bottom mobile-logo">
                <img v-if="site.LogoPath" :src="isMobile ? site.FaviconPath : site.LogoPath" alt="white_logo" class="logo" />
                <b v-else class="text-white">Categories</b>
              </li>
              <li v-if="isMobile && catalogs.length > 1" class="p-2">
                <b-dropdown
                  variant="default"
                  style="background-color: #6ca3d0; border-radius: 5px;"
                  right>
                  <template #button-content>
                    <span style="color: #FFF;" class="text-wrap">Catalog: {{ catalog?.DisplayName }}
                      <template v-if="isPreview && isEditor">[Preview]</template>
                    </span>
                  </template>
                  <b-dropdown-item @click="menuActive = false" v-for="catalog in catalogs.filter(c => c.CatalogId !== catalogId)" :key="catalog.CatalogId" :to="catalogRedirect(catalog.CatalogId, isPreview)">
                    {{ catalog.DisplayName }}
                  </b-dropdown-item>
                </b-dropdown>
              </li>
              <li :class="{ active: !$route.params.category }" @click="menuActive = false">
                <router-link :to="getCatalogTo()" style="cursor: pointer">Home
                </router-link>
              </li>
              <li :key="category.CategoryId" v-for="category in categories" :class="{ active: category.Name === $route.params.category }" @click="menuActive = false">
                <router-link :to="getTopologyTo(catalogId, isPreview, category)" style="cursor: pointer">{{ category.DisplayName }}
                </router-link>
              </li>
            </ul>
            <!-- /NAV -->
          </div>
          <!-- /responsive-nav -->
        </div>
        <!-- /container -->
      </nav>
    </div>

    <!-- /NAVIGATION -->
  </b-navbar>
  <!-- NAVIGATION -->
</template>

<script lang="ts">
import LoginComponent from '@/components/security/login.vue'
import LoadingText from '@/components/utility/loading-text.vue'
import { Category } from '@/store/menu/types'
import { Component, Mixins } from 'vue-property-decorator'
import { State, namespace } from 'vuex-class'
import CartComponent from '../cart/cart-component.vue'
import SearchInput from '../search/search-input.vue'

import MxSite from '@/mixins/site'
import { isMobile, handleResize } from '@/common/helpers/responsive-helper'
import { Catalog } from '@/store/types'
import _ from 'lodash'

const nsMenu = namespace('menu')

@Component({
  components: {
    SearchInput,
    LoadingText,
    CartComponent,
    'c-login': LoginComponent,
  },
})
export default class extends Mixins(MxSite) {
  public isMobile = isMobile()

  @nsMenu.State('version')
  public version!: number

  @nsMenu.State('hidden')
  public hidden!: boolean

  @nsMenu.Mutation('setVersion')
  public setVersion!: any

  @nsMenu.Getter('categories')
  public categories!: Category[]

  @nsMenu.Getter('activeCategory')
  public activeCategory!: Category

  @State('isLoginAllowed')
  public isLoginAllowed!: boolean

  public menuActive = false

  public created() {
    handleResize((isMobile) => {
      this.isMobile = isMobile
    })
  }

  public catalogRedirect(catalogId: number, isPreview: boolean) {
    return {
      name: 'catalog',
      query: { catalogId: catalogId.toString(), isPreview: (isPreview ? 'true' : undefined) },
    }
  }

  public changeCatalog(catalog: Catalog) {
    const { query } = this.$route
    const newQuery = _.assign({}, query)
    newQuery.catalogId = catalog.CatalogId.toString()
    this.$router.replace({ query: newQuery })
  }

  get isCatalogSite() {
    return process.env.VUE_APP_TPI_ROLE === 'site' || (this.$route.query.catalogId ? +this.$route.query.catalogId > 0 : false)
  }
}
</script>

<style scoped>
.brand-logo {
  cursor: pointer;
  min-height: 57.15px;
}

.nav-height {
  min-height: 57.15px;
}

.logo {
  height: 40px;
  width: auto;
}

.parts-nav {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.bar-icon {
  font-size: 1.5rem;
}

.navigation {
  background: #fff;
  border-bottom: 2px solid #cee0ef;
  width: 100vw;
}

.main-nav>li {
  display: flex;
}

.main-nav>li+li {
  margin-left: 30px;
}

.main-nav>li>a {
  padding: 20px 0px;
  color: #2b2d42;
}

.main-nav>li>a:hover,
.main-nav>li>a:focus,
.main-nav>li.active>a {
  color: #0b67b2;
  background-color: transparent;
  text-decoration: none;
}

.main-nav>li>a:after {
  content: '';
  display: block;
  width: 0%;
  height: 2px;
  background-color: #0b67b2;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.main-nav>li>a:hover:after,
.main-nav>li>a:focus:after,
.main-nav>li.active>a:after {
  width: 100%;
}

.header-ctn li.nav-toggle {
  display: none;
}

.menu-toggle {
  display: none;
}

.mobile-logo {
  display: none !important;
}

@media only screen and (max-width: 1024px) {

  .menu-toggle {
    display: block;
  }

  .mobile-logo {
    display: flex !important;
  }

  .responsive-nav {
    position: fixed;
    left: 0;
    top: 0;
    background: #0b67b2;
    height: 100vh;
    max-width: 250px;
    width: 0%;
    overflow: hidden;
    z-index: 22;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.3);
  }

  .responsive-nav.active {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
    width: 100%;
  }

  .main-nav {
    margin: 0px;
    float: none;
  }

  .main-nav>li {
    float: none;
    width: 100%;
  }

  .main-nav>li+li {
    margin-left: 0px;
  }

  .main-nav>li>a {
    padding: 15px;
    color: #fff;
  }

  .main-nav>li.active {
    background-color: #cee0ef;
  }

  .main-nav>li.active>a {
    color: #0b67b2;
    font-weight: bold;
    text-decoration: none;
  }

  .main-nav>li>a:after {
    content: '';
    display: block;
    width: 0%;
    height: 2px;
    background-color: transparent;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
  }
}
</style>
