<template>
    <b-modal
        v-if="shippingInformation && !shippingInformation.isBlank"
        id="order-tracking-modal"
        v-model="show"
        centered
        no-close-on-backdrop
        no-close-on-esc
        size="md"
        hide-footer
        @ok="close"
        @close="close"
        @hide="close">
        <b-container v-if="shippingInformation">
            <b-row>
                <b-col class='col-md-12 col-lg-4'>
                    <div class='order-card p-30'>
                        <h3 v-if="shippingInformation.TrackingUpdatesList?.length > 0">{{ shippingInformation.TrackingUpdatesList[0].Status }}</h3>
                        <p class='title'>Tracking number:</p>
                        <p class="pl-2">{{ trackingNumber }}</p>
                        <p class='title' v-if="shippingInformation.TrackingUpdatesList?.length > 0">Last update:</p>
                        <p class="pl-2" v-if="shippingInformation.TrackingUpdatesList?.length > 0">{{ getFormatedDate(shippingInformation.TrackingUpdatesList[0].Date) }}</p>
                    </div>
                </b-col>
                <b-col class='col-md-12 col-lg-8'>
                    <div class='p-30 pt-0'>
                        <h3>History</h3>
                        <c-tracking-stepper :statusUpdates='sortedTrackingUpdates'></c-tracking-stepper>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script lang='ts'>
import MxSite from '@/mixins/site'
import { ShippingInformation } from '@/store/orders/types'
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator'
import OrderTrackingStepper from '@/components/order-tracking/order-tracking-stepper.vue'
import moment from 'moment'

@Component({
  components: {
    'c-tracking-stepper': OrderTrackingStepper,
  },
})

export default class extends Mixins(MxSite) {
  public show = false

  public sortedTrackingUpdates = []

  @Prop({ default: null })
  public shippingInformation: ShippingInformation | any = undefined

  @Prop({ default: null })
  public trackingNumber: string | undefined = undefined

  @Watch('shippingInformation')
  private setShow() {
    if (!this.shippingInformation || (this.shippingInformation as any).isBlank) return

    this.sortedTrackingUpdates = this.shippingInformation.TrackingUpdatesList.sort((a, b) => a.Date < b.Date ? 1 : -1)
    this.show = true
  }

  @Emit('close')
  public close() {
    // Just emit
  }

  public getFormatedDate(date: string) {
    return moment.utc(date).local()
  }
}
</script>
<style>
    .order-card {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        border-radius: 13px;
        background-color: #f9fbfc;
    }

    .p-30 {
        padding: 30px;
    }

    .pt-0 {
        padding-top: 0;
    }

    .title {
        font-weight: bold;
    }
</style>
