<template>
  <b-modal
    size="xl"
    v-model="show"
    id="payment-model"
    title="Payment"
    centered
    no-close-on-backdrop
    @ok="authorize"
    @close="handleClose"
    @cancel="handleClose"
    ok-title="Confirm"
    :ok-disabled="!authenticated"
    body-class="find-modal-body"
  >
    <loading v-if="loadingDelego" :loadingText="'Loading payment form'" />
    <div class="payment-details-form">
      <div id="iframehost"></div>
    </div>
  </b-modal>
</template>
<script lang="ts">
import { Api } from '@/api/Api'
import MxSite from '@/mixins/site'
import { INewOrder, IOrder } from '@/store/orders/types'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Loading from '../utility/loading.vue'
const nsOrders = namespace('orders')

@Component({
  components: {
    Loading,
  },
})
export default class PaymentDetails extends Mixins(MxSite) {
  public DelegoRapidPay: any
  public rapidPay: any
  public paymentResponse: any
  public threeDSResponse: any
  public authenticated = false

  @nsOrders.Action('addPaymentOrder')
  public addPaymentOrder: any

  @nsOrders.State('isSubmittingOrder')
  public isSubmittingOrder!: boolean

  private api = new Api()
  @Prop({ type: Object, required: true }) order!: IOrder
  @Prop({ type: Boolean, required: true }) show!: boolean
  @Prop() public handleClose!: () => void
  @Prop({ type: Number }) shippingCost!: number
  public loadingDelego = false

  public created() {
    this.loadingDelego = true
    this.paymentResponse = null
    const delegoScript = document.createElement('script')
    const delegoScriptLoaded = new Promise((resolve) => {
      delegoScript.onload = resolve
    })

    delegoScript.setAttribute('src', (process.env as any).VUE_APP_DELEGO_SDK_URL)

    document.head.appendChild(delegoScript)

    delegoScriptLoaded.then(() => {
      // eslint-disable-next-line
      this.DelegoRapidPay = (window as any).DelegoRapidPay
      this.checkout()
    })
  }

  public checkout() {
    const url = '/checkout/token'

    this.api
      .fetch({
        url,
      })
      .then((response) => {
        this.rapidPay = this.openRapidPay(response.Data)
        this.loadingDelego = false
      })
  }

  public decode(jwt) {
    return new Promise((resolve) => {
      const url = '/checkout/decode'
      const obj = jwt
      this.api
        .fetch({
          url,
          obj,
        })
        .then((response) => {
          if (response.successful) {
            resolve(response.data)
          } else {
            this.toastError('Payment Failed to Authorize')
          }
        })
    })
  }

  public get totalAmount() {
    const total = this.order.Items.reduce(
      (total, item) => total + item.quantity * item.price,
      0,
    )
    return {
      currencyCode: 'USD',
      total: total + this.shippingCost,
    }
  }

  public getAddress() {
    const {
      City,
      Country,
      Address1,
      Address2,
      FirstName,
      LastName,
      Zip,
      State,
      Phone,
      Email,
    } = this.order.BillToParty
    return {
      city: City,
      country: Country,
      street: Address1 + ' ' + Address2,
      firstName: FirstName,
      lastName: LastName,
      postalCode: Zip,
      region: State,
      phoneNumber: Phone,
      email: Email,
    }
  }

  public openRapidPay(authorizationToken) {
    return this.DelegoRapidPay.open({
      url: process.env.VUE_APP_DELEGO_API_URL,
      token: authorizationToken,
      billingAddressFieldsRequired: true,
      paymentRequest: this.totalAmount,
      element: '#iframehost',
      iframeclass: 'fit-to-parent',
      events: {
        onApplicationInitialized: () => {
          /*
           * Actions to take once RapidPay is displayed
           */
        },
        onTokenizeSuccess: this.tokenizeSuccess,
        onFailure: (error) => {
          this.toastError(error)
          /*
           * Actions to take if RapidPay fails to tokenize a payment card (typically a system error)
           */
        },
      },
      /*
       * Additional optional parameters, as desired.
       */
    })
  }

  public authorize(e) {
    const { paymentResponse, rapidPay, totalAmount } = this
    e.preventDefault()
    rapidPay
      .authorize({
        amount: {
          value: process.env.VUE_APP_DELEGO_PRE_AUTH ? process.env.VUE_APP_DELEGO_PRE_AUTH_AMOUNT : totalAmount.total,
          currency: totalAmount.currencyCode,
        },
        payment: paymentResponse.payment,
        address: this.getAddress(),
        useVerifiableResponse: true,
        preAuth: process.env.VUE_APP_DELEGO_PRE_AUTH,
      })
      .then((authorization) => this.decode(authorization))
      .then((authorization) => {
        if (authorization.authorized) {
          this.handleClose()
          this.addOrder(this.order, authorization)
        } else {
          this.authenticated = false
          try {
            const h = this.$createElement
            const vNodesMsg = h('div', [
              'Payment Failed: ',
              h('br'),
              h('br'),
              `(${authorization.responseCode}) ${authorization.responseDescription}`,
            ])
            this.toastErrorNode(vNodesMsg)
          } catch (error) {
            this.toastError('Payment Failed to Authorize')
          }
        }
      })
  }

  public tokenizeSuccess(tokenizeResponse) {
    this.paymentResponse = tokenizeResponse
    this.authenticated = true
  }

  async addOrder(order: IOrder, authorization: any) {
    const newOrder: INewOrder = {
      CustomerPurchaseOrderNumber: 'ReplacementParts',
      BillToParty: {
        Name: order.BillToParty.FirstName + ' ' + order.BillToParty.LastName,
        Street: order.BillToParty.Address1,
        Name2: order.BillToParty.Address2,
        City: order.BillToParty.City,
        Region: order.BillToParty.State,
        PostalCode: order.BillToParty.Zip,
        Country: order.BillToParty.Country,
        Phone: order.BillToParty.Phone,
      },
      ShipToParty: {
        Name: order.BillToParty.FirstName + ' ' + order.BillToParty.LastName,
        Street: order.ShipToParty.Address1,
        Name2: order.ShipToParty.Address2,
        City: order.ShipToParty.City,
        Region: order.ShipToParty.State,
        PostalCode: order.ShipToParty.Zip,
        Country: order.ShipToParty.Country,
        Phone: order.ShipToParty.Phone,
      },
      CardInfo: {
        CardType: authorization.payment.cardType,
        CardNumber: authorization.payment.token,
        Name: authorization.payment.cardholderName,
        ValidTo:
          authorization.payment.cardExpiration.year +
          '-' +
          authorization.payment.cardExpiration.month +
          '-01',
        BillAmount: authorization.requestedAmount.value,
        Currency: authorization.requestedAmount.currency,
        Authorized: authorization.authorized,
        AuthAmount: authorization.authorizedAmount.value,
        AuthorizationDate: new Date(authorization.authorizationDate),
        AuthorizationNo: authorization.authorizationNumber,
        ReferenceNo: authorization.referenceNumber,
        PreAuth: authorization.preAuth,
      },
      Items: order.Items,
      TextLines: order.TextLines,
      Email: order.ShipToParty.Email,
      ShippingCost: this.shippingCost,
    }
    await this.addPaymentOrder(newOrder)
  }
}
</script>

<style scoped>
.payment-details-form label {
  font-weight: bold;
}
</style>
