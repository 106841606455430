import { BaseApi } from '@/api/BaseApi'
import { FetchApi } from '@/api/FetchApi'
import { IFetchOptions } from '@/types/IFetchOptions'

export class Api extends BaseApi {
  private provider: any = new FetchApi()

  public async fetch(args: IFetchOptions): Promise<any> {
    return await this.provider.fetch(args)
  }

  public async fetchV2(args: IFetchOptions): Promise<any> {
    return await this.provider.fetchV2(args)
  }

  // TODO: JM: Fix V2
  public async fetchList(args: IFetchOptions): Promise<any> {
    return await this.provider.fetchList(args)
  }
}
