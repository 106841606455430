<template>
  <ul class='paragraph'>
    <template v-for='(feature, index) in features'>
      <li :key='index' v-if="feature.trim() !== ''">
        {{feature}}
      </li>
    </template>
    <li>
      California residents <b-link href="#" id="prop65-tooltip">see Prop 65 Warnings</b-link>
      <b-popover
        target="prop65-tooltip"
        triggers="click"
        placement="bottom"
        custom-class="prop65"
      >
        <template v-slot:title>
          <b-icon icon="exclamation-triangle" variant="warning"></b-icon> Warning
        </template>
        <span class="prop65-text">
          This product can expose you to chemicals including nickel which is known to the state of California to cause cancer, and chromium, which is known to the state of California to cause birth defects and/or reproductive harm. For more information go to <a href="https://www.p65warnings.ca.gov/" target="_window">https://www.p65warnings.ca.gov/</a>
        </span>
      </b-popover>
    </li>
  </ul>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class extends Vue {
  @Prop()
  public features!: string[]
}
</script>
