import _ from 'lodash'
import { loading, blankResponseArray } from '../constants'
import { ArrayLoading, ResponseArray } from '../types'

import {
  Image,
  Section,
  Product,
  Model,
  ModelNone,
  TableLayoutNone,
  TableLayoutColumn,
  SectionTab,
  SectionImage,
  SectionTableNone,
} from './types'
export const blankImage: Image = { ImageId: -1, Path: '', ImagePath: '', Caption: '', loading: false }

export const blankSection: Section = { SectionId: -1, Name: '', DisplayName: '', MobileDisplayName: '', active: true, Tabs: [] }
export const blankSectionTab: SectionTab = { SectionTabId: -1, Name: '', DisplayName: '', MobileDisplayName: '', Items: [], OrderBy: -1, active: true }

// these constants are useful so we don't constantly define them over and over
export const loadingModels: ResponseArray<Model> = _.assign([], blankResponseArray)
// export const errorModels: ResponseArray<Model> = _.assign([], { loading: true, Count: 0, Page: 0, PageSize: 0, Successful: false, Total: 0, TotalPages: 0, List: [] })

export const loadingSection: ArrayLoading<Section> = _.assign([], loading)
export const productNotFound: Product = { ProductId: -1, DisplayName: 'Product Not Found', Name: 'product-not-found', Description: '', CategoryId: -1, ProductClassId: -1, ManufacturingSeriesId: -1, Images: [], Sections: loadingSection, loading: false }
export const productLoading: Product = { ProductId: -1, DisplayName: 'Loading', Name: 'product-loading', Description: '', CategoryId: -1, ProductClassId: -1, ManufacturingSeriesId: -1, Images: [], Sections: loadingSection, loading: true }

export const imageLoading: Image = { ImageId: -1, Path: '', Caption: 'Loading', ImagePath: '', loading: true }

export const modelNone: ModelNone = { PcnCatalogNumber: '', isNone: true }
export const tableLayoutNone: TableLayoutNone = { SectionTableLayoutId: -1, isNone: true }
export const sectionTableNone: SectionTableNone = { DisplayName: '', isNone: true }

export const activeSectionNone: Section = _.assign({}, blankSection, { active: true, isNone: true })
export const activeSectionTabNone: SectionTab = _.assign({}, blankSectionTab, { active: true, isNone: true })
export const sectionTabFeatures: SectionTab = _.assign({}, blankSectionTab, { SectionTabId: -999, active: true, isNone: false, DisplayName: 'Features', Name: 'Features', MobileDisplayName: 'Features' })

export const blankSectionImage: SectionImage = _.assign({ SectionItemType: 'Image', SectionImageId: -1, DisplayName: '', OrderBy: -1, Visible: true, Caption: '', Cols: 12, Width: 0, Height: 0, OriginalWidth: 0, OriginalHeight: 0, key: 'sectionImage--1', ExplodedView: false, AltText: '', IsThumbnail: false }, blankImage)

export const viewDetailsTableLayoutColumn: TableLayoutColumn = {
  SectionTableLayoutColumnId: -1,
  EntityName: 'viewDetails',
  EntityPropertyName: 'viewDetails',
  ParentPropertyName: 'viewDetails',
  PropertyName: 'viewDetails',
  DisplayName: '',
  DisplayDescription: '',
  Name: 'ViewDetails',
  SortBy: false,
  FilterBy: false,
  KeywordSearchBy: false,
  ShowOnMobile: false,
  PropertyType: 'ViewDetails',
  ParentPropertyType: '',
  OverrideProperty: null,
  facets: [],
  sort: 0,
  keyword: '',
  filterVisible: false,
  key: 'viewDetails',
  filteredModels: [],
  isCustom: true,
  OrderBy: 0,
}

export const viewChangeRequestTableLayoutColumn: TableLayoutColumn = {
  SectionTableLayoutColumnId: -1,
  EntityName: 'changeRequest',
  EntityPropertyName: 'changeRequest',
  ParentPropertyName: 'changeRequest',
  PropertyName: 'changeRequest',
  DisplayName: '',
  DisplayDescription: '',
  Name: 'ChangeRequest',
  SortBy: false,
  FilterBy: false,
  KeywordSearchBy: false,
  ShowOnMobile: false,
  PropertyType: 'ChangeRequest',
  ParentPropertyType: '',
  OverrideProperty: null,
  facets: [],
  sort: 0,
  keyword: '',
  filterVisible: false,
  key: 'changeRequest',
  filteredModels: [],
  isCustom: true,
  OrderBy: 0,
}

export const addItemToCart: TableLayoutColumn = { // This is only for testing
  SectionTableLayoutColumnId: -1,
  EntityName: 'viewDetails',
  EntityPropertyName: 'viewDetails',
  ParentPropertyName: 'viewDetails',
  PropertyName: 'viewDetails',
  DisplayName: '',
  DisplayDescription: '',
  Name: 'ViewDetails',
  SortBy: false,
  FilterBy: false,
  KeywordSearchBy: false,
  ShowOnMobile: true,
  PropertyType: 'ViewDetails',
  ParentPropertyType: '',
  OverrideProperty: null,
  facets: [],
  sort: 0,
  keyword: '',
  filterVisible: false,
  key: 'addItemToCart',
  filteredModels: [],
  isCustom: true,
  OrderBy: 0,
}

export const linkToParts: TableLayoutColumn = { // This is only for testing
  SectionTableLayoutColumnId: -1,
  EntityName: 'linkToParts',
  EntityPropertyName: 'linkToParts',
  ParentPropertyName: 'linkToParts',
  PropertyName: 'linkToParts',
  DisplayName: '',
  DisplayDescription: '',
  Name: 'linkToParts',
  SortBy: false,
  FilterBy: false,
  KeywordSearchBy: false,
  ShowOnMobile: true,
  PropertyType: 'linkToParts',
  ParentPropertyType: '',
  OverrideProperty: null,
  facets: [],
  sort: 0,
  keyword: '',
  filterVisible: false,
  key: 'linkToParts',
  filteredModels: [],
  isCustom: true,
  OrderBy: 0,
}

export const showInventory: TableLayoutColumn = {
  SectionTableLayoutColumnId: 4,
  EntityName: 'available',
  EntityPropertyName: 'available',
  ParentPropertyName: 'available',
  PropertyName: 'available',
  DisplayName: 'Inventory',
  DisplayDescription: 'Inventory',
  Name: 'Inventory',
  SortBy: true,
  FilterBy: false,
  KeywordSearchBy: false,
  ShowOnMobile: false,
  PropertyType: 'Inventory',
  ParentPropertyType: '',
  OverrideProperty: null,
  facets: [],
  sort: 0,
  keyword: '',
  filterVisible: false,
  key: 'showInventory',
  filteredModels: [],
  isCustom: true,
  OrderBy: 5,
}

export const optionsTableLayoutColumn: TableLayoutColumn = {
  SectionTableLayoutColumnId: -1,
  EntityName: 'options',
  EntityPropertyName: 'options',
  ParentPropertyName: 'options',
  PropertyName: 'options',
  DisplayName: '',
  DisplayDescription: '',
  Name: 'Options',
  SortBy: false,
  FilterBy: false,
  KeywordSearchBy: false,
  ShowOnMobile: false,
  PropertyType: 'Options',
  ParentPropertyType: '',
  OverrideProperty: null,
  facets: [],
  sort: 0,
  keyword: '',
  filterVisible: false,
  key: 'options',
  filteredModels: [],
  isCustom: true,
  OrderBy: 0,
}
