<template>
  <div class="item-table-container">
    <b-table
      :items="items"
      :fields="fields"
      responsive
      hover
      borderless
      sticky-header
      class="item-table"
    >
      <template v-slot:cell(details)="row">
        <b-icon
          :icon="row.detailsShowing ? 'chevron-up' : 'chevron-down'"
          @click="row.toggleDetails"
        >
        </b-icon>
      </template>
      <template v-slot:head(details)>
        <div></div>
      </template>
      <template v-slot:head(checkbox)>
        <input type="checkbox" :checked="selectAll" @change="handleSelectAll" />
      </template>
      <template v-slot:cell(checkbox)="{ item }">
        <input
          type="checkbox"
          :checked="selectedRows.includes(item)"
          @change="handleCheckboxChange(item)"
        />
      </template>
      <template v-slot:cell(imgThumbnail)="{ item }">
        <div class="text-center">
          <b-img
            v-if="item.imgThumbnail"
            class="text-center"
            fluid
            :src="item.imgThumbnail + '?width=50&height=50'"
          />
        </div>
      </template>
      <template v-slot:cell(browseModel)="{ item }" v-if="!isInputDisabled">
        <div class="d-flex">
          <button
            class="btn-flat"
            @click="handleBrowse(item)"
            v-b-tooltip.hover
            title="Look Up"
          >
            <b-icon icon="search"></b-icon>
          </button>
        </div>
      </template>
      <template
        v-slot:cell(pcnCatalogNumber)="{ item }"
        v-if="!isInputDisabled"
      >
        <pcn-search
          :catalogNumberProp="item.pcnCatalogNumber"
          @onSelect="(model) => onSelect(model, item)"
        />
      </template>
      <template v-slot:cell(quantity)="{ item }">
        <input
          type="number"
          :value="item.quantity"
          class="form-control text-center quantity-input"
          @change="handleQtyChange($event, item)"
          min="1"
          :disabled="disableInput"
        />
      </template>
      <template v-slot:cell(fingoodTpiMfgMod)="{ item }">
        <div class="d-flex justify-center align-items-center">
          <div>{{ item.fingoodTpiMfgMod }}</div>
          <div v-if="item.modelDetails && Object.keys(item.modelDetails).length > 0">
            <button
              v-b-modal="item.pcnCatalogNumber + item.id"
              class="btn-flat"
              v-b-tooltip.hover
              title="View Model Details"
            >
              <b-icon icon="info-circle"></b-icon>
            </button>

            <b-modal
              :id="item.pcnCatalogNumber + item.id"
              title="Model Details"
              ok-only
              ok-title="Close"
            >
              <item-details :item="item" />
            </b-modal>
          </div>
        </div>
      </template>
      <template v-slot:cell(inventory)="{ item }" v-if="!isInputDisabled">
        <span class="mb-0 d-flex" style="gap: 5px">
          <modal-inventory :item="item"></modal-inventory>
        </span>
      </template>
      <template v-slot:cell(actions)="{ item }">
        <div class="actions">
          <button
            v-if="lineActions.includes(LineActionEnum.Delete)"
            class="btn-flat btn-red"
            @click="handleDelete(item)"
          >
            <b-icon icon="trash" />
          </button>
          <button
            v-if="lineActions.includes(LineActionEnum.Duplicate)"
            class="btn-flat"
            @click="handleDuplicate(item)"
          >
            <b-icon icon="files" />
          </button>
        </div>
      </template>
      <template #row-details="row">
        <b-card class="small">
          <item-details :item="row.item" />
          <b-button
            size="sm"
            class="mt-2 w-100"
            @click="row.toggleDetails"
            variant="outline"
            ><b-icon icon="chevron-double-up" variant="link"
          /></b-button>
        </b-card>
      </template>
    </b-table>
  </div>
</template>

<script lang="ts">
import { CartItem } from '@/store/cart/types'
import { Component, Prop } from 'vue-property-decorator'
import { BIcon, BImg } from 'bootstrap-vue'
import { ResponseArray } from '@/store/types'
import Multiselect from 'vue-multiselect'
import { debounce } from 'typescript-debounce-decorator'
import MxSite from '@/mixins/site'
import { LineAction } from '@/store/cart/constants'
import PcnSearch from '../search/pcn-search.vue'
import { Model } from '@/store/editor-model/types'
import { namespace } from 'vuex-class'
import ModalInventory from '../inventory/modal-inventory.vue'
import ItemDetails from './item-details.vue'

const nsMultiplier = namespace('multiplier')

@Component({
  components: {
    BIcon,
    BImg,
    Multiselect,
    PcnSearch,
    ModalInventory,
    ItemDetails,
  },
})
export default class ItemTable extends MxSite {
  @Prop()
  public fields!: []

  @Prop()
  public items!: CartItem[]

  @Prop()
  public selectedRows!: CartItem[]

  @Prop()
  public selectedItems!: CartItem[]

  @Prop()
  public selectAll!: boolean

  @Prop()
  public disableInput!: boolean

  @Prop()
  public searchResult!: ResponseArray<CartItem>

  @Prop()
  public handleDelete!: (item: CartItem) => void

  @Prop()
  public handleDuplicate!: (item: CartItem) => void

  @Prop()
  public handleCheckboxChange!: (item: CartItem) => void

  @Prop()
  public handleSelectAll!: () => void

  @Prop()
  public handleQtyChange!: (event: any, item: CartItem) => void

  @Prop()
  public handleSearch!: (event: any) => void

  @Prop()
  public handleBrowse!: (item: CartItem) => void

  @Prop()
  public handleSelect!: (item: CartItem) => void

  @nsMultiplier.Getter('getMultiplierPrice')
  private getMultiplierPrice: any

  @Prop({ default: () => [LineAction.Delete, LineAction.Duplicate] })
  public lineActions!: LineAction[]

  public LineActionEnum = LineAction

  public formatPrice(cost: number): string {
    return `$${cost.toFixed(2)}`
  }

  public selectedModelItem: any = null

  @debounce(1000)
  public async asyncFind(query: string) {
    this.handleSearch(query)
  }

  public onSelect(model: Model, currentItem: CartItem) {
    const price = this.getMultiplierPrice(
      model.PcnCatalogNumber,
      model.ProductHierarchy,
      model.TotalList,
    )
    const item: CartItem = {
      ...currentItem,
      pcnCatalogNumber: model.PcnCatalogNumber || '',
      SapModelDescription: model.SapModelDescription || '',
      fingoodTpiMfgMod: model.FingoodTpiMfgMod || '',
      description:
        model?.Descriptions[0]?.toString() || model.SapModelDescription || '',
      quantity: model.PcnCatalogNumber ? 1 : 0,
      price: price || 0,
      totalPrice: price || 0,
      imgThumbnail: model.Image?.ImagePath || '',
      shippingStrategyId: model.ShippingStrategyId || 0,
      originalPrice: model.TotalList || 0,
      productHierarchy: model.ProductHierarchy || '',
      inventory: model.Available || '',
      modelDetails: model.Details,
    }
    this.handleSelect(item)
  }

  public customLabel({
    pcnCatalogNumber,
    fingoodTpiMfgMod,
  }: {
    pcnCatalogNumber: string
    fingoodTpiMfgMod: string
  }) {
    return `${pcnCatalogNumber} - ${fingoodTpiMfgMod}`
  }

  public get isInputDisabled() {
    return (
      this.disableInput ||
      (!this._isCustomerSite(this.site.Name) &&
        !this._isCustomerAdminSite(this.role || '') &&
        !this._isSalesRepSite(this.site.Name))
    )
  }
}
</script>

<style scoped>
.item-icon {
  font-size: 2rem;
}
.item-img {
  width: 60px;
}

.quantity-input {
  max-width: 80px;
  float: right;
}

.image-soon {
  height: 50px;
  width: 50px;
}

.actions {
  display: flex;
}

.pcn-model-search {
  display: flex;
}
</style>
