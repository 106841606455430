<template>
  <div id="search">
    <b-container fluid>
      <h3>Search Results</h3>
      <b-row v-if="loading || !results || results.loading">
        <loading-text></loading-text>
      </b-row>
      <b-row v-else>
        <b-col>
          <div class="text-center" v-if="!hasResults">No results found.</div>
          <b-list-group class="result-list">
            <search-result v-for="(result, i) in results.List" :key="i"
              :result="result"
              @clearResults="clearResults"
            />
          </b-list-group>
          <div class="mt-3" v-if="hasResults">
            <b-pagination
              v-model="page"
              align="center"
              :total-rows="resultsCount"
              @input="setCurrentPage(page, currentCatalogId)"
            ></b-pagination>
          </div>
          <div class="mt-3 text-center" v-if="hasResults">
            <p>Showing records {{start}} to {{(start + Number(pageSize)) > resultsCount ? resultsCount : page * Number(pageSize)}} of {{resultsCount}} results</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { SearchResult } from '@/store/search/types'
import LoadingText from '@/components/utility/loading-text.vue'
import SearchResultComponent from '@/components/search/search-result.vue'
import { ResponseArray } from '@/store/types'
import { blankResponseArray } from '@/store/constants'

import MxSite from '@/mixins/site'
const nsSearch = namespace('search')

@Component({
  components: {
    'loading-text': LoadingText,
    'search-result': SearchResultComponent,
  },
})
export default class SearchComp extends Mixins(MxSite) {
  public pageSize = 10
  public page = 0
  public currentCatalogId = ''
  public loading = false
  public results: ResponseArray<SearchResult> = blankResponseArray

  @nsSearch.Action('performSearch')
  public performSearch: any

  @nsSearch.Action('updateCurrentPage')
  public updateCurrentPage: any

  @Watch('$route', { immediate: true, deep: true })
  public onRouteChanged() {
    this.runSearch()
  }

  get hasResults() { return this.results && this.results.Count > 0 }
  get resultsCount() { return this.hasResults ? this.results.Total : 0 }
  get start() { return ((this.page - 1) * this.pageSize) + 1 }

  // public mounted() {
  //   const { params } = this.$router.currentRoute
  //   if (!params.page) {
  //     this.setCurrentPage(1)
  //   } else {
  //     this.updateCurrentPage(parseInt(params.page, 10))
  //   }
  // }

  public runSearch() {
    this.loading = true
    const { params } = this.$router.currentRoute

    const { catalogId } = this
    this.currentCatalogId = catalogId + ''

    if (!params.page) {
      this.setCurrentPage(1, this.currentCatalogId, true)
      return
    }

    const intPage = parseInt(params.page, 10)
    if (this.page !== intPage) {
      this.page = intPage
    }
    const keyword = params.keyword.replace('+', ' ')
    const { page, pageSize } = this
    this.performSearch({ catalogId, keyword, page, pageSize }).then(results => {
      this.results = results
      this.loading = false
    })
  }

  public setCurrentPage(currentPage: number, catalogId: string, noHistory = false) {
    const page = currentPage + ''
    const { isPreview } = this
    const strIsPreview = isPreview ? 'true' : undefined

    if (noHistory) {
      this.$router.replace({ params: { page }, query: { catalogId, strIsPreview } })
    } else {
      this.$router.push({ params: { page }, query: { catalogId, strIsPreview } })
    }
  }

  public clearResults() {
    Vue.set(this, 'results', [])
  }
}
</script>
<style scoped>
.click {
  cursor: pointer;
}

.result-badge {
  height: fit-content;
}
</style>
