import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class extends Vue {
  public toastError(text: string) {
    this.makeToast('Error', text, 'danger', false)
  }

  public toastErrorNode(vNode: any) {
    this.makeToastNode('Error', vNode, 'danger', false)
  }

  public toastWarning(text: string) {
    this.makeToast('Warning', text, 'warning')
  }

  public toastSuccess(text: string, location = 'b-toaster-top-center') {
    this.makeToast('Success', text, 'success', true, location)
  }

  public toastInfo(text: string) {
    this.makeToast('Information', text, 'info')
  }

  public makeToastNode(title: string, vNode: any, variant = 'info', autoHide = true) {
    // TODO: this isn't any, it's this and Vue but why no toast?
    (this as any).$bvToast.toast([vNode], {
      title,
      variant,
      solid: true,
      toaster: 'b-toaster-top-center',
      noAutoHide: !autoHide,
    })
  }

  public makeToast(title: string, text: string, variant = 'info', autoHide = true, location = 'b-toaster-top-center') {
    // TODO: this isn't any, it's this and Vue but why no toast?
    (this as any).$bvToast.toast(text, {
      title,
      variant,
      solid: true,
      toaster: location,
      noAutoHide: !autoHide,
    })
  }

  public toastConfirm(text: string) {
    (this as any).$bvToast.toast(text, {
      type: 'error',
      title: 'This is title',
      content: 'This is the message area!',
      confirmText: 'ok',
      cancelText: 'no',
      confirm: () => {
        // this.$close()
      },
      cancel: () => {
        // this.$close()
      },
    })
  }
}
