<template>
  <div>
    <b-navbar
      type="dark"
      class="sale-rep-nav background-color-primary py-0"
      fixed="top">
      <b-navbar-brand :href="site.LogoLink" v-if="site.LogoPath">
        <img :src="site.LogoPath" alt="white_logo" class="logo" />
      </b-navbar-brand>
      <b-navbar-nav v-if="!isMobile">
        <b-nav-item
          v-for="(item, index) in navItems.filter((x) => x.show)"
          :key="index"
          :to="item.to"
          :active="item.active"
          :class="item.class">
          {{ item.text }}
        </b-nav-item>
        <b-nav-item-dropdown text="Catalogs" class="nav-items-default" right>
          <b-dropdown-item v-for="catalog in catalogs" :key="catalog.CatalogId" :to="{ name: 'catalog', query: { catalogId: catalog.CatalogId } }" target="_blank" class="nav-items-default">
            {{ catalog.DisplayName }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <div class="flex-grow-1" v-if="isMobile" />
      <b-navbar-nav class="ml-auto pr-3" v-if="currentUser.HasAccess">
        <b-navbar-nav right>
          <search-input></search-input>
        </b-navbar-nav>
      </b-navbar-nav>
      <b-button
        v-b-toggle.sidebar-1
        variant="outline-light"
        v-if="isMobile && currentUser.HasAccess">
        <b-icon icon="list"></b-icon>
      </b-button>
      <b-sidebar
        id="sidebar-1"
        shadow
        body-class="background-color-primary"
        header-class="background-color-primary border-bottom"
        footer-class="background-color-primary border-top"
        :backdrop="true">
        <template #header="{ hide }">
          <b-navbar-nav class="align-items-center w-100">
            <img :src="site.LogoPath" alt="white_logo" class="logo" />
            <b-nav-item
              class="h6 mb-0 font-weight-bold"
              :to="{ name: 'home' }">{{ site.BrowserTitle }}</b-nav-item>
            <div class="flex-grow-1"></div>
            <b-button variant="outline-light" @click="hide"><b-icon icon="chevron-left"></b-icon></b-button>
          </b-navbar-nav>
        </template>
        <b-nav vertical variant="dark">
          <b-nav-item v-for="(item, index) in navItems.filter((x) => x.show)" :key="index" :to="item.to" :active="item.active" :class="item.class">
            {{ item.text }}
          </b-nav-item>
        </b-nav>
      </b-sidebar>
    </b-navbar>
    <div v-if="
      $route.matched.some(({ name }) =>
        profileRoutes.find((item) => item.path === name),
      )
    " class="tabs-selector">
      <b-tabs class="mb-3" nav-class="filter-tabs" fill :end="true">
        <b-tab v-for="(item, index) in profileRoutes" :key="index" :title="item.displayName" @click="$router.push({ name: item.path })" :active="$route.matched.some(({ name }) => name === item.path)">
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import MxSite from '@/mixins/site'
import { isMobile, handleResize } from '@/common/helpers/responsive-helper'
import { NavItem } from '@/helpers/nav.helper'
import SearchInput from '../search/search-input.vue'

@Component({
  components: {
    SearchInput,
  },
})
export default class extends Mixins(MxSite) {
  public isMobile = isMobile()

  public profileRoutes = [
    { path: 'sales-rep-list', displayName: 'Sales Representatives' },
    { path: 'customers', displayName: 'Customers' },
    { path: 'user-list', displayName: 'Users' },
  ]

  public get userInitials(): string {
    const names = [this.currentUser.Name, this.currentUser.LastName ?? '']
    return names
      .map((n) => n.charAt(0))
      .join('')
      .toUpperCase()
  }

  public created() {
    handleResize((isMobile) => {
      this.isMobile = isMobile
    })
  }

  public get isCurrentRoute(): (routeName: string) => boolean {
    return (routeName: string) => this.$route.name === routeName
  }

  public get navItems(): NavItem[] {
    return [
      {
        text: 'Change Requests',
        to: { name: 'home' },
        active: this.isCurrentRoute('home'),
        class: 'nav-items-default',
        show: this.currentUser.HasAccess,
      },
    ]
  }
}
</script>

<style scoped>
.white-link {
  color: white;
}

.logo {
  height: 40px;
  width: auto;
}

.nav-link.active {
  font-weight: bold;
  color: #fff;
}

@media (max-width: 767px) {
  .nav-link.active {
    background-color: #08528e;
  }
}

.nav-link.active::after {
  background-color: #fff;
}

.nav-link {
  color: whitesmoke !important;
}

.tabs-selector {
  padding-left: 15px;
  padding-right: 15px;
}
</style>
