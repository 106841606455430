import { GetterTree } from 'vuex'

import State, { Catalog } from './types'
import { blankCatalog } from './constants'

export const getters: GetterTree<State, State> = {
  sectionCatalogs({ catalogs }: State) {
    // note: double-negative makes us stop with the first invalid item!
    return catalogs.map(c => ({ CatalogId: c.CatalogId, DisplayName: null }))
  },
  currentCatalog({ activeRoute, site, catalogs }: State) {
    // note: double-negative makes us stop with the first invalid item!
    let catalog: Catalog = blankCatalog
    if (catalogs.length === 1) {
      return catalogs[0]
    }
    if (!activeRoute?.query.catalogId) {
      catalog = catalogs.find(c => c.CatalogId === site.CatalogId) ?? blankCatalog
    } else {
      catalog = catalogs.find(c => c.CatalogId === +activeRoute?.query.catalogId) ?? blankCatalog
    }
    return catalog
  },
}
