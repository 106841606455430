<template>
  <b-card no-body>
    <b-card-header class="py-1" header-tag="h3">
      <slot name="productHeader" v-bind="product"></slot>
      {{product.DisplayName}}
    </b-card-header>
    <b-card-body v-if="product.Sections.length > 0" class="p-1 w-auto">
      <model-section :section="section" :fields="fields" v-for="section in product.Sections" :key="section.SectionId">
        <template #sectionTabHeader="sectionTab"><slot name="sectionTabHeader" v-bind="sectionTab"></slot></template>
        <template #sectionTabContentHeader="sectionTab"><slot name="sectionTabContentHeader" v-bind="sectionTab"></slot></template>
        <template #sectionTableHeader="sectionTable"><slot name="sectionTableHeader" v-bind="sectionTable"></slot></template>
        <template #rowHeader="model"><slot name="rowHeader" v-bind="model"></slot></template>
      </model-section>
    </b-card-body>
  </b-card>

</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'

import LoadingText from '@/components/utility/loading-text.vue'

import MxSite from '@/mixins/site'

import ModelSection from './section.vue'
import { Product } from '@/store/model-viewer/types'

@Component({
  components: {
    'loading-text': LoadingText,
    'model-section': ModelSection,
  },
})
export default class extends MxSite {
  @Prop()
  public product!: Product

  @Prop({ default: () => [] })
  public fields!: any[]
}
</script>
