import { RouteConfig } from 'vue-router'
import MainProductSearch from './main-views/ProductSearch.vue'

import Search from './views/Search.vue'
import Cart from './views/ShoppingCart.vue'
import Cache from './views/Cache.vue'
import Checkout from './views/Checkout.vue'
import OrderSummary from './views/OrderSummary.vue'
import { getRoutes as getProductSearchRoutes, getWaits as getProductSearchWaits } from './routes/product-search'

import { Store } from 'vuex'
import State from './store/types'

export function getRoutes(): RouteConfig[] {
  const productSearchRoutes = getProductSearchRoutes()
  return [
    {
      // TODO: move to routes-admin!
      path: '/admin/cache',
      name: 'admin-cache',
      component: Cache,
      meta: { title: 'Admin - Cache' },
    },
    {
      path: '/search/:keyword?/:page?',
      name: 'search',
      component: Search,
      props: true,
    },
    {
      path: '/cart',
      name: 'cart',
      component: Cart,
      meta: { title: 'Cart' },
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: Checkout,
      meta: { title: 'Checkout' },
    },
    {
      path: '/order_summary/:invoiceNo',
      name: 'order_summary',
      component: OrderSummary,
      meta: { title: 'Order Summary' },
      props: true,
    },
    // note to add all static routes before the /:category else refresh wont work
    {
      path: '/',
      name: 'home',
      component: MainProductSearch,
      redirect: { name: 'catalog' },
      children: [
        ...productSearchRoutes,
      ],
    },
  ]
}

export function getWaits(store: Store<State>) {
  const productSearchWaits = getProductSearchWaits(store)
  return [
    ...productSearchWaits,
  ]
}

// export function getWaits(store: Store<State>) {
//   store.dispatch('common/setOverlayShowing', true, { root: true })
//   return [
//     async (to: any, from: any, next: any) => {
//       const route = to
//       await store.dispatch('setActiveRoute', { route })
//       next()
//     },
//     async (to: any, from: any, next: any) => {
//       if (!store.state.preloaded) {
//         store.dispatch('common/setOverlayShowing', true, { root: true })
//         // TODO: possibly bootstrap
//         // TODO: a
//         await store.dispatch('getDivisions')
//         await store.dispatch('getSites')
//         await store.dispatch('getSite')

//         store.commit('setPreloaded', { value: true })
//         const favicon = document.getElementById('favicon') as any
//         if (favicon !== null) {
//           favicon.href = '//' + location.host + store.state.site.FaviconPath
//         }
//         store.dispatch('common/setOverlayShowing', false, { root: true })
//       }
//       next()
//     },
//   ]
// }
