import { RouteConfig } from 'vue-router'

import Diagnostics from './views/Diagnostics.vue'

import { Store } from 'vuex'
import State from './store/types'

export function getRoutes(): RouteConfig[] {
  return [
    {
      path: '/diagnostics',
      component: Diagnostics,
    },
  ]
}

export function getWaits(store: Store<State>, loadMenu = false, preLoad = false) {
  store.dispatch('common/setOverlayShowing', true, { root: true })
  return [
    async (to: any, from: any, next: any) => {
      const route = to
      await store.dispatch('setActiveRoute', { route })
      await store.dispatch('common/resetBreadcrumbDisplay', undefined, { root: true })
      next()
    },
    async (to: any, from: any, next: any) => {
      await store.dispatch('common/resetBreadcrumbDisplay', undefined, { root: true })
      if (!store.state.preloaded) {
        store.dispatch('common/setOverlayShowing', true, { root: true })
        if (preLoad) {
          await store.dispatch('entity/preload', { withEditors: false }, { root: true }) // TODO: move this to commmon or site?
          await store.dispatch('getDivisions')
          await store.dispatch('getSites')
        }
        await store.dispatch('getCatalogs')
        await store.dispatch('getSite')

        const catalogId = store.state.site.CatalogId

        if (loadMenu) await store.dispatch('menu/getMenu', { catalogId })

        store.commit('setPreloaded', { value: true })
        const favicon = document.getElementById('favicon') as any
        if (favicon !== null) {
          favicon.href = '//' + location.host + store.state.site.FaviconPath
        }
        store.dispatch('common/setOverlayShowing', false, { root: true })
      }
      next()
    },
  ]
}
