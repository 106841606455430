import { Cart, CartItem, ShippingStrategy } from './types'

export const blankCartItem: CartItem = {
  pcnCatalogNumber: '',
  fingoodTpiMfgMod: '',
  description: '',
  quantity: 0,
  price: 0,
  totalPrice: 0,
  imgThumbnail: '',
  shippingStrategyId: '',
  id: '',
  productHierarchy: '',
  originalPrice: 0,
}

export const blankCart: Cart = {
  dateCreated: new Date(),
  dateModified: new Date(),
  subTotal: 0,
  taxesCost: 0,
  shippingCost: 0, // TODO: Obtain from the database
  total: 0,
  taxPercentage: 0.17, // TODO: Obtain from the database
  items: [],
  totalItemsCount: 0,
}

export const blankShippingStrategy: ShippingStrategy = {
  ShippingStrategyId: '',
  DisplayName: '',
  Description: '',
  Costs: [],
}

export enum LineAction {
  Delete = 0,
  Duplicate = 1,
}
