import Vue from 'vue'
import Vuex, { ModuleTree, StoreOptions } from 'vuex'
import State from './types'

import { getters } from './getters'
import { actions } from './actions'
import { blankDivision, blankSite, loadingDivision, loadingSite, loadingCatalog } from './constants'
import { loadingSchedule } from './editor-schedules/constants'
import { mutations } from './mutations'

export async function createStore({ modules, role, plugins, isLoginAllowed, apiPrefix }: { modules: ModuleTree<State>, persistModules: string[], role: string, plugins: any[], isLoginAllowed: boolean, apiPrefix: string }) {
  const state: State = {
    error: false,
    activeRoute: null,
    role,
    divisions: loadingDivision,
    catalogs: loadingCatalog,
    sites: loadingSite,
    division: blankDivision,
    site: blankSite,
    preloaded: false,
    schedules: loadingSchedule,
    isLoginAllowed,
    apiPrefix,
  }

  Vue.use(Vuex)
  const store: StoreOptions<State> = {
    getters,
    actions,
    mutations,
    state,
    modules,
    plugins,
  }

  return new Vuex.Store<State>(store)
}
