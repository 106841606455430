<template>
  <div>
    <b-modal
      v-model="show"
      centered
      no-close-on-backdrop
      no-close-on-esc
      size="sm"
      hide-footer
      @hide="Close"
      scrollable
    >
      <div class="modal-container">
        <h5>Inventory availability</h5>
        <div class="text-center">
          <template>
            <div>
              <b-table
                striped
                hover
                :items="supplierLocations"
                :fields="fields"
              ></b-table>
            </div>
          </template>
        </div>
      </div>
    </b-modal>
    <template v-if="isFetching"> Loading... </template>
    <template v-else>
      <div>
        <span>{{ item.Available || item.inventory }}</span>
        <button
          v-if="
            !isNaN(item.Available || item.inventory) &&
            (_isSalesRepSite(site.Name) ||
              _isCustomerAdminSite() ||
              _isCustomerSite(site.Name))
          "
          class="btn-flat p-1"
          @click.stop="fetchInventory()"
        >
          <b-icon icon="info-circle"></b-icon>
        </button>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Prop, Component, Mixins } from 'vue-property-decorator'
import MxSite from '@/mixins/site'
import { Api } from '@/api/Api'
import { HttpMethod } from '@/api/FetchApi'
import { isCustomerAdminSite } from '@/helpers/sites.helper'

@Component({
  components: {},
})
export default class extends Mixins(MxSite) {
  public modalInventoryVisible = false
  public isFetching = false
  public supplierLocations: any[] = []
  public show = false

  public fields = ['LocationName', 'QuantityAvailable']
  private api = new Api()

  @Prop()
  public item!: any

  public Close() {
    this.show = false
  }

  public fetchInventory() {
    this.isFetching = true
    this.modalInventoryVisible = true

    const url = `/Inventory/catalog/${this.item.PcnCatalogNumber || this.item.pcnCatalogNumber}`
    this.api
      .fetchV2({
        url,
        method: HttpMethod.Get,
        user: this.currentUser,
      })
      .then((result) => {
        this.show = true
        this.supplierLocations = result.SupplierLocations
      })
      .finally(() => {
        this.isFetching = false
      })
  }

  public _isCustomerAdminSite() {
    return isCustomerAdminSite(this.role)
  }
}
</script>

<style>
pre {
  background: #eee;
  padding: 1rem;
  border-radius: 5px;
}

.no-border {
  border: 0 !important;
}

.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
  text-align: center;
  height: 40vh;
}
</style>
