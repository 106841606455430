import { createRouter } from './router'
import { getRoutes } from './routes-site'
import { getRoutes as getCommonRoutes, getWaits as getCommonWaits } from './routes-common'
import { createStore } from './store'

import { createApp, VuePluginListItem } from './app'

import { getModules, getPersistModules } from './store/index-site'

// Transpiler for older browsers
import 'core-js/stable'
import 'whatwg-fetch'

const role = 'site'

const routes = [
  ...getCommonRoutes(),
  ...getRoutes(),
]

const modules = getModules()

const plugins: VuePluginListItem[] = []

const persistModules = getPersistModules()

createStore({ modules, persistModules, role, plugins: [], isLoginAllowed: false, apiPrefix: '' }).then(store => {
  const waits = getCommonWaits(store, true)

  createRouter({ routes, waits }).then(router => {
    createApp({ router, store, plugins })
  })
})
