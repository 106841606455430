<template>
  <div id="editor">
    <b-container fluid>
      <template>
        <b-row class="editor-main">
          <b-col cols="12" class="h-100">
            <b-container v-if="!error">
              <b-row v-for="cache in caches" :key="cache.Name">
                <b-col>{{cache.Name}}</b-col>
                <b-col>{{cache.EntryCount}}</b-col>
                <b-col>
                  <b-button @click="clearClick({ cache })">
                    Clear {{cache.Name}} Cache
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
            <b-container v-else>
              Access Denied
            </b-container>
          </b-col>
        </b-row>
      </template>
    </b-container>
  </div>
</template>

<script lang="ts">
// TODO: call getCurrentUser when in the admin screens
//  v-if="currentUser.HasAccess"
// TODO: make this Admin.vue and make cache a sub!

import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import MxSecurity from '@/mixins/security'

const nsCache = namespace('cache')

@Component({
  components: {
  },
})
export default class extends Mixins(MxSecurity) {
  @nsCache.State('error')
  private error!: any

  @nsCache.State('caches')
  private caches!: any

  @nsCache.Action('getCaches')
  private getCaches!: any

  @nsCache.Action('clearCache')
  private clearCache!: any

  public mounted() {
    this.getCaches()
  }

  public clearClick({ cache }: { cache: Cache }) {
    this.clearCache({ cache }).then(() => {
      this.getCaches()
    })
  }

  get versionText() {
    return process.env.VUE_APP_VERSION
  }
}
</script>

<style scoped>

</style>
