<template>
  <b-tab v-if="sectionTab">
    <template #title>
      <slot name="sectionTabHeader" v-bind="sectionTab"></slot>
      {{ sectionTab.DisplayName }}
    </template>
    <slot name="sectionTabContentHeader" v-bind="sectionTab"></slot>
    <model-table :sectionTable="sectionTable" :fields="fields" v-for="sectionTable in sectionTab.Tables" :key="sectionTable.SectionTableId">
      <template v-if="sectionTab.Tables.length > 1" #sectionTableHeader="sectionTable">
        <slot name="sectionTableHeader" v-bind="sectionTable"></slot>
      </template>
      <template #rowHeader="model">
        <slot name="rowHeader" v-bind="model"></slot>
      </template>
    </model-table>
  </b-tab>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'

import LoadingText from '@/components/utility/loading-text.vue'

import MxSite from '@/mixins/site'

import ModelTable from './table.vue'

import { SectionTab } from '@/store/model-viewer/types'

@Component({
  components: {
    'loading-text': LoadingText,
    'model-table': ModelTable,
  },
})
export default class extends MxSite {
  @Prop()
  public sectionTab!: SectionTab

  @Prop({ default: () => [] })
  public fields!: any[]
}
</script>
