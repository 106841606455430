import { Api } from '@/api/Api'
import { HttpMethod } from '@/api/FetchApi'
import _ from 'lodash'
import { ActionTree } from 'vuex'
import { SelectedShippingCost } from '../cart/types'
import { ResponseArray, RootState } from '../types'
import { blankOrderArray, blankSearchFilters } from './constants'
import State, { ISearchFilters, INewOrder, IOrder, IOrderDocument, IOrderAddress } from './types'
import SecurityState from '../security/types'
const api = new Api()

export const actions: ActionTree<State, RootState> = {
  getOrders(
    { commit, rootState },
    searchFilters: ISearchFilters,
  ): any {
    const {
      page,
      orderStatus,
      sortBy,
      sortDesc,
      salesOrderNumber,
      customerName,
      poNumber,
      orderDate,
      keyTerm = '',
      salesRep = null,
      delivery = '',
      pageSize = 10,
      withoutCustomer,
      saveFilters,
    } = searchFilters
    commit('ordersLoading')
    if (saveFilters) {
      commit('searchFiltersSet', searchFilters)
    }
    let url = `${rootState.apiPrefix ?? ''}/order?OrderStatus=` +
      orderStatus +
      '&PageSize=' +
      pageSize +
      '&Page=' +
      page +
      '&SalesOrderNumber=' +
      salesOrderNumber +
      '&Customer=' +
      encodeURIComponent(customerName ?? '') +
      '&PoNumber=' +
      encodeURIComponent(poNumber ?? '') +
      '&OrderDate=' +
      encodeURIComponent(orderDate ?? '') +
      '&SortBy=' +
      sortBy +
      '&SortDirection=' +
      (sortDesc ? 'desc' : 'asc') +
      '&KeyTerm=' +
      encodeURIComponent(keyTerm ?? '') +
      '&Delivery=' +
      encodeURIComponent(delivery ?? '')
    if (withoutCustomer !== undefined) {
      url += '&WithoutCustomer=' + encodeURIComponent(withoutCustomer ?? '')
    }

    if (salesRep && salesRep !== '') {
      url += `&salesRep=${salesRep}`
    }
    const securityState = (this.state as any).security as SecurityState
    return api
      .fetchV2({
        url,
        user: securityState.currentUser,
      })
      .then((response) => {
        const orders: ResponseArray<IOrder> = _.assign({}, blankOrderArray)
        orders.loading = false
        orders.List = response.List
        // TODO: Exists a better way to do it?
        orders.Page = response.Page
        orders.PageSize = response.PageSize
        orders.Total = response.Total

        orders.List.forEach((order) => {
          order.Items = order.OrderItems.map((x) => {
            return {
              pcnCatalogNumber: x.MaterialNumber,
              fingoodTpiMfgMod: x.Description,
              description: x.Description,
              quantity: x.Quantity,
              price: x.Price,
              tax: x.Tax,
              totalPrice: x.Status !== 'Cancelled' ? x.Price * x.Quantity : 0,
              imgThumbnail: '',
              status: x.Status,
            }
          })
        })
        commit('ordersLoaded', orders)
        return orders
      })
      .catch(() => {
        commit('ordersError')
      })
  },
  async addOrder({ commit, dispatch, rootState }, order: INewOrder) {
    try {
      commit('orderAdding')
      const url = `${rootState.apiPrefix ?? ''}/order`
      const obj = {
        PurchaseNo: order.CustomerPurchaseOrderNumber,
        SalesRepNumber: order.SalesRepNumber,
        BillToParty: order.BillToParty,
        ShipToParty: order.ShipToParty,
        Items: order.Items.map((item, index) => {
          return {
            Material: item.pcnCatalogNumber,
            ItemNumber: index * 10,
            Quantity: item.quantity,
            QuantityUnit: 'EA',
          }
        }),
        TextLines: order.TextLines,
        InquiryId: order.InquiryId,
        InquiryFiles: order.InquiryFiles?.map(s => s.Id),
      }
      const body: BodyInit = new FormData()
      body.append('request', JSON.stringify({ Data: obj }))
      order.Files?.forEach(file => body.append('files', file))
      const securityState = (this.state as any).security as SecurityState
      api
        .fetchV2({
          url,
          method: HttpMethod.Post,
          user: securityState.currentUser,
          body,
        })
        .then((response) => {
          if (
            response.SalesOrderNumber !== undefined &&
            response.SalesOrderNumber !== null
          ) {
            const savedOrder: IOrder = response
            savedOrder.Items = savedOrder.OrderItems.map((x) => {
              return {
                pcnCatalogNumber: x.MaterialNumber,
                fingoodTpiMfgMod: x.Description,
                description: x.Description,
                quantity: x.Quantity,
                price: x.Price,
                tax: x.Tax,
                totalPrice: x.Status !== 'Cancelled' ? x.Price * x.Quantity : 0,
                imgThumbnail: '',
                status: x.Status,
              }
            })
            commit('orderAdded', savedOrder)
            dispatch('cart/clearCartItems', null, { root: true })
          } else {
            commit('orderAddedError', response.Message ?? response.Error)
          }
        })
        .catch(() => {
          commit(
            'orderAddedError',
            'Error processing request. Please try again later or contact support',
          )
        })
      return false
    } catch (error) {
      commit('orderAddedError')
    }
  },
  async fetchDocuments({ commit }, salesOrderNumber: string) {
    try {
      commit('orderDocumentsLoading')
      const url = '/order/documents/' + salesOrderNumber
      await api
        .fetch({
          url,
        })
        .then((response) => {
          const error = response.Message ?? response.Error
          if (error) {
            commit('orderDocumentError', error)
          } else {
            const documents: IOrderDocument[] = response
            commit('orderDocumentsSet', documents)
          }
        })
      return false
    } catch (error) {
      commit('orderDocumentsSet', [])
      commit('orderDocumentError', error)
    }
  },
  async uploadDocuments({ commit, dispatch, rootState }, { orderNumber, files }: {orderNumber: string, files: File[]}) {
    try {
      commit('orderDocumentsLoading')
      const url = `${rootState.apiPrefix ?? ''}/order/document/` + orderNumber
      const body: BodyInit = new FormData()
      files.forEach(file => body.append('files', file))
      const securityState = (this.state as any).security as SecurityState

      return await api
        .fetchV2({
          url,
          method: HttpMethod.Post,
          user: securityState.currentUser,
          body,
        })
        .then((response) => {
          const error = response.Message ?? response.Error
          if (error) {
            commit('orderDocumentError', error)
          } else {
            dispatch('orders/fetchDocuments', orderNumber, { root: true })
          }
        })
        .catch(() => {
          commit(
            'orderAddedError',
            'Error processing request. Please try again later or contact support',
          )
        })
    } catch (error) {
      commit('orderDocumentsSet', [])
      commit('orderDocumentError', error)
    }
  },
  async clipDocuments({ commit, dispatch, rootState }, { orderNumber, documentIds }: {orderNumber: string, documentIds: number[]}) {
    try {
      commit('orderDocumentsLoading')
      const url = `${rootState.apiPrefix ?? ''}/order/document/clip`
      const obj = {
        documentIds,
      }
      const securityState = (this.state as any).security as SecurityState

      return await api
        .fetchV2({
          url,
          method: HttpMethod.Post,
          user: securityState.currentUser,
          obj,
        })
        .then((response) => {
          const error = response.Message ?? response.Error
          if (error) {
            commit('orderDocumentError', error)
          } else {
            dispatch('orders/fetchDocuments', orderNumber, { root: true })
          }
        })
    } catch (error) {
      commit('orderDocumentsSet', [])
      commit('orderDocumentError', error)
    }
  },
  async unclipDocuments({ commit, dispatch, rootState }, { orderNumber, documentId }: {orderNumber: string, documentId: number}) {
    try {
      commit('orderDocumentsLoading')
      const url = `${rootState.apiPrefix ?? ''}/order/document/unclip/${documentId}`
      const securityState = (this.state as any).security as SecurityState
      return await api
        .fetchV2({
          url,
          method: HttpMethod.Post,
          user: securityState.currentUser,
        })
        .then((response) => {
          const error = response.Message ?? response.Error
          if (error) {
            commit('orderDocumentError', error)
          } else {
            dispatch('orders/fetchDocuments', orderNumber, { root: true })
          }
        })
    } catch (error) {
      commit('orderDocumentsSet', [])
      commit('orderDocumentError', error)
    }
  },
  async deleteDocuments({ commit, dispatch, rootState }, { orderNumber, documentId }: {orderNumber: string, documentId: number}) {
    try {
      const securityState = (this.state as any).security as SecurityState
      commit('orderDocumentsLoading')
      const url = `${rootState.apiPrefix ?? ''}/order/document/delete/${documentId}`
      return await api
        .fetchV2({
          url,
          method: HttpMethod.Post,
          user: securityState.currentUser,
        })
        .then(async (response) => {
          const error = response.Message ?? response.Error
          if (error) {
            commit('orderDocumentError', error)
          } else {
            await dispatch('orders/fetchDocuments', orderNumber, { root: true })
          }
        })
    } catch (error) {
      commit('orderDocumentsSet', [])
      commit('orderDocumentError', error)
    }
  },
  async addPaymentOrder({ commit, dispatch }, order: INewOrder) {
    try {
      commit('orderAdding')
      const url = '/order/nocustomer'
      const obj = {
        PurchaseNo: order.CustomerPurchaseOrderNumber,
        BillToParty: order.BillToParty,
        ShipToParty: order.ShipToParty,
        Items: order.Items.map((item) => {
          return {
            Material: item.pcnCatalogNumber,
            Quantity: item.quantity,
            QuantityUnit: 'EA',
          }
        }),
        TextLines: order.TextLines,
        CardInfo: order.CardInfo,
        Email: order.Email,
        ShippingCost: order.ShippingCost,
      }
      api
        .fetch({
          url,
          obj,
        })
        .then((response) => {
          if (
            response.SalesOrderNumber !== undefined &&
            response.SalesOrderNumber !== null
          ) {
            const savedOrder: IOrder = response
            savedOrder.Email = order.Email
            savedOrder.Items = savedOrder.OrderItems.map((x) => {
              return {
                pcnCatalogNumber: x.MaterialNumber,
                fingoodTpiMfgMod: x.Description,
                description: x.Description,
                quantity: x.Quantity,
                price: x.Price,
                tax: x.Tax,
                totalPrice: x.Status !== 'Cancelled' ? x.Price * x.Quantity : 0,
                imgThumbnail: '',
                status: x.Status,
              }
            })
            commit('orderAdded', savedOrder)
            dispatch('cart/clearCartItems', null, { root: true })
          } else {
            commit('orderAddedError', response.Message ?? response.Error)
          }
        })
        .catch(() => {
          commit(
            'orderAddedError',
            'Error processing request. Please try again later or contact support',
          )
        })
      return false
    } catch (error) {
      commit('orderAddedError')
    }
  },
  setActiveOrder({ commit }, order: IOrder) {
    try {
      commit('activeOrderSet', order)
    } catch (error) {
      commit('activeOrderError')
    }
  },
  fetchOrder({ commit, dispatch, state, rootState }, salesOrderNumber: string) {
    try {
      const securityState = (this.state as any).security as SecurityState
      dispatch('common/setOverlayShowing', true, { root: true })
      let order: any
      if (state.orders && state.orders.List) {
        order = state.orders.List.find(
          (x) => x.SalesOrderNumber === salesOrderNumber,
        )
      }
      if (!order) {
        const url = `${rootState.apiPrefix ?? ''}/order/${salesOrderNumber}`
        let fetchApi: Promise<any>
        if (salesOrderNumber.length > 10) {
          fetchApi = api.fetch({
            url,
          })
        } else {
          fetchApi = api.fetchV2({
            url,
            user: securityState.currentUser,
          })
        }
        fetchApi
          .then((response) => {
            const savedOrder: IOrder = response
            savedOrder.Items = savedOrder.OrderItems.map((x) => {
              return {
                pcnCatalogNumber: x.MaterialNumber,
                fingoodTpiMfgMod: x.Description,
                description: x.Description,
                quantity: x.Quantity,
                price: x.Price,
                tax: x.Tax,
                totalPrice: x.Status !== 'Cancelled' ? x.Price * x.Quantity : 0,
                imgThumbnail: x.Image ?? '',
                status: x.Status,
              }
            })
            order = savedOrder
            commit('activeOrderSet', order)
            dispatch('common/setOverlayShowing', false, { root: true })
          })
          .catch(() => {
            commit('activeOrderError')
            dispatch('common/setOverlayShowing', false, { root: true })
          })
      } else {
        commit('activeOrderSet', order)
        dispatch('common/setOverlayShowing', false, { root: true })
      }
    } catch (error) {
      commit('activeOrderError')
      dispatch('common/setOverlayShowing', false, { root: true })
    }
  },
  validateAddresses({ commit }, addresses: IOrderAddress[]) {
    try {
      return api.fetchV2({
        url: '/addresses/validate',
        obj: addresses,
        method: HttpMethod.Post,
      })
    } catch {
      commit('orderValidationFailed')
    }
  },
  async getPDFInvoiceOrder(
    store,
    { invoiceNumber }: { invoiceNumber: string },
  ) {
    const url = '/api/PDF/PdfInvoiceFromOrder/?InvoiceNumber=' + invoiceNumber
    const response = await fetch(url)
    const blob = await response.blob()
    const responseUrl = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = responseUrl
    link.download = 'Invoice_Order_' + invoiceNumber + '.pdf'
    link.click()
    URL.revokeObjectURL(responseUrl)
  },

  setIsSubmitting({ commit }, isSubmittingOrder) {
    commit('isSubmittingSet', isSubmittingOrder)
  },

  setCurrentOrder({ commit }, order: IOrder) {
    try {
      commit('currentOrderSet', order)
    } catch (error) {
      commit('currentOrderError')
    }
  },

  resetCurrentOrder({ commit }) {
    commit('currentOrderReset')
  },
  setValidationErrors({ commit }, errors: object) {
    try {
      commit('validationErrorsSet', errors)
    } catch (error) {
      commit('validationErrorsError')
    }
  },
  setShippingCost({ commit }, cost: SelectedShippingCost) {
    commit('shippingCostSet', cost)
  },
  resetShippingCost({ commit }) {
    commit('shippingCostReset')
  },
  resetSearchFilters({ commit }) {
    commit('searchFiltersSet', blankSearchFilters)
  },
}
