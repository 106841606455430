import _ from 'lodash'
import { loading, blankResponseArray } from '../constants'
import { ResponseArray } from '../types'
import {
  IOrderCustomer,
  IOrder,
  IOrderAddress,
  IOrderItem,
  IOrderParty,
  ISearchFilters,
} from './types'

export const blankOrderAddress: IOrderAddress = {
  Name: '',
  Address1: '',
  Address2: '',
  City: '',
  State: '',
  Zip: '',
  Phone: '',
  Country: 'US',
  Email: '',
  FirstName: '',
  LastName: '',
}

export const blankOrderParty: IOrderParty = {
  PartnerNumber: '',
  Name: '',
  Street: '',
  Name2: '',
  Phone: '',
  City: '',
  Region: '',
  PostalCode: '',
  Country: '',
}

export const blankOrderCustomer: IOrderCustomer = {
  poNumber: '',
  customerNo: '',
  companyName: '',
  shippingMethod: 'Best Way',
  address: _.merge({}, blankOrderAddress),
}

export const blankOrderItem: IOrderItem = {
  ItemNumber: '',
  MaterialNumber: '',
  Description: '',
  Quantity: 0,
  EA: 'EA',
  ShippedQuantity: 0,
  Price: 0.0,
  Tax: 0.0,
  InvoiceNumber: '',
  SalesOrderNumber: '',
  DeliveryNumber: '',
  Status: '',
}

export const blankOrderArray: ResponseArray<IOrder> = _.assign(
  [],
  blankResponseArray,
)

export const loadingOrders: ResponseArray<IOrder> = _.assign(
  [],
  blankOrderArray,
  loading,
)

export const blankOrder: IOrder = {
  SalesOrderNumber: '',
  OrderId: 0,
  OrderDate: new Date(),
  CustomerPurchaseOrderNumber: '',
  CustomerNumber: '',
  CustomerName: '',
  SalesRepName: '',
  SalesRepPhone: '',
  ShipVia: '',
  TrackingNumber: '',
  BillToParty: _.merge({}, blankOrderAddress),
  ShipToParty: _.merge({}, blankOrderAddress),
  RemitTo: _.merge({}, blankOrderAddress),
  Total: 0,
  Currency: '', // USD
  Deliveries: [],
  Items: [],
  OrderStatus: 'In Progress',
  OrderItems: [],
  SalesRepNumber: '',
  TextLines: [],
  TaxCalculated: false,
  Email: '',
  Comments: [],
  Creator: '',
  CreatorUsername: '',
  SalesRepresentatives: [],
  AuthorizationNumber: '',
}

export const blankCustomers: ResponseArray<IOrderCustomer> = _.assign(
  {},
  blankResponseArray,
  { loading: false },
)

export const blankCustomer: IOrderCustomer = {
  poNumber: '',
  customerNo: '',
  companyName: '',
  shippingMethod: 'Best Way',
  address: blankOrderAddress,
  paymentTerm: '',
}

export const STATUS_ALL = 'All'

export const OrderStatus = {
  ALL: 'All',
  PENDING: 'Pending',
  IN_PROGRESS: 'In Progress',
  SHIPPED: 'Shipped',
  CANCELLED: 'Cancelled',
  // COMPLETED: 'Completed', // disabled until TPI find the logic for transitioning from shipped to completed
}

export const TAX_PERCENTAGE = 0.0955

export const blankSearchFilters: ISearchFilters = {
  page: 1,
  pageSize: 10,
  orderStatus: STATUS_ALL,
  sortBy: 'OrderDate',
  sortDesc: true,
  salesOrderNumber: '',
  customerName: '',
  poNumber: '',
  orderDate: '',
  keyTerm: '',
  salesRep: '',
  tabIndex: 0,
  delivery: '',
  saveFilters: true,
}
