import { Screen } from './screen'
import { debounce } from 'typescript-debounce-decorator'

export class BreakpointState {
  public screen: Screen = new Screen()
  public scrollY = 0

  private style = getComputedStyle(document.body)
  private xs = Number(this.style.getPropertyValue('--breakpoint-xs').replace('px', ''))
  private sm = Number(this.style.getPropertyValue('--breakpoint-sm').replace('px', ''))
  private md = Number(this.style.getPropertyValue('--breakpoint-md').replace('px', ''))
  private lg = Number(this.style.getPropertyValue('--breakpoint-lg').replace('px', ''))
  private xl = Number(this.style.getPropertyValue('--breakpoint-xl').replace('px', ''))

  public onResize() {
    const { xs, sm, md, lg, xl, screen } = this
    const width = window.innerWidth
    /* Not really sure how to properly define gt or lt */
    screen.xs = width >= xs && width < sm
    screen.sm = width >= sm && width < md
    screen.md = width >= md && width < lg
    screen.lg = width >= lg && width < xl
    screen.xl = width >= xl

    /* Greater than */
    screen.gt.xs = width >= xs
    screen.gt.sm = width >= sm
    screen.gt.md = width >= md
    screen.gt.lg = width >= lg
    screen.gt.xl = width >= xl

    /* Less than */
    screen.lt.xs = width < sm
    screen.lt.sm = width < md
    screen.lt.md = width < lg
    screen.lt.lg = width < xl
    screen.lt.xl = width < 9999
  }

  @debounce(100)
  public onScroll() {
    this.scrollY = window && window.top ? window.top.scrollY : 0
  }
}

/* This assumes you're using default bootstrap breakpoint names */
/* You need to hardcode the breakpoint values if you want to support IE11 */

export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  install(Vue: any) {
    const breakpoints = Vue.observable(new BreakpointState())
    Vue.prototype.$breakpoints = breakpoints
    window.onresize = () => breakpoints.onResize()
    window.onscroll = () => breakpoints.onScroll()
    breakpoints.onResize()
  },
}
