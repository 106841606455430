import { MutationTree } from 'vuex'
import { ArrayLoading } from '../types'
import { loadingCache } from './constants'
import State, { Cache } from './types'

export const mutations: MutationTree<State> = {
  cacheLoaded(state, caches: ArrayLoading<Cache>) {
    state.error = false
    state.caches = caches
  },
  cacheError(state) {
    state.error = true
    state.caches = loadingCache
  },
}
