import { MutationTree } from 'vuex'
import State from './types'
import { Model } from '../product/types'
import { ResponseArray } from '@/store/types'
import { blankResponseArray } from '../constants'

export const mutations: MutationTree<State> = {
  visibleModelsLoading(state: State) {
    state.error = false
    state.visibleModelsResults = blankResponseArray
  },
  visibleModelsResults(state: State, payload: ResponseArray<Model>) {
    state.error = false
    state.visibleModelsResults = payload
  },
  visibleModelsError(state: State) {
    state.error = true
    state.visibleModelsResults = blankResponseArray
  },
}
