import { RouteConfig } from 'vue-router'
import MenuPage from '@/views/MenuPage.vue'
import Product from '@/views/Product.vue'
import CatalogHome from '@/views/Home.vue'

import { Store } from 'vuex'
import State from '@/store/types'

export function getRoutes(): RouteConfig[] {
  return [
    {
      path: '',
      name: 'catalog',
      meta: { title: 'Product Search' },
      components: {
        'main': CatalogHome,
      },
    },
    {
      path: ':category',
      name: 'menu-page-category',
      components: {
        'main': MenuPage,
      },
    },
    {
      path: ':category/:productclass',
      name: 'menu-page-productclass',
      components: {
        'main': MenuPage,
      },
    },
    {
      path: ':category/:productclass/:series/:product/:section?/:tab?',
      name: 'product',
      components: {
        'main': Product,
      },
    },
  ]
}

export function getWaits(store: Store<State>) {
  return [
    async (to: any, from: any, next: any) => {
      // if we have a catalogId in the query, ignore the preloaded state
      if (to.query.catalogId || !store.state.preloaded) {
        if (to.query.catalogId === from.query.catalogId) next() // no need to get menu if catalog did not change!
        const catalogId = to.query.catalogId || store.state.site.CatalogId
        await store.dispatch('menu/getMenu', { catalogId })
      }
      next()
    },
  ]
}
