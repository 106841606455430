<template>
  <b-container fluid id="printMe">
    <b-row>
      <b-col>
        <slot name="top-left"></slot>
      </b-col>
      <b-col>
        <slot name="top-right"></slot>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h4>Selected Model: {{ sideBarModel.model.PcnCatalogNumber }}</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <slot name="bottom"></slot>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { SideBarItem } from '../store/product/types'

const nsProduct = namespace('product')

@Component({
  components: {
  },
})
export default class extends Vue {
   @nsProduct.Getter('sideBarModel')
  private sideBarModel!: SideBarItem
}
</script>

<style scoped>
.detail-container {
  height: 100% !important;
}
</style>
