import { Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import MxToast from '@/mixins/toast'
import { User } from '@/store/security/types'

import VueJwtDecode from 'vue-jwt-decode'

const nsSecurity = namespace('security')

@Component
export default class extends MxToast {
  @nsSecurity.State('currentUser')
  public currentUser!: User

  @nsSecurity.State('passwordExpired')
  public passwordExpired!: boolean

  @nsSecurity.State('resetUsername')
  public resetUsername!: string

  @nsSecurity.Getter('canRead')
  public canRead!: any

  @nsSecurity.Action('login')
  public login!: any

  @nsSecurity.Action('logout')
  public logout!: any

  @nsSecurity.Action('signup')
  public signup!: any

  @nsSecurity.Action('resetpassword')
  public resetpassword!: any

  @nsSecurity.Action('updatepassword')
  public updatepassword!: any

  @nsSecurity.Action('changePassword')
  public changePassword!: any

  get role() {
    return process.env.VUE_APP_TPI_ROLE
  }

  decodeJwt(jwt: string) : User {
    return VueJwtDecode.decode(jwt)
  }
}
