<template>
  <div class="mx-a">
    <b-carousel
      id="image-carousel"
      :interval="0"
      fade
      controls
      indicators
      img-height="(screen.lt.md ? 250 : 400)"
      img-width="(screen.lt.md ? 250 : 400)"
      class="image-carousel"
      v-if="haveImages">
      <b-carousel-slide
        v-for="img in images"
        :key="img.ImageId"
        img-height="(screen.lt.md ? 250 : 400)"
        :text="img.Caption"
        >
        <template v-slot:img>
          <b-img
            class="d-block img-fluid m-auto"
            :src="img.ImagePath + '?width=' + (screen.lt.md ? 250 : 400) + '&height=' + (screen.lt.md ? 250 : 400)"
            :alt="img.Caption"
            @error="event => _loadErrorImage(event, screen.lt.md ? '250' : '400', screen.lt.md ? '250' : '400')"
          />
          </template>
      </b-carousel-slide>
    </b-carousel>
    <div class="caption w-100 mx-auto" v-if="haveImages">
      <p class="text-muted text-center disclaimer-text font-italic">Image shown is a representation, actual product may vary. Not all options shown are available on all models.</p>
    </div>
    <div class="no-image-container" v-else>
      <i class="fa fa-image fa-10x"></i><br />
      <span class="no-image-text" v-if="loadingImages">Loading</span>
      <span class="no-image-text" v-else>No Image Available</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { Image } from '@/store/product/types'
import MxSite from '@/mixins/site'

@Component({
  components: {
  },
})
export default class ImageCarousel extends Mixins(MxSite) {
  @Prop()
  public images!: Image[]

  get haveImages() {
    return this.images && this.images.length > 0 && !this.images[0].loading
  }

  get loadingImages() {
    return this.images && this.images.length > 0 && this.images[0].loading
  }

  get imagesCount() {
    return this.images.length
  }
}
</script>

<style>
/* i can't get scoped styles to work here??? */
.image-carousel {
  text-shadow: 1px 1px 1px rgb(19, 19, 19);
  height:425px;
}

.product-mobile .image-carousel {
  height: 275px;
}
.product-mobile .carousel {
    margin-top: 75px;
}

.image-carousel .carousel-item {
  height: 400px;
}

.product-mobile .image-carousel .carousel-item {
  height: 250px;
}

.image-carousel .carousel-item img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.image-carousel .carousel-caption {
  color: #ffffff;
  bottom: 0px;
  font-size: 1rem;
  background-color: rgba(105, 105, 105, 0.5);
}

.image-carousel .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%236c757d' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.image-carousel .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%236c757d' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.image-carousel .carousel-indicators li {
  background-color: #a2a7aa;
}

.disclaimer-text {
  font-size: 0.75rem;
}
</style>
