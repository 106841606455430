<template>
  <div class="pcn-search" ref="pcnSearch">
    <b-input-group>
      <b-form-input
        type="text"
        class="form-control search-input"
        placeholder="Enter a Catalog Number"
        :value="catalogNumber"
        @input="asyncFind"
        :v-model="catalogNumber"
      />
      <b-input-group-append>
        <b-input-group-text v-if="loading">
          <b-spinner label="Spinning" small></b-spinner>
        </b-input-group-text>
      </b-input-group-append>
    </b-input-group>
    <b-list-group
      :class="{ 'bottom-fixed': showAboveInput, 'result-list': true }"
      v-if="searchResults.Count > 0 && catalogNumber.length > 0"
    >
      <b-list-group-item
        v-for="result in searchResults.List"
        :key="result.PcnCatalogNumber"
        class="result-item text-left click"
        @click="selectModelResult(result)"
        role="button"
      >
        <p class="mb-0 font-weight-bold">
          {{ result.PcnCatalogNumber }}
          <span v-if="result.FingoodTpiMfgMod"
            >- {{ result.FingoodTpiMfgMod }}</span
          >
        </p>
        <span class="small">{{ result?.Descriptions[0]?.toString() || result.SapModelDescription }}</span>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script lang="ts">
import ModelViewer from '@/components/shared/model-viewer/viewer.vue'
import MxSite from '@/mixins/site'
import { blankResponseArray } from '@/store/constants'
import { Model } from '@/store/editor-model/types'
import { blankVisibleModelSearchRequest } from '@/store/search/constants'
import type { VisibleModelSearchRequest } from '@/store/search/types'
import { ResponseArray } from '@/store/types'
import { debounce } from 'typescript-debounce-decorator'
import Multiselect from 'vue-multiselect'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import SearchInput from '../search/search-input.vue'

const nsSearch = namespace('search')

@Component({
  components: {
    'model-viewer': ModelViewer,
    Multiselect,
    SearchInput,
  },
})
export default class extends MxSite {
  @Prop()
  public catalogNumberProp!: string

  public loading = false

  public catalogNumber = this.catalogNumberProp ?? ''

  @nsSearch.Action('searchVisibleModels')
  public searchVisibleModels: any

  public searchResults: ResponseArray<Model> = {
    ...blankResponseArray,
    loading: false,
  }

  public showAboveInput = false
  public pcnSearchRef = this.$refs.pcnSearch as HTMLElement

  @Watch('catalogNumberProp', {
    immediate: true,
  })
  public pcnUpdated(pcn: string) {
    this.catalogNumber = pcn
  }

  @debounce(1000)
  public async asyncFind(query: string) {
    this.catalogNumber = query
    this.handleSearch(query)
  }

  public handleSearch(searchTerm: string) {
    const search: VisibleModelSearchRequest = {
      ...blankVisibleModelSearchRequest,
      Keyword: searchTerm,
    }
    this.loading = true
    this.searchVisibleModels(search)
      .then((results: ResponseArray<Model>) => {
        const resultItems: Model[] = []

        results.List.forEach(async (model) => {
          resultItems.push(model)
        })
        this.searchResults = { ...results, List: resultItems }
        if (this.searchResults.List.length === 0 && searchTerm.length > 0) {
          this.toastWarning('No results found for: ' + searchTerm)
          if (this.catalogNumberProp) {
            this.catalogNumber = this.catalogNumberProp
          }
        }
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }

  public selectModelResult(model: Model) {
    this.catalogNumber = model.PcnCatalogNumber
    this.$emit('onSelect', model)
    this.searchResults = blankResponseArray
  }

  public mounted() {
    this.checkPosition()
    window.addEventListener('scroll', this.checkPosition)
  }

  public checkPosition() {
    this.pcnSearchRef = this.$refs.pcnSearch as HTMLElement
    if (this.pcnSearchRef) {
      const elementRect = this.pcnSearchRef.getBoundingClientRect()
      const viewportHeight = window.innerHeight
      this.showAboveInput = elementRect.top >= 0.6 * viewportHeight
    }
  }
}
</script>

<style scoped>
.pcn-search {
  position: relative;
}

.result-list {
  position: absolute;
  width: 100%;
  height: 35vh;
  overflow: auto;
  z-index: 9999;
}

@media (min-width: 767px) {
  .result-list  {
    width: 25vw;
  }
}

.bottom-fixed {
  bottom: 0;
  margin-bottom: 38px;
}
.result-item:hover {
  background-color: #7ec0f7;
  color: #fff;
}
</style>
