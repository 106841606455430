import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import State from './types'
import { RootState } from '../types'
import { blankCart, blankCartItem } from './constants'
import _ from 'lodash'

export const state: State = {
  activeCart: _.assign({}, _.cloneDeep(blankCart)),
  error: false,
  activeCartItem: blankCartItem,
}

const namespaced = true

export const cart: Module<State, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
