import { Module } from 'vuex'
import { RootState } from '../types'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import State from './types'

export const state: State = {
  countries: [],
  countryStates: [],
  Error: false,
  showLoadingOverlay: false,
  hideBreadcrumbs: undefined,
}

const namespaced = true

export const common: Module<State, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
