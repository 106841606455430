<template>
  <span></span>
</template>
<script lang="ts">
import { Component, Mixins, Watch } from 'vue-property-decorator'
import MxSite from '@/mixins/site'

import _ from 'lodash'

import {
  ManufacturingSeries as BaseManufacturingSeries,
  ProductClass as BaseProductClass,
  Category as BaseCategory,
} from '@/store/types'

import { Category as MenuCategory, ProductClass as MenuProductClass } from '@/store/menu/types'

import { ProductClass as ProductProductClass, ManufacturingSeries as ProductManufacturingSeries, Product } from '@/store/product/types'

import { blankCategory } from '@/store/editor/constants'

import { namespace } from 'vuex-class'

const nsProduct = namespace('product')
const nsMenu = namespace('menu')

@Component({
  components: {
  },
})
export default class extends Mixins(MxSite) {
  @nsMenu.State('categories')
  private categories!: MenuCategory[]

  @nsProduct.State('product')
  private product!: Product

  public mounted() {
    this.setTitle()
  }

  @Watch('$route.params', { deep: true })
  @Watch('product')
  @Watch('categories')
  @Watch('site.BrowserTitle')
  public setTitle() {
    const { name, params: currentParams, meta } = this.$route

    const { product } = this

    let displayName = ''

    const titleData: string[] = []

    let manufacturingSeries: BaseManufacturingSeries | undefined
    let productClass: BaseProductClass | undefined
    let category: BaseCategory | undefined
    // let manufacturingSeries = {}

    switch (name) { // note: yes two switches, this one is for the references, the other one is for the breadcrumb entries
      case 'menu-page-series':
      case 'menu-page-productclass':
      case 'menu-page-category':
        if (currentParams.category) {
          category = this.categories ? this.categories.find(c => c.Name === currentParams.category) : blankCategory
          if (currentParams.productclass && category) {
            productClass = (category as MenuCategory).ProductClasses.find(c => c.Name === currentParams.productclass)
            if (currentParams.series && productClass) {
              manufacturingSeries = (productClass as MenuProductClass).ManufacturingSeries.find(c => c.Name === currentParams.series)
            }
          }
        }
        break
    }

    switch (name) {
      case 'product':
        if (!product) { break }
        if (currentParams.pcnCatalogNumber) {
          displayName = currentParams.pcnCatalogNumber
          titleData.push(displayName)
        }

        if (currentParams.tab) {
          const sectionTab = product.Sections.flatMap(s => s.Tabs).find(t => t.Name === currentParams.tab)
          displayName = sectionTab ? sectionTab.DisplayName : currentParams.tab
          titleData.push(displayName)
        }

        displayName = product.DisplayName
        titleData.push(displayName)

        // if (!product.ManufacturingSeries) { break }
        manufacturingSeries = product.ManufacturingSeries
        if (manufacturingSeries) {
          productClass = (manufacturingSeries as ProductManufacturingSeries).ProductClass
          if (productClass) {
            category = (productClass as ProductProductClass).Category
          }
        }
        break // just the Product for Product?
      case 'menu-page-series':
        if (manufacturingSeries) {
          displayName = manufacturingSeries.DisplayName
          titleData.push(displayName)
        }
      /* falls through */
      case 'menu-page-productclass':
        if (productClass) {
          displayName = productClass.DisplayName
          titleData.push(displayName)
        }
      /* falls through */
      case 'menu-page-category':
        if (category) {
          displayName = category.DisplayName
          titleData.push(displayName)
        }
        break
      case 'search':
        if (currentParams.keyword) {
          if (currentParams.page) {
            displayName = (currentParams.page ? currentParams.page : '1')
            titleData.push(displayName)
          }

          displayName = '"' + currentParams.keyword + '"'
          titleData.push(displayName)
        }

        displayName = 'Search Results'
        titleData.push(displayName)

        break
      default:
        if (meta && meta.title) {
          titleData.push(meta.title)
        }
        if (name?.startsWith('editor')) {
          titleData.push('Editor')
        }
    }

    titleData.push(this.site.BrowserTitle)

    document.title = _.join(titleData.reverse(), ' - ')
  }
}
</script>
<style lang="scss">

</style>
