import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import State from './types'
import { RootState } from '../types'
import { blankEditor, blankEntityObject, loadingEditor, loadingEntityObject } from './constants'

export const state: State = {
  Error: false,
  entityObjects: loadingEntityObject,
  entityObjectLookup: {
    model: blankEntityObject,
    modelDetail: blankEntityObject,
    category: blankEntityObject,
    productClass: blankEntityObject,
    manufacturingSeries: blankEntityObject,
    product: blankEntityObject,
    sectionTableLayout: blankEntityObject,
  },
  editors: loadingEditor,
  editorLookup: {
    model: blankEditor,
    multipleModelEditor: blankEditor,
    imageFolder: blankEditor,
    documentFolder: blankEditor,
    schedule: blankEditor,
    site: blankEditor,
    catalog: blankEditor,
    division: blankEditor,
    category: blankEditor,
    productClass: blankEditor,
    manufacturingSeries: blankEditor,
    product: blankEditor,
    entity: blankEditor,
    editor: blankEditor,
    editorSection: blankEditor,
    editorField: blankEditor,
    permissions: blankEditor,
    modelDetails: blankEditor,
    multipleModelChangeRequest: blankEditor,
  },
  preloaded: false,
}

const namespaced = true

export const entity: Module<State, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
