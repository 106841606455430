<template>
  <div class="checkout-address-form">
    <h5 class="my-2">
      <b>{{ title }}</b>
    </h5>
    <p class="my-2">
      {{ subtitle }}
    </p>
    <div class="form-group row">
      <div class="col-md-6">
        <label for="first-name">First Name</label>
        <input
          type="text"
          class="form-control"
          id="first-name"
          name="FirstName"
          v-model.trim="address.FirstName"
          :class="{
            'is-invalid': errors.FirstName,
          }"
        />
        <div v-if="errors.FirstName" class="invalid-feedback">
          {{ errors.FirstName }}
        </div>
      </div>
      <div class="col-md-6">
        <label for="last-name">Last Name</label>
        <input
          type="text"
          class="form-control"
          name="LastName"
          id="last-name"
          v-model.trim="address.LastName"
          :class="{
            'is-invalid': errors.LastName,
          }"
        />
        <div v-if="errors.LastName" class="invalid-feedback">
          {{ errors.LastName }}
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-12">
        <label for="country-dropdown">Country: </label>
        <multiselect
          v-model="selectedCountry"
          deselect-label="Selected"
          :allow-empty="false"
          :options="countries"
          :close-on-select="true"
          :clear-on-select="false"
          placeholder="Select a Country"
          track-by="AlphaCode"
          label="Name"
          :searchable="true"
          @input="handleCountrySelect"
          :class="{
            'is-invalid': errors.Country,
            'invalid-dropwdown': errors.Country,
          }"
        >
        </multiselect>
        <div v-if="errors.Country" class="invalid-feedback">
          {{ errors.Country }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="street-address">Street Address</label>
      <input
        type="text"
        id="street-address-line1"
        class="form-control"
        name="Address1"
        v-model.trim="address.Address1"
        :class="{
          'is-invalid': errors.Address1,
        }"
      />
      <div v-if="errors.Address1" class="invalid-feedback">
        {{ errors.Address1 }}
      </div>
      <input
        type="text"
        name="Address2"
        id="street-address-line2"
        class="form-control mt-3"
        v-model.trim="address.Address2"
      />
    </div>
    <div class="form-group row">
      <div class="col-md-6" v-if="address.Country === 'US'">
        <label for="state">State</label>
        <select
          id="state"
          class="form-control"
          v-model.trim="address.State"
          :class="{
            'is-invalid': errors.State,
          }"
          :disabled="errors.Country === ''"
          name="State"
        >
          <option value="">Select Your State</option>
          <option
            v-for="state in states"
            :key="state.AlphaCode"
            :value="state.AlphaCode"
          >
            {{ state.Name }}
          </option>
        </select>
        <div v-if="errors.State" class="invalid-feedback">
          {{ errors.State }}
        </div>
      </div>
      <div class="col-md-6" v-else>
        <label for="street-address">State</label>
        <input
          type="text"
          id="state"
          class="form-control"
          name="State"
          v-model.trim="address.State"
          :class="{
            'is-invalid': errors.State,
          }"
        />
        <div v-if="errors.State" class="invalid-feedback">
          {{ errors.State }}
        </div>
      </div>
      <div class="col-md-6">
        <label for="City">City</label>
        <input
          type="text"
          id="City"
          class="form-control"
          name="City"
          v-model.trim="address.City"
          :class="{
            'is-invalid': errors.City,
          }"
        />
        <div v-if="!address.City" class="invalid-feedback">
          {{ errors.City }}
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-6">
        <label for="zip-code">Zip/Postal Code</label>
        <input
          id="zip-code"
          class="form-control"
          name="Zip"
          v-model.trim="address.Zip"
          :class="{
            'is-invalid': errors.Zip,
          }"
          placeholder=""
        />
        <div v-if="errors.Zip" class="invalid-feedback">
          {{ errors.Zip }}
        </div>
      </div>
      <div class="col-md-6">
        <label for="Phone">Phone</label>
        <input
          type="tel"
          id="Phone"
          class="form-control"
          name="Phone"
          v-model.trim="address.Phone"
          :class="{
            'is-invalid': errors.Phone,
          }"
          maxlength="10"
          placeholder="(000) 000-0000"
          @input="formatPhoneNumber"
        />
        <div v-if="errors.Phone" class="invalid-feedback">
          {{ errors.Phone }}
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-6">
        <label for="email">Email</label>
        <input
          id="email"
          class="form-control"
          name="Email"
          v-model.trim="address.Email"
          :class="{
            'is-invalid': errors.Email,
          }"
          placeholder=""
          type="email"
        />
        <div v-if="errors.Email" class="invalid-feedback">
          {{ errors.Email }}
        </div>
      </div>
    </div>
    <div class="form-check" v-show="isBillTo">
      <input
        type="checkbox"
        id="same-address"
        class="form-check-input mr-2"
        :value="sameAddress"
        @change="handleChangeCheckbox"
      />
      <label for="same-address" class="form-check-label"
        >My Billing and shipping address are the same</label
      >
    </div>
    <!-- <b-button variant="outline-primary float-right my-2" @click="submit"
      >Save</b-button
    > -->
  </div>
</template>
<script lang="ts">
import { ICountry, ICountryState } from '@/store/common/types'
import { blankOrderAddress } from '@/store/orders/constants'
import { IOrderAddress, IOrderCustomer } from '@/store/orders/types'
import Multiselect from 'vue-multiselect'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const nsOrders = namespace('orders')

@Component({
  components: {
    Multiselect,
  },
})
export default class OrderCheckoutForm extends Vue {
  @Prop({ type: String, required: true }) title!: string
  @Prop({ type: String }) subtitle!: string
  @Prop({ type: Boolean, default: false }) sameAddress!: boolean
  @Prop({ type: Array, default: () => [] }) customers!: IOrderCustomer[]
  @Prop({ type: Boolean, default: false }) isBillTo!: boolean
  @Prop({ type: Array, default: () => [] }) countries!: ICountry[]
  @Prop({ type: Array, default: () => [] }) states!: ICountryState[]
  @Prop({ type: Object, required: true }) address!: IOrderAddress

  @nsOrders.Action('setValidationErrors')
  public setValidationErrors: any

  public shippingMethods = ['Best Way', 'UPS', 'FedEx', 'Will Call']
  public selectedCustomer = ''
  public selectedDBCustomer: any = null
  public searchKeyword = ''
  public selectedCountry: any = null
  public errors: any = {}

  public validate() {
    this.errors = {}
    if (!this.address.FirstName) {
      this.errors.FirstName = 'First Name is required'
    }

    if (!this.address.LastName) {
      this.errors.LastName = 'Last Name is required'
    }

    if (!this.address.Address1) {
      this.errors.Address1 = 'Address Line 1 is required'
    }

    if (!this.address.State) {
      this.errors.State = 'State is required'
    }

    if (!this.address.City) {
      this.errors.City = 'City is required'
    }

    if (!this.address.Zip) {
      this.errors.Zip = 'Zip is required'
    }

    if (!this.address.Phone) {
      this.errors.Phone = 'Phone is required'
    }

    if (!this.address.Country) {
      this.errors.Country = 'Country is required'
    }

    if (!this.address.Email) {
      this.errors.Email = 'Email is required'
    }

    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

    if (this.address.Email && !emailRegex.test(this.address.Email)) {
      this.errors.Email = 'Email is invalid'
    }

    this.setValidationErrors({ key: this.title, list: this.errors })
    return Object.keys(this.errors).length === 0 ? this.address : null
  }

  // Watchers
  @Watch('countries')
  onCountriesChanged(newCountries: ICountry[]) {
    this.selectedCountry = newCountries.find((c) => c.AlphaCode === 'US')
  }

  public formatPhoneNumber() {
    const digitsOnly = this.address.Phone.replace(/\D/g, '')
    this.address.Phone = digitsOnly.replace(
      /^(\d{3})(\d{3})(\d{4})$/,
      '($1) $2-$3',
    )
  }

  public handleChangeCheckbox(event: any) {
    this.$emit('handleShowBillingAddress', event.target.checked)
  }

  public async handleCountrySelect(country: { AlphaCode: any } | null) {
    if (country != null) {
      this.address = {
        ...blankOrderAddress,
        ...this.address,
        Country: country.AlphaCode,
        State: '',
      }
    } else {
      this.address = {
        ...this.address,
        Country: '',
        State: '',
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.section1 label {
  font-weight: bold;
}

.section1 .form-check-label {
  font-weight: normal;
}

.invalid-dropwdown {
  border: 1px solid #cd0200;
  border-radius: 5px;
}
</style>
