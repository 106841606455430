<template>
  <!-- TODO: login page! -->
  <b-nav-text align="right" id="navUser" v-if="currentUser">
    <!-- TODO: another flag besides HasAccess needed to know if we are not logged in? -->
    <template v-if="currentUser.HasAccess">
      Logged in as: {{getCurrentUserName()}}
      (<b-button variant="link" @click="onLogout()">Logout</b-button>)
    </template>
    <template v-else>
      <b-button id="btnHeaderLogin" variant="link"><b-icon icon="person" /> Login</b-button>
      <b-popover target="btnHeaderLogin" title="Login" triggers="click blur" :show.sync="showPopover">
        <b-container fluid>
          <b-row v-if="error">
            <b-col>
              <b-alert show variant="danger">{{error}}</b-alert>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-input-group>
                <b-form-input autofocus v-model="loginRequest.Username" placeholder="Username"></b-form-input>
              </b-input-group>
              <b-input-group>
                <b-form-input type="password" v-model="loginRequest.Password" placeholder="Password"></b-form-input>
              </b-input-group>
            </b-col>
            <b-col cols="4">
              <b-button @click="onLogin()">
                Login
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <span>Do you need an account? <a href="/authentication/signup">Create one</a></span>
          </b-row>
        </b-container>
      </b-popover>
    </template>
    <!-- TODO: make better -->
    <!-- TODO: options like logout, etc -->
  </b-nav-text>
</template>
<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'

import { ILoginRequest, IHttpError } from '@/store/security/types'

import MxSecurity from '@/mixins/security'
@Component({
  components: {
  },
})
export default class extends Mixins(MxSecurity) {
  // TODO: remove test data
  public showPopover = false

  public loginRequest: ILoginRequest = {
    Username: '',
    Password: '',
    Scope: '',
  }

  private error: string | null = null

  public onLogin() {
    this.error = null

    const { loginRequest } = this
    const { $router } = this

    this.login({ loginRequest }).then(() => {
      this.showPopover = false
      $router.push('/')
    }).catch((error: IHttpError | any) => {
      this.error = error.Message ?? 'Failed to login, please try again later'
    })
  }

  public onLogout() {
    this.logout().then(() => {
      this.showPopover = false
      const { $router } = this

      $router.replace('/authentication/login')
    })
  }

  public getCurrentUserName() {
    return (this.currentUser.Name ?? '') !== ''
      ? `${this.currentUser.Name} ${this.currentUser.LastName ?? ''}`
      : this.currentUser.Username
  }
}
</script>
