<template>
  <b-container fluid="xl">
    <div class="row">
      <div class="col-md-8">
        <div class="row mx-1">
          <b-card class="col-md-12 my-2 px-0">
            <address-form :title="'Shipping Address'" :subtitle="'We cannot ship to a P.O Box - We must have physical address'" :isBillTo="true" :sameAddress="sameAddress" @handleShowBillingAddress="handleSameAddress" ref="shippingAddressForm" :countries="countries" :states="states" :address="currentOrder.ShipToParty" />
          </b-card>
          <b-card class="col-md-12 my-2 px-0" v-show="!sameAddress">
            <address-form :title="'Billing Address'" ref="billingAddressForm" :countries="countries" :states="states" :address="currentOrder.BillToParty" />
          </b-card>
          <b-card class="col-md-12 my-2 px-0">
            <h5 class="my-2"><b>Shipping Information and Cost</b></h5>
            <shipping-cost v-for="(groupedItems, shippingStrategyId) in itemsByShippingStrategy" :key="shippingStrategyId" :strategy="shippingStrategyId" :items="groupedItems" />
          </b-card>
        </div>
      </div>
      <div class="col-md-4">
        <div class="row mx-1">
          <b-card class="col-md-12 my-2 px-0"><order-review :cartItems="cart.items" :handleDelete="deleteCartItem" :handleQtyChange="updateCartItemQty" /></b-card>
          <b-card class="col-md-12 my-2 px-0"><billing-summary :subtotal="totalPrice" :shipping="totalShippingCost" :tax="0" :formatCost="formatCost" :submit="checkout" /></b-card>
        </div>
      </div>
    </div>
    <payment-details v-if="showPaymentModal" :show="showPaymentModal" :handleClose="closePaymentModal" :order="currentOrder" :shippingCost="totalShippingCost" />
    <order-confirmation v-if="showConfirmationModal" :show="showConfirmationModal" :orderConfirmation="orderConfirmation" />
    <address-verification v-if="showAddressVerificationModal" :show="showAddressVerificationModal"
      :shipToAddress="shipToAddress"
      :billToAddress="billToAddress"
      :validatedBillToAddress="validatedBillToAddress"
      :validatedShipToAddress="validatedShipToAddress"
      :useSameAddress="sameAddress"
      :onSelected="mergeAddressesAndExecuteCheckout"
      @close="showAddressVerificationModal = false"
      />
    <loading-overlay :isLoading="isSubmittingOrder || isLoading" :loadingText="isSubmittingOrder ? 'Submitting Order' : 'Loading...'" />
  </b-container>
</template>

<style>
.fit-to-parent {
  width: 100%;
  height: 100%;
  border-width: 0;
}
</style>

<script lang="ts">
import AddressForm from '@/components/checkout/address-form.vue'
import BillingSummary from '@/components/checkout/billing-summary.vue'
import OrderReview from '@/components/checkout/order-review.vue'
import PaymentDetails from '@/components/checkout/payment-details.vue'
import ShippingCost from '@/components/checkout/shipping-cost.vue'
import Vue from 'vue'
import { Component, Mixins, Watch } from 'vue-property-decorator'

import OrderConfirmation from '@/components/checkout/order-confirmation.vue'
import AddressVerification from '@/components/checkout/address-verification.vue'
import LoadingOverlay from '@/components/utility/loading-overlay.vue'
import MxSite from '@/mixins/site'
import { Cart, CartItem, SelectedShippingCost } from '@/store/cart/types'
import { TAX_PERCENTAGE } from '@/store/orders/constants'
import { IAddressValidation, IOrder, IOrderAddress, IOrderConfirmation } from '@/store/orders/types'
import { namespace } from 'vuex-class'
import _ from 'lodash'

const nsCart = namespace('cart')
const nsOrders = namespace('orders')
const shippingAddressKey = 'shipping'
const billingAddressKey = 'billing'
const countryUs = 'US'

@Component({
  components: {
    AddressForm,
    ShippingCost,
    PaymentDetails,
    OrderReview,
    BillingSummary,
    OrderConfirmation,
    LoadingOverlay,
    AddressVerification,
  },
})
export default class CheckoutPage extends Mixins(MxSite) {
  @nsCart.Getter('cart')
  public cart!: Cart

  @nsCart.Action('deleteCartItem')
  public deleteCartItem: any

  @nsOrders.Action('validateAddresses')
  public validateAddresses: any

  @nsOrders.Action('resetShippingCost')
  public resetShippingCost: any

  @nsCart.Action('updateCartItemQty')
  public updateCartItemQty: any

  @nsOrders.State('currentOrder')
  public currentOrder!: IOrder

  @nsOrders.Action('setCurrentOrder')
  public setCurrentOrder: any

  @nsOrders.Action('resetCurrentOrder')
  private resetCurrentOrder: any

  @nsOrders.Action('addPaymentOrder')
  public addPaymentOrder: any

  @nsOrders.State('shippingCosts')
  public shippingCosts!: number[]

  public sameAddress = false

  public handleSameAddress(checked: boolean) {
    this.sameAddress = checked
  }

  public formatCost(cost: number): string {
    return `$${cost.toFixed(2)}`
  }

  public isLoading = false

  public shipToAddress: IOrderAddress | null = null

  public billToAddress: IOrderAddress | null = null

  public validatedShipToAddress: IOrderAddress | null = null

  public validatedBillToAddress: IOrderAddress | null = null

  public taxPercentage = TAX_PERCENTAGE

  public comment: string | null = null

  public addressFormRef
  public billingAddressRef

  public countries = []
  public states = []

  public showPaymentModal = false
  public showConfirmationModal = false
  public showAddressVerificationModal = false
  public orderConfirmation: IOrderConfirmation = {
    PublicKey: '',
    Email: '',
  }

  @nsOrders.State('activeOrder')
  private activeOrder!: IOrder

  @nsOrders.State('submissionError')
  private submissionError!: string

  @Watch('activeOrder')
  public handleOrderAdded(newOrder: IOrder) {
    if (newOrder.PublicKey) {
      this.showPaymentModal = false
      this.showConfirmationModal = true
      this.orderConfirmation = {
        PublicKey: newOrder.PublicKey,
        Email: newOrder.Email,
      }
    }
  }

  @Watch('submissionError')
  public alertSubmissionError(newError) {
    if (newError) {
      const h = this.$createElement
      const vNodesMsg = h('div', [
        'Failed to place order: ',
        h('br'),
        h('br'),
        newError,
      ])
      this.toastErrorNode(vNodesMsg)
      this.showPaymentModal = false
    }
  }

  @nsOrders.State('isSubmittingOrder')
  public isSubmittingOrder!: boolean

  public created() {
    this.resetCurrentOrder()
  }

  public async mounted() {
    this.addressFormRef = this.$refs.shippingAddressForm as Vue
    this.billingAddressRef = this.$refs.billingAddressForm as Vue
    await this.fetchCountries()
    await this.fetchUSStates()
  }

  async fetchCountries() {
    try {
      await this.$store.dispatch('common/fetchCountries', '')
      this.countries = this.$store.getters['common/countries']
    } catch (error) {
      console.error('Error fetching countries:', error)
    }
  }

  async fetchUSStates() {
    try {
      await this.$store.dispatch('common/fetchCountryStates', 'US') // get states for US for now
      this.states = this.$store.getters['common/countryStates']
    } catch (error) {
      console.error('Error fetching country states:', error)
    }
  }

  public closePaymentModal() {
    this.showPaymentModal = false
  }

  public async checkout(comment: string) {
    const shippingInfo = this.addressFormRef.validate()
    let billingInfo = null

    const addressesToValidate: IAddressValidation[] =
      (shippingInfo as IOrderAddress).Country === countryUs
        ? [{ Id: shippingAddressKey, Address: shippingInfo! }]
        : []

    if (!this.sameAddress) {
      billingInfo = this.billingAddressRef.validate()

      if ((billingInfo! as IOrderAddress).Country === countryUs) {
        addressesToValidate.push({ Id: billingAddressKey, Address: billingInfo! })
      }
    } else {
      billingInfo = _.cloneDeep(shippingInfo)
    }

    this.comment = comment

    if (shippingInfo != null && billingInfo != null) {
      if (addressesToValidate.length === 0) {
        return this.executeCheckout(billingInfo, shippingInfo)
      }

      this.isLoading = true
      const result = await this.validateAddresses(addressesToValidate)

      this.isLoading = false
      if (result && result.Successful) {
        const shipToAddressResult = (result.find((item: IAddressValidation) => item.Id === shippingAddressKey) ?? {}).Address
        this.validatedShipToAddress = this.isSameAddress(shipToAddressResult, shippingInfo!)
          ? null
          : shipToAddressResult

        const billToAddressResult = (result.find((item: IAddressValidation) => item.Id === billingAddressKey) ?? {}).Address
        this.validatedBillToAddress = this.isSameAddress(billToAddressResult, billingInfo!)
          ? null
          : billToAddressResult
      }

      if (this.validatedBillToAddress === null && this.validatedShipToAddress === null) {
        return this.executeCheckout(billingInfo, shippingInfo)
      }

      this.shipToAddress = shippingInfo
      this.billToAddress = billingInfo
      this.showAddressVerificationModal = true
    }
  }

  public mergeAddressesAndExecuteCheckout(shippingInfo: IOrderAddress, billingInfo: IOrderAddress | null) {
    this.shipToAddress = _.merge(this.shipToAddress, {
      Address1: shippingInfo.Address1,
      Address2: shippingInfo.Address2,
      City: shippingInfo.City,
      State: shippingInfo.State,
      Zip: shippingInfo.Zip,
      Country: shippingInfo.Country,
    })

    if (this.sameAddress) {
      this.billToAddress = _.cloneDeep(this.shipToAddress)
    } else {
      this.billToAddress = _.merge(this.billToAddress, {
        Address1: billingInfo!.Address1,
        Address2: billingInfo!.Address2,
        City: billingInfo!.City,
        State: billingInfo!.State,
        Zip: billingInfo!.Zip,
        Country: billingInfo!.Country,
      })
    }

    this.showAddressVerificationModal = false

    this.executeCheckout(this.billToAddress, this.shipToAddress)
  }

  public executeCheckout(billingInfo: any, shippingInfo: any) {
    const newOrder: IOrder = {
      ...this.currentOrder,
      BillToParty: billingInfo,
      ShipToParty: shippingInfo,
      Items: this.cart.items,
      TextLines: [
        this.comment ?? '',
        `${this.totalShippingCost.toFixed(2)}`,
      ],
    }
    this.setCurrentOrder({ ...newOrder })
    this.showPaymentModal = true
  }

  public get totalPrice(): number {
    return this.cart.items.reduce(
      (total, item) => total + item.quantity * item.price,
      0,
    )
  }

  public get shippingStrategies(): string[] {
    const uniqueShippingStrategyIdsSet = new Set<string>()
    this.cart.items.forEach((obj) => {
      uniqueShippingStrategyIdsSet.add(obj.shippingStrategyId ?? 'Parts')
    })
    return [...uniqueShippingStrategyIdsSet]
  }

  public get itemsByShippingStrategy() {
    const groups: Record<number, CartItem[]> = this.cart.items.reduce((groups, item) => {
      const shippingStrategyId = item.shippingStrategyId ?? 'Parts'

      if (!groups[shippingStrategyId]) {
        groups[shippingStrategyId] = []
      }

      groups[shippingStrategyId].push(item)
      return groups
    }, {})
    return groups
  }

  public totalShippingCost = 0

  @Watch('shippingCosts', { deep: true })
  public onShippingCostChange(shippingCosts: SelectedShippingCost[]) {
    this.totalShippingCost = shippingCosts.reduce((accumulator, currentValue) => accumulator + currentValue.ShippingCost, 0)
  }

  isSameAddress(sourceAddress: IOrderAddress, comparisonAddress: IOrderAddress) {
    if (!sourceAddress || !comparisonAddress) return false

    return (sourceAddress.Address1 ?? '').toLowerCase() === (comparisonAddress.Address1 ?? '').toLowerCase() &&
      (sourceAddress.Address2 ?? '').toLowerCase() === (comparisonAddress.Address2 ?? '').toLowerCase() &&
      (sourceAddress.City ?? '').toLowerCase() === (comparisonAddress.City ?? '').toLowerCase() &&
      (sourceAddress.State ?? '').toLowerCase() === (comparisonAddress.State ?? '').toLowerCase() &&
      (sourceAddress.Zip ?? '').toLowerCase() === (comparisonAddress.Zip ?? '').toLowerCase()
  }
}
</script>
