import { GetterTree } from 'vuex'
import { RootState } from '../types'
import State from './types'

export const getters: GetterTree<State, RootState> = {
  validationErrors(state): object {
    const { validationErrors } = state
    return validationErrors
  },
}
