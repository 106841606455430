<template>
  <b-container fluid v-if="product && !product.loading" :class="{ scrolled: !atTop, 'product-mobile': screen.lt.md }">
    <b-row class="separator header-row w-100">
      <b-col>
        <b-container fluid class="title-container">
          <b-row class="title-row">
            <b-col>
              <b-container fluid class="title-container">
                <b-row>
                  <b-col>
                    <h2 @click="clearTab()" class="wsite-content-title">{{ product.DisplayName }}</h2>
                  </b-col>
                </b-row>
                <!-- <b-row class="m-0">
                  <b-col class="m-0 pb-2">
                    <b-link @click="clearTab()" v-if="screen.lt.md && $route.params.tab" class="p-0">
                      <span class="fa fa-caret-left"></span> Back
                    </b-link>
                  </b-col>
                </b-row> -->
              </b-container>
            </b-col>
            <b-col cols="3" v-if="screen.lt.md && $route.params.tab && images && images.length > 0" class="product-image-thumbnail">
              <div class="thumbnail-wrapper"><b-img fluid :src="images[0].ImagePath + '?width=200&height=200'" @error="event => _loadErrorImage(event, '200', '200')" /></div>
            </b-col>
          </b-row>
          <b-row class="sub-title-row" v-if="!screen.lt.md || !$route.params.tab">
            <b-col>
              <h4 class="wsite-content-title" v-if="product.Description">{{ product.Description }}</h4>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row v-if="screen.lt.md" class="product-row">
      <b-row class="separator adaptive-row" :hidden="showPrintView" v-if="!$route.params.tab">
        <b-col v-if="product.Images.length > 0">
          <image-carousel v-if="images" :images="images"></image-carousel>
        </b-col>
      </b-row>
      <b-row class="w-100 m-0" no-gutters>
        <b-col class="p-0">
          <b-card :class="'col-12 col-sm-12'" no-body class="table-card" v-if="$route.params.tab">
            <b-card-header class="tab-header text-center my-0 py-2" header-tag="h4" @click="clearTab()">
              {{ activeSectionTab.DisplayName }}
              <b-button class="close" variant="link">x</b-button>
            </b-card-header>
            <b-card-body class="tab-body p-1 w-auto">
              <features-list :features="product.Features" v-if="$route.params.tab == 'Features'"></features-list>
              <section-collapse v-else :product="product" :section="activeSection" :sectionTab="activeSectionTab"></section-collapse>
            </b-card-body>
          </b-card>
          <b-card no-body class="mb-1" v-b-toggle.tab-nav-collapse>
            <b-card-header header-tag="header" class="p-1" role="tab" v-if="$route.params.tab != 'Features'">
              <b-button block
                v-b-toggle="'accordion-features'"
                class="mobile-tab"
                @click="selectSectionTabByName({ sectionName: '', tabName: 'Features' })">
                Features <span class="fa fa-caret-right"></span>
              </b-button>
            </b-card-header>
            <template v-for="section in product.Sections">
              <b-card-header header-tag="h3" :key="'mobile-header-' + section.SectionId" class="text-center"
                v-if="(section.MobileDisplayName && section.MobileDisplayName.length > 0) || section.DisplayName && section.DisplayName.length > 0">
                {{ section.MobileDisplayName && section.MobileDisplayName.length > 0 ? section.MobileDisplayName : section.DisplayName }}
              </b-card-header>

              <template v-for="sectionTab in section.Tabs">
                <b-card-header header-tag="header" class="p-1" role="tab" :key="'sectionTab-header-' + sectionTab.SectionTabId"
                  v-if="$route.params.tab != sectionTab.DisplayName">
                  <b-button block v-b-toggle="'accordion-' + sectionTab.SectionTabId" class="mobile-tab"
                    @click="selectSectionTab({ section, sectionTab })">
                    {{ sectionTab.MobileDisplayName && sectionTab.MobileDisplayName.length > 0 ? sectionTab.MobileDisplayName : sectionTab.DisplayName }}<span class="fa fa-caret-right"></span>
                  </b-button>
                </b-card-header>
              </template>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-row>
    <b-row class='separator' v-else>
      <b-col>
        <b-container fluid>
          <print-model v-if="showPrintView" id="printMe">
            <template #top-left>
              <portal-target name="print-left"></portal-target>
            </template>
            <template #top-right>
              <portal-target name="print-right"></portal-target>
            </template>
            <template #bottom>
              <portal-target name="print-bottom"></portal-target>
            </template>
          </print-model>
          <!-- TODO: don't hardcode site here, make it an option! -->
          <b-row class='separator' :hidden="showPrintView" v-if="catalog.ProductImageEnabled || catalog.FeatureListEnabled">
            <b-col v-if="catalog.FeatureListEnabled" :md="product.Images.length > 0 && catalog.FeatureListEnabled && catalog.ProductImageEnabled ? 6 : 12" class="p-xs-0">
              <portal :disabled="!showPrintView" to="print-left">
                <features-list :features="product.Features"></features-list>
              </portal>
            </b-col>
            <b-col :md="catalog.FeatureListEnabled ? 6 : 12" class="p-xs-0" v-if="product.Images.length > 0 && catalog.ProductImageEnabled">
              <portal :disabled="!showPrintView" to="print-right">
                <image-carousel v-if="images" :images="images"></image-carousel>
              </portal>
            </b-col>
          </b-row>
          <b-row class='separator' :hidden="showPrintView">
            <b-col class="p-xs-0">
              <template v-if="!product.Sections.loading">
                <section-component v-for="section in product.Sections" :key="section.SectionId" :product="product" :section="section"></section-component>
                <exploded-view-modal></exploded-view-modal>
              </template>
              <loading-text v-else></loading-text>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
  <loading-text v-else></loading-text>
</template>
<script lang='ts'>
import { Component, Watch, Mixins } from 'vue-property-decorator'
import SectionComponent from '@/components/section.vue'
import SectionCollapse from '@/components/section-collapse.vue'
import { namespace } from 'vuex-class'
import { Product, Section, SectionTab, Image, SectionTable } from '@/store/product/types'
import ImageCarousel from '@/components/utility/image-carousel.vue'
import PrintModel from '@/components/print-model.vue'
import LoadingText from '@/components/utility/loading-text.vue'
import FeaturesList from '@/components/features-list.vue'
import MxSite from '@/mixins/site'
import { activeSectionNone, activeSectionTabNone, sectionTabFeatures } from '@/store/product/constants'
import ExplodedViewModal from '@/components/modals/exploded-view.vue'
import { TPIException } from '@/store/editor/types'

const nsProduct = namespace('product')

@Component({
  components: {
    'section-component': SectionComponent,
    'section-collapse': SectionCollapse,
    'image-carousel': ImageCarousel,
    'print-model': PrintModel,
    'loading-text': LoadingText,
    'features-list': FeaturesList,
    'exploded-view-modal': ExplodedViewModal,
  },
})
export default class extends Mixins(MxSite) {
  @nsProduct.State('activeSection')
  public activeSection!: Section

  @nsProduct.State('activeSectionTab')
  public activeSectionTab!: SectionTab

  @nsProduct.State('showPrintView')
  public showPrintView!: boolean

  @nsProduct.Mutation('setPrintView')
  public setPrintView: any

  @nsProduct.Action('getProduct')
  public getProduct: any

  @nsProduct.Action('getProductSections')
  public getProductSections: any

  @nsProduct.Action('loadSections')
  public loadSections: any

  @nsProduct.Action('loadImages')
  public loadImages: any

  @nsProduct.Action('loadTable')
  public loadTable: any

  @nsProduct.Action('clearActiveTab')
  public clearActiveTab: any

  @nsProduct.Action('setActiveTab')
  public setActiveTab: any

  // @nsProduct.Getter('productItem')
  // public product!: Product

  @nsProduct.State('product')
  public product!: Product

  @nsProduct.State('images')
  public images!: Image[] //

  public loadTables({ routeSectionTable, routePcnCatalognumber }: { routePcnCatalognumber: string, routeSectionTable: string }) {
    this.product.Sections.forEach(section => {
      section.Tabs.forEach(sectionTab => {
        sectionTab.Items.forEach(sectionItem => {
          const sectionTable = sectionItem as SectionTable
          if (sectionTable.SectionTableId) {
            setTimeout(() => this.loadTable({ routeSectionTable, routePcnCatalognumber, sectionTable }))
          }
        })
      })
    })
  }

  @Watch('$route.query.catalogId')
  @Watch('$route.query.isPreview')
  @Watch('$route.params.product')
  public onProductChanged() {
    const { product: productName } = this.$route.params
    const { catalogId, isPreview } = this.$route.query

    this.getProduct({ productName, catalogId, isPreview }).then(() => {
      this.getProductSections({ productName, catalogId, isPreview }).then(() => {
        const loadTables = () => {
          this.loadTables({ routePcnCatalognumber: '', routeSectionTable: '' })
        }
        setTimeout(loadTables)
      })

      const loadImages = () => { this.loadImages() }
      setTimeout(loadImages)
    }).catch((err: TPIException) => {
      this.toastError(err.Message)
    })
  }

  public mounted() {
    const { params, query } = this.$route

    const { section, tab } = params

    const pcnCatalogNumber = query.catalogNumber as string
    const sectionTable = query.sectionTable as string
    const catalogId = query.catalogId as string
    const isPreview = query.isPreview as string

    this.getProduct({ productName: this.$route.params.product, catalogId, isPreview }).then(() => {
      this.getProductSections({
        productName: this.$route.params.product,
        routeSectionName: section,
        routeTabName: tab, /// this could be a PCN - but then it just won't match?
        catalogId,
        isPreview,
      }).then(() => {
        if (!tab && !this.screen.lt.md) {
          if (this.product.Sections && this.product.Sections.length > 0) {
            if (this.product.Sections[0].Name && this.product.Sections[0].Name.length > 0) {
              params.section = this.product.Sections[0].Name
              if (this.product.Sections[0].Tabs && this.product.Sections[0].Tabs.length > 0) {
                params.tab = this.product.Sections[0].Tabs[0].Name
              }
            } else if (this.product.Sections[0].Tabs && this.product.Sections[0].Tabs.length > 0) {
              params.section = this.product.Sections[0].Tabs[0].Name
            }
            this.$router.replace({ params, query })
          }
        }

        const loadTables = () => {
          this.loadTables({ routePcnCatalognumber: pcnCatalogNumber, routeSectionTable: sectionTable })
        }
        setTimeout(loadTables)
      })
      const loadImages = () => { this.loadImages() }
      setTimeout(loadImages)
    }).catch((err: TPIException) => {
      this.toastError(err.Message)
    })

    window.addEventListener('afterprint', () => { this.setPrintView({ value: false }) })
  }

  public removeHash(hash: string) {
    if (window.location.hash === hash) { // remove the hash only if has something to do with the tab
      history.pushState('', document.title, window.location.pathname + window.location.search)
    }
  }

  public clearTab() {
    const { params, query } = this.$route
    delete params.section
    delete params.tab
    this.$router.replace({ params, query })
    if (this.screen.lt.md) {
      window.scrollTo(0, 0)
    }
    this.clearActiveTab()
  }

  public selectSectionTabByName({ sectionName, tabName }: { sectionName: string, tabName: string }) {
    const section = tabName === 'Features'
      ? activeSectionNone
      : this.product.Sections.find(s => s.Name === sectionName) || activeSectionNone

    const sectionTab = tabName === 'Features'
      ? sectionTabFeatures
      : (section ? section.Tabs.find(t => t.Name === tabName) || activeSectionTabNone : activeSectionTabNone)

    this.selectSectionTab({ section, sectionTab })
  }

  public selectTab({ sectionName, tabName }: { sectionName: string, tabName: string }) {
    const { params, query } = this.$route

    params.section = sectionName
    params.tab = tabName

    this.$router.replace({ params, query })
    if (this.screen.lt.md) {
      window.scrollTo(0, 0)
    }
  }

  public selectSectionTab({ section, sectionTab }: { section: Section, sectionTab: SectionTab }) {
    this.selectTab({ sectionName: section.Name, tabName: sectionTab.Name })
    this.setActiveTab({ section, sectionTab })
  }

  @Watch('product.Sections')
  public watchProduct() {
    const { query } = this.$route
    if (this.site.HasPricing && query.addToCart === '1' && this.catalog.CartEnabled && this.screen.lt.md) {
      const partsTab = this.product.Sections[0].Tabs.find(section => section.Name.toLowerCase().includes('parts'))
      if (this.product.Sections[0] && partsTab) {
        this.selectSectionTab({ section: this.product.Sections[0], sectionTab: partsTab })
      }
    }
  }

  get category() {
    // We will see what `params` is shortly
    return this.$route.params
  }

  get atTop() {
    return this.scrollY <= 1
  }

  get aboveImage() {
    return this.scrollY <= 260
  }

  // // kind of hacky for POC
  // get activeSection() {
  //   const { params } = this.$route
  //   if (params.tab === 'Features') {
  //     return null
  //   }

  //   return this.product.Sections.find(section => section.Tabs.find(tab => tab.active))
  // }

  // get activeSectionTab() {
  //   const { params } = this.$route
  //   if (!params.tab || params.tab === '' || params.tab === 'Features') {
  //     return null
  //   }
  //   if (!this.activeSection) {
  //     return null
  //   }

  //   return this.activeSection.Tabs.find(tab => tab.active)
  // }
}
</script>

<style scoped>
.separator {
  border-bottom: 1px solid #d0d0d0;
  padding: 30px 0;
}

h2.wsite-content-title {
  color: #42514c;
  font-family: "Arial";
  font-size: 30pt;
  text-align: center;
  text-transform: none;
}

h4.wsite-content-title {
  color: #6d6e6e;
  font-family: "Arial";
  font-size: 16pt;
  text-align: center;
  text-transform: none;
}

ul.paragraph {
  list-style: disc outside;
  padding-left: 1.5em;
  margin: 5px 0;
}

ul.text-list {
  list-style: none;
}

.adaptive-row {
  padding: 0 10px;
}

.product-mobile .header-row {
  position: fixed;
  top: 71px;
  z-index: 1000;
  margin: 0;
  padding: 3px;
  padding-bottom: 0 !important;
  background-color: #eee;
}

.product-mobile .tab-header {
  position: fixed;
  top: 158px;
  z-index: 1000;
  width: 100%;
  margin-left: -15px;
  background-color: #f5f5f5;

}

.tab-body {
  margin-top: 10px;
}

.product-mobile .tab-dropdown {
  position: fixed;
  top: 200px;
  margin: 0;
  padding: 3px;
  padding-bottom: 0 !important;
  background-color: #fff;
}

@media (min-width:768px) {
  .product-mobile .header-row {
    margin-left: -30px !important;
  }
}

.product-mobile .row {
  padding: 0;
  vertical-align: top;
  margin: 0 -15px;
}

.title-container {
  padding: 0;
}

.product-mobile .title-row>.col {
  min-height: 70px;
  background-color: #eee;
  display: flex;
  align-items: center;
}

.product-mobile .title-row h2 {
  font-size: 1rem;
  text-align: left;
  font-weight: bold;
  padding: 15px;
  margin: 0;
}

.product-mobile .sub-title-row {
  background-color: #eee;
  margin: 0 0 0 -20px;
  padding: 0;
}

.product-mobile .product-row>.row {
  margin: 0;
}

.product-mobile .sub-title-row h4 {
  font-size: 0.9rem;
  text-align: left;
  margin: 0;
  padding: 0;
  padding: 0 30px 15px 30px;
}

.product-mobile .row.product-row {
  margin: 125px 0 0 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.product-image-thumbnail {
  background: #eee;
  /* background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 80%, rgba(238,238,238,1) 80%, rgba(238,238,238,1) 100%); */
  /* animation: fadeIn ease-out 1s; */
}

.product-image-thumbnail .thumbnail-wrapper {
  display: inline-block;
  animation: fadeIn ease-out 1s;
  border: 1px solid #666;
  position: relative;
  top: 10px;
}

.product-image-thumbnail img {
  max-height: 70px;
}

.product-mobile .separator {
  border: 0 none;
}

.product-mobile .text-center~.card {
  border: 0;
}
</style>
<style>
.product-mobile .product-row h4 {
  margin-top: 15px;
}

.product-mobile .table-card {
  border: 0 none;
}

.product-mobile .card-body .paragraph li,
.product-mobile .table-card .card-body li {
  margin-bottom: .8rem;
}

.product-mobile .table-card {
  border: 0 none;
}

.product-mobile .card-header {
  border: 0 none;
}

.product-mobile .btn-secondary .fa {
  float: right;
  font-size: 1.2rem;
  padding-top: 3px;
}

.product-mobile .not-collapsed .fa {
  transform: rotate(180deg);
  transition: all ease-out .3s;
}

.product-mobile .collapsed .fa {
  transform: rotate(0);
  transition: all ease-in .2s;
}

.product-mobile .mobile-tab {
  font-weight: 700;
  padding: 0.75rem
}

.product-mobile .mobile-tab.collapsed {
  background-color: #666 !important;
  background-image: none !important;
  border: 0 none !important;
  ;
  border-radius: 0 !important;
  transition: background-color .3s;
}

.product-mobile .mobile-tab.not-collapsed {
  background-color: #333 !important;
  background-image: none !important;
  border: 0 none !important;
  border-radius: 0 !important;
  transition: background-color .3s;
}

.product-mobile .accordion>.card {
  border: 0;
}

.product-mobile .table-card {
  padding: 0;
}

figure.table {
  margin: 1em auto;
}
</style>
