<template>
  <div class="checkout-shipping-cost-form" v-if="!loading">
    <p v-if="showOtherCosts">
      {{ shippingStrategy.Description }}
    </p>
    <div class="my-2">
      <b-badge variant="primary" class="background-color-primary right">{{ shippingStrategy.DisplayName }}</b-badge>
      <div v-for="option in shippingStrategy.Costs" :key="option.ShippingStrategyCostId">
        <div :class="[
          'border rounded p-3 mb-3 d-flex align-items-center flex-wrap flex-sm-nowrap',
          selectedShippingCostId === option.ShippingStrategyCostId ? 'border-primary' : '',
        ]" v-if="selectedShippingCostId === option.ShippingStrategyCostId || showOtherCosts">
          <input class="mx-3" type="radio" :name="`${shippingStrategy.ShippingStrategyId}_${option.ShippingStrategyCostId}`" :id="`${shippingStrategy.ShippingStrategyId}_${option.ShippingStrategyCostId}`" :value="option.ShippingStrategyCostId" :checked="selectedShippingCostId === option.ShippingStrategyCostId" :disabled="selectedShippingCostId !== option.ShippingStrategyCostId" />
          <div :class="[
            ' d-flex align-items-center',
            selectedShippingCostId !== option.ShippingStrategyCostId ? 'text-muted' : '',
          ]" :for="option.ShippingStrategyCostId">
            <b class="mr-5">{{ formatCost(option.ShippingCost) }} </b>
            <span class="text-muted">{{ option.Description }} </span>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <span><i class="fa fa-info-circle mr-2 info-icon color-primary"></i></span>
      <span class="text-muted small">
        Shipping method and cost are automatically determined based on total
        cost of order.
        <b-button variant="link p-0" size="sm" @click="toggleShowMoreOptions">{{
          showOtherCosts ? 'Hide Costs' : 'See Costs'
        }}</b-button>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Api } from '@/api/Api'
import { blankShippingStrategy } from '@/store/cart/constants'
import { CartItem, ShippingStrategy } from '@/store/cart/types'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const api = new Api()

const nsOrders = namespace('orders')

@Component
export default class ShippingCost extends Vue {
  @nsOrders.Action('setShippingCost')
  public setShippingCost: any

  @Prop({ type: String, required: true })
  public strategy!: string

  @Prop({ type: Array, required: true })
  public items!: CartItem[]

  public showOtherCosts = false
  public shippingStrategy: ShippingStrategy = { ...blankShippingStrategy }
  public loading = false

  public formatCost(cost: number): string {
    return `$${cost.toFixed(2)}`
  }

  public toggleShowMoreOptions() {
    this.showOtherCosts = !this.showOtherCosts
  }

  public async mounted() {
    await this.getShippingStrategyDetails(this.strategy)
  }

  public get selectedShippingCostId(): number {
    const totalPrice = this.items.reduce(
      (total, item) => total + item.quantity * item.price,
      0,
    )
    let costId = this.shippingStrategy.Costs[0].ShippingStrategyCostId
    this.shippingStrategy.Costs.forEach((cost) => {
      if (
        totalPrice >= cost.MinOrderAmount &&
        (cost.MaxOrderAmount === undefined || totalPrice < cost.MaxOrderAmount!)
      ) {
        costId = cost.ShippingStrategyCostId
      }
    })
    return costId
  }

  public setShipping() {
    const totalPrice = this.items.reduce(
      (total, item) => total + item.quantity * item.price,
      0,
    )
    let shippingCost = this.shippingStrategy.Costs[0].ShippingCost
    this.shippingStrategy.Costs.forEach((cost) => {
      if (
        totalPrice >= cost.MinOrderAmount &&
        (cost.MaxOrderAmount === undefined || totalPrice < cost.MaxOrderAmount!)
      ) {
        shippingCost = cost.ShippingCost
      }
    })
    this.setShippingCost({ ShippingCost: shippingCost, ShippingStrategyId: this.shippingStrategy.ShippingStrategyId })
  }

  public async getShippingStrategyDetails(strategyId: string) {
    this.loading = true
    this.$store.dispatch('common/setOverlayShowing', true, { root: true })
    const url = `/cart/shipping_strategy?strategyId=${strategyId}`
    api
      .fetch({
        url,
      })
      .then((response) => {
        this.shippingStrategy = response
        this.$store.dispatch('common/setOverlayShowing', false, { root: true })
        this.loading = false
        this.setShipping()
      })
  }

  @Watch('items')
  public onItemsChanges() {
    this.setShipping()
  }
}
</script>
<style scoped lang="scss">
.info-icon {
  font-size: 1.2rem;
}
</style>
