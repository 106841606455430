import { GetterTree } from 'vuex'
import State, { Category } from './types'
import { RootState } from '../types'

export const getters: GetterTree<State, RootState> = {
  categories(state): Category[] {
    const { categories } = state
    const token = (categories) || []
    return token
  },
  activeCategory(state): string {
    const { categories } = state
    const token = (categories && categories.find(f => f.Active === true)) || ''
    return `${token}`
  },
}
