<template>
    <div class="section">
        <div class="container">
            <div class="row">
                <h4>Diagnostics Information</h4>
            </div>
            <div class="row">
                <table class="diagnostics-table">
                    <tbody>
                        <tr>
                            <td><b>Version</b></td>
                            <td>{{ versionText }}</td>
                        </tr>
                        <tr>
                            <td><b>User Agent</b></td>
                            <td>{{ userAgent }}</td>
                        </tr>
                        <tr>
                            <td><b>Time</b></td>
                            <td>{{ new Date() }}</td>
                        </tr>
                        <tr>
                            <td><b>Timezone</b></td>
                            <td>{{ Intl.DateTimeFormat().resolvedOptions().timeZone }}</td>
                        </tr>
                        <tr>
                            <td><b>Has User</b></td>
                            <td>{{ isUserLoggedIn }}</td>
                        </tr>
                        <tr>
                            <td><b>User</b></td>
                            <td>{{ currentUserFullName }}</td>
                        </tr>
                        <tr>
                            <td><b>Username</b></td>
                            <td>{{ currentUsername }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import MxSecurity from '@/mixins/security'

@Component({

})
export default class extends Mixins(MxSecurity) {
  get versionText() {
    return process.env.VUE_APP_VERSION
  }

  get userAgent() {
    return navigator.userAgent
  }

  get isUserLoggedIn() {
    return !!this.currentUser && !!this.currentUser.Username
  }

  get currentUserFullName() {
    if (!this.isUserLoggedIn) return ''

    return (this.currentUser.Name ?? '') !== ''
      ? `${this.currentUser.Name} ${this.currentUser.LastName ?? ''}`
      : this.currentUser.Username
  }

  get currentUsername() {
    if (!this.isUserLoggedIn) return ''

    return this.currentUser.Username
  }
}
</script>

<style>
    .diagnostics-table td {
        padding: 10px;
    }

    .diagnostics-table tr:nth-child(odd) {
        background-color: #f2f2f2;
    }
</style>
