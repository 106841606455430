import { MutationTree } from 'vuex'
import State, { ICountry, ICountryState } from './types'

export const mutations: MutationTree<State> = {
  countriesLoaded(state, countries: ICountry[]) {
    state.Error = false
    state.countries = countries
  },
  countriesError(state) {
    state.Error = true
    state.countries = []
  },
  countryStatesLoaded(state, countryStates: ICountryState[]) {
    state.Error = false
    state.countryStates = countryStates
  },
  countryStatesError(state) {
    state.Error = true
    state.countryStates = []
  },
  overlayLoadingSet(state, isShowing: boolean) {
    state.showLoadingOverlay = isShowing
  },
  hideBreadcrumbSet(state, isHide: boolean) {
    state.hideBreadcrumbs = isHide
  },
}
