<template>
  <div class="small">
    <b-row class="mb-2" v-if="item.imgThumbnail">
      <b-col cols="12">
        <div class="text-center">
          <b-img
            v-if="item.imgThumbnail"
            class="text-center"
            fluid
            :src="item.imgThumbnail + '?width=100&height=100'"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="item.pcnCatalogNumber">
      <b-col cols="4" class="row-details-label">PCN</b-col>
      <b-col cols="8"
        ><div class="text-left">
          {{ item.pcnCatalogNumber }}
        </div></b-col
      >
    </b-row>
    <b-row class="mb-2" v-if="item.fingoodTpiMfgMod">
      <b-col cols="4" class="row-details-label">Model #</b-col>
      <b-col cols="8 text-left">
        {{ item.fingoodTpiMfgMod }}
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="item.description">
      <b-col cols="4" class="row-details-label">Description</b-col>
      <b-col cols="8 text-left">
        {{ item.description }}
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="item.inventory">
      <b-col cols="4" class="row-details-label">Inventory</b-col>
      <b-col cols="8 text-left">
        <modal-inventory :item="item"></modal-inventory>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="item.status">
      <b-col cols="4" class="row-details-label">Status</b-col>
      <b-col cols="8 text-left">
        {{ item.status }}
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="item.price">
      <b-col cols="4" class="row-details-label">Unit Price</b-col>
      <b-col cols="8 text-left">
        {{ formatPrice(item.price) }}
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="item.quantity">
      <b-col cols="4" class="row-details-label">Quantity</b-col>
      <b-col cols="8 text-left">
        {{ item.quantity }}
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="item.totalPrice">
      <b-col cols="4" class="row-details-label">Total Price</b-col>
      <b-col cols="8 text-left">
        {{ formatPrice(item.totalPrice) }}
      </b-col>
    </b-row>
    <label>Details</label>
    <div v-if="item.modelDetails" class="model-details">
      <b-row v-for="(value, key) in item.modelDetails" :key="key">
        <b-col cols="4" class="row-details-label">{{ key }}</b-col>
        <b-col cols="8 text-left">
          {{ value }}
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script lang="ts">
import MxSite from '@/mixins/site'
import { CartItem } from '@/store/cart/types'
import { BIcon, BImg } from 'bootstrap-vue'
import { Component, Prop } from 'vue-property-decorator'
import ModalInventory from '../inventory/modal-inventory.vue'
import PcnSearch from '../search/pcn-search.vue'

@Component({
  components: { BIcon, BImg, PcnSearch, ModalInventory },
})
export default class ItemTable extends MxSite {
  @Prop()
  public item!: CartItem

  public formatPrice(val: any): string {
    if (val == null) {
      return ''
    } else if (isNaN(val)) {
      return val
    } else {
      return `$${parseFloat(val).toFixed(2)}`
    }
  }
}
</script>

<style scoped>
.item-icon {
  font-size: 2rem;
}
.item-img {
  width: 60px;
}

.quantity-input {
  max-width: 80px;
  float: right;
}

.image-soon {
  height: 50px;
  width: 50px;
}

.actions {
  display: flex;
}

.pcn-model-search {
  display: flex;
}

.row-details-label {
  color: #000;
  font-weight: bold;
  text-align: right;
}

.model-details {
  border-top: 1px solid #dee2e6;
  padding-top: 5px;
}
</style>
