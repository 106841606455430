<template>
  <b-card no-body>
    <b-card-header class="py-1" header-tag="h3">
      <slot name="manufacturingSeriesHeader" v-bind="manufacturingSeries"></slot>
      {{manufacturingSeries.DisplayName}}
    </b-card-header>
    <b-card-body v-if="manufacturingSeries.Products.length > 0" class="p-1 w-auto">
      <model-product :product="product" :fields="fields" v-for="product in manufacturingSeries.Products" :key="product.ProductId">
        <template #productHeader="product"><slot name="productHeader" v-bind="product"></slot></template>
        <template #sectionTabHeader="sectionTab"><slot name="sectionTabHeader" v-bind="sectionTab"></slot></template>
        <template #sectionTabContentHeader="sectionTab"><slot name="sectionTabContentHeader" v-bind="sectionTab"></slot></template>
        <template #sectionTableHeader="sectionTable"><slot name="sectionTableHeader" v-bind="sectionTable"></slot></template>
        <template #rowHeader="model"><slot name="rowHeader" v-bind="model"></slot></template>
      </model-product>
    </b-card-body>
  </b-card>

</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'

import LoadingText from '@/components/utility/loading-text.vue'

import MxSite from '@/mixins/site'

import ModelProduct from './product.vue'
import { ManufacturingSeries } from '@/store/model-viewer/types'

@Component({
  components: {
    'loading-text': LoadingText,
    'model-product': ModelProduct,
  },
})
export default class extends MxSite {
  @Prop()
  public manufacturingSeries!: ManufacturingSeries

  @Prop({ default: () => [] })
  public fields!: any[]
}
</script>
