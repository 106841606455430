import _ from 'lodash'
import Vue from 'vue'

import { MutationTree } from 'vuex'
import State, {
  Facet, TableLayoutColumn,
  Product, Section, Image, SectionTab, SectionTable, Model, TableLayout,
} from './types'

import {
  productNotFound,
  imageLoading,
  productLoading,
  modelNone,
  tableLayoutNone,
  activeSectionTabNone,
  activeSectionNone,
  sectionTableNone,
} from './constants'

const formatForSorting = (val: string) => {
  if (!val) { // return a blank if it's undefined
    return ''
  }
  const replacedValue = val.replace(/'|"|\$"/g, '')
  const parsedValue = Number(replacedValue)
  if (Number.isNaN(parsedValue)) {
    return val.toLowerCase()
  } else {
    const arr = replacedValue.split('.')
    const dec = arr.length > 1 ? arr[1] : ''
    return arr[0].padStart(10, '0') + '.' + dec.padEnd(10, '0')
  }
}

export const mutations: MutationTree<State> = {
  productLoading(state: State) {
    state.Error = false
    state.product = productLoading
  },
  productLoaded(state, { product }: { product: Product }) {
    state.Error = false
    if (!product) {
      state.product = productNotFound
      return
    }

    state.product = product
  },
  productError(state) {
    state.product = productNotFound
    state.Error = true
  },
  productSectionsLoaded(state, { product }: { product: Product }) {
    state.Error = false
    Vue.set(state.product, 'Sections', product.Sections)
  },
  productSectionsError(state) {
    state.Error = true
  },
  imagesLoading(state: State) {
    state.Error = false
    state.images = [imageLoading]
  },
  imagesLoaded(state, { images }: { images: Image[] }) {
    state.Error = false
    if (!images || images.length === 0) {
      state.images = []
      return
    }

    state.images = images
  },

  // updateFacet(state, { facet, count }: { facet: Facet, count: number }) {
  //   facet.Count = count
  // },
  updateFacets(state, { facets, sectionTable }: { facets: { [key: number]: Facet[] }, sectionTable: SectionTable }) {
    sectionTable.TableLayout.Columns.filter(c => c.FilterBy).forEach(column => {
      // TODO: match and update counts/visibles
      //  const updatedColumn = updatedSectionTable.TableLayout.Columns.find(c => c.SectionTableLayoutColumnId === column.SectionTableLayoutColumnId)
      const updatedFacets = facets[column.SectionTableLayoutColumnId]
      if (updatedFacets) {
        if (column.facets && column.facets.length > 0) {
          column.facets.forEach(facet => {
            const updatedFacet = updatedFacets.find(u => u.name === facet.name)
            facet.count = updatedFacet ? updatedFacet.count : 0
          })
        } else { // we never had facets before!
          column.facets = updatedFacets
        }
      } else {
        column.facets = []
      }
    })
  },
  setActiveTab(state, { section, sectionTab }: { section: Section, sectionTab: SectionTab }) {
    section.Tabs.forEach(t => { t.active = (t.SectionTabId === sectionTab.SectionTabId) })
    state.product.Sections.forEach(s => { s.active = (s.SectionId === section.SectionId) })
    state.activeSection = section
    state.activeSectionTab = sectionTab
  },
  clearActiveTab(state) {
    state.product.Sections.forEach(section => {
      section.active = false
      section.Tabs.forEach(tab => { tab.active = false })
    })
    state.activeSection = activeSectionNone
    state.activeSectionTab = activeSectionTabNone
  },
  setCurrentPage(state, { sectionTable, page }) {
    sectionTable.currentPage = page
  },
  toggleFilterVisible(state, { layoutProperty }: { layoutProperty: TableLayoutColumn }) {
    layoutProperty.filterVisible = !layoutProperty.filterVisible
  },
  updateSort(state, { sectionTable, tableLayoutColumn, sort }: { sectionTable: SectionTable, tableLayoutColumn: TableLayoutColumn, sort: number }) {
    const sortIndex = sectionTable.sorts.findIndex(lc => lc.Name === tableLayoutColumn.Name)
    if (tableLayoutColumn.sort === sort) { // remove this sort since we re-clicked the same button
      tableLayoutColumn.sort = 0
      sectionTable.sorts.splice(sortIndex, 1)
    } else { // add a new sort to the list
      if (sortIndex > -1) { // remove it if it was already there
        sectionTable.sorts.splice(sortIndex, 1)
      }
      tableLayoutColumn.sort = sort
      sectionTable.sorts.unshift(tableLayoutColumn)
    }
  },
  clearColumnFilters(state, { tableLayoutColumn }: { tableLayoutColumn: TableLayoutColumn }) {
    tableLayoutColumn.facets.forEach(facet => { facet.selected = false })
  },
  clearColumnKeyword(state, { tableLayoutColumn }: { tableLayoutColumn: TableLayoutColumn }) {
    tableLayoutColumn.keyword = ''
  },
  toggleSidebarVisible(state) {
    state.sideBarModel.visible = !state.sideBarModel.visible
  },
  setActiveModel(state, { model, tableLayout, sectionTable }: { index: number, model: Model, tableLayout: TableLayout, sectionTable: SectionTable }) {
    state.sideBarModel.visible = true
    state.sideBarModel.model = model
    state.sideBarModel.tableLayout = tableLayout
    state.sideBarModel.sectionTable = sectionTable
  },
  clearActiveModel(state) {
    state.sideBarModel.visible = false
    state.sideBarModel.model = modelNone
    state.sideBarModel.tableLayout = tableLayoutNone
    state.sideBarModel.sectionTable = sectionTableNone
  },
  setPrintView(state, { value }: { value: boolean }) {
    state.showPrintView = value
  },
  setFilteredModels(state, { sectionTable, models }: { sectionTable: SectionTable, models: Model[] }) {
    sectionTable.filteredModels = models
  },
  sortFilteredModels(state, { sectionTable }: { sectionTable: SectionTable }) {
    // TODO: this is now NOT sorting reverse at all

    const sortFields = sectionTable.sorts.map(layoutProperty => (model: Model) => formatForSorting(_.get(model, layoutProperty.key)))
    // const sortFields = sectionTable.sorts.map(tableLayoutColumn => (model: Model) => tableLayoutColumn.objectName.length > 0 ? model[tableLayoutColumn.objectName][tableLayoutColumn.propertyName] : model[tableLayoutColumn.propertyName])
    const sortDirections = sectionTable.sorts.map(s => s.sort > 0 ? 'asc' : 'desc')

    sectionTable.filteredModels = _.orderBy(sectionTable.filteredModels, sortFields, sortDirections)
  },
  setColumnFilteredModels(state, { sectionTable, columnFilteredModels }: { sectionTable: SectionTable, columnFilteredModels: { [key: number]: Model[] } }) {
    sectionTable.TableLayout.Columns.forEach(tableLayoutColumn => {
      tableLayoutColumn.filteredModels = columnFilteredModels[tableLayoutColumn.SectionTableLayoutColumnId]
    })
  },
  setSectionTableLoading(_, { sectionTable, value }: { sectionTable: SectionTable, value: boolean }) {
    sectionTable.loading = value
  },
  setSelectedImage(state: State, { image }: { image: Image }) {
    state.activeImage = image
  },
}
