import Vue from 'vue'
import Router, { RouteConfig, NavigationGuard } from 'vue-router'

export async function createRouter({ routes, waits }: { routes: RouteConfig[], waits: Array<NavigationGuard<Vue>> } = { routes: [], waits: [] }) {
  Vue.use(Router)

  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  })

  waits.forEach(wait => router.beforeEach(wait))

  return router
}
