import { MutationTree } from 'vuex'
import State, { Category } from './types'

export const mutations: MutationTree<State> = {
  menuLoaded(state, payload: Category[]) {
    state.Error = false

    const categories = payload

    state.categories = categories
  },
  menuError(state) {
    state.Error = true
    state.categories = undefined
  },
  setCategoryActive(state, { name }) {
    if (name) {
      state.activeCategory = state.categories?.find(c => c.Name === name)
      state.activeProductClass = state.activeCategory?.ProductClasses[0]
    }
  },

  setProductClassActive(state, { name }) {
    if (name) {
      state.activeProductClass = state.activeCategory?.ProductClasses.find(c => c.Name === name)
    }
  },

  setVersion(state, { version }) {
    state.version = version
  },
  toggleHidden(state) {
    state.hidden = !state.hidden
  },
}
