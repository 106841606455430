import { ActionTree } from 'vuex'
import State, { Cache } from './types'
import { RootState } from '../types'
import { Api } from '@/api/Api'

const api = new Api()

export const actions: ActionTree<State, RootState> = {
  getCaches({ commit }): any {
    return api.fetch({
      url: '/cache',
    })
      .then(response => {
        if (response.Error) {
          throw response.Error
        }
        const caches: Cache[] = response.List
        commit('cacheLoaded', caches)
        return caches
      }).catch(() => {
        commit('cacheError')
      })
  },
  clearCache({ commit }, { cache }: { cache: Cache }): any {
    return api.fetch({
      url: '/cache/clear/' + cache.Name,
    })
      .then(response => {
        return response
      }).catch(() => {
        commit('cacheError')
      })
  },
}
