var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('table',{staticClass:"diagnostics-table"},[_c('tbody',[_c('tr',[_vm._m(1),_c('td',[_vm._v(_vm._s(_vm.versionText))])]),_c('tr',[_vm._m(2),_c('td',[_vm._v(_vm._s(_vm.userAgent))])]),_c('tr',[_vm._m(3),_c('td',[_vm._v(_vm._s(new Date()))])]),_c('tr',[_vm._m(4),_c('td',[_vm._v(_vm._s(Intl.DateTimeFormat().resolvedOptions().timeZone))])]),_c('tr',[_vm._m(5),_c('td',[_vm._v(_vm._s(_vm.isUserLoggedIn))])]),_c('tr',[_vm._m(6),_c('td',[_vm._v(_vm._s(_vm.currentUserFullName))])]),_c('tr',[_vm._m(7),_c('td',[_vm._v(_vm._s(_vm.currentUsername))])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('h4',[_vm._v("Diagnostics Information")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('b',[_vm._v("Version")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('b',[_vm._v("User Agent")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('b',[_vm._v("Time")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('b',[_vm._v("Timezone")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('b',[_vm._v("Has User")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('b',[_vm._v("User")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('b',[_vm._v("Username")])])
}]

export { render, staticRenderFns }