import { GetterTree } from 'vuex'
import State, { Product, Model, SideBarItem, SectionTable, TableLayoutColumn } from './types'
import { RootState } from '@/store/types'

import _ from 'lodash'

export const getters: GetterTree<State, RootState> = {
  productItem(state): Product | undefined {
    const { product } = state
    const item = product || undefined
    return item
  },
  sideBarModel(state): SideBarItem {
    const { sideBarModel } = state
    const item = sideBarModel
    return item
  },
  sideVarVisible(state): boolean {
    const val = state.sideBarModel.visible
    return val
  },
  // getFacets: ({ }: State) => ({ sectionTable }: { sectionTable: SectionTable }) => {
  //   // TODO: filter by lists also...
  //   const tableFacets: any = sectionTable.TableLayout.Columns.filter(c => c.FilterBy && !c.IsList).map(tableLayoutColumn => ({
  //     const counts = _.countBy(sectionTable.Models, tableLayoutColumn.PropertyName)
  //     return Object.keys(counts).map(key => ({
  //       name: key,
  //       displayName: key,
  //       count: counts[key],
  //       selected: false,
  //     }))
  //   }))
  // },
  getFilteredModels: () => ({ sectionTable, excludeLayoutColumn, filteredList }: { sectionTable: SectionTable, excludeLayoutColumn: TableLayoutColumn, filteredList: Model[] }) => {
    // note: we uset he list passed, if passed
    const ret = filteredList || sectionTable.Models
    let columns = sectionTable.TableLayout.Columns
    if (excludeLayoutColumn !== undefined) { // don't include the current column when calculating facets for that column
      columns = columns.filter(column => column.SectionTableLayoutColumnId !== excludeLayoutColumn.SectionTableLayoutColumnId)
    }

    // TODO: filter on global keyword - but look at ALL columns
    // if (sectionTable.keyword !== '') { // global keyword
    //   ret = ret.filter(model => _.some(columns, tableLayoutColumn => regexKeyword.test(_.get(model, tableLayoutColumn.key)))) // note: searches all properties, regardless of whether they're meta or not
    // }
    const regexKeyword = new RegExp(sectionTable.keyword.toLowerCase(), 'i')

    // a column with its selected facets and keyword lowercased!
    const columnFacets = columns.map(tableLayoutColumn => ({ column: tableLayoutColumn, keyword: tableLayoutColumn.keyword ? tableLayoutColumn.keyword.toLowerCase() : undefined, facets: tableLayoutColumn.facets.filter(f => f.selected).map(f => f.name) }))

    return ret.filter(model => {
      // TODO: filter if none of these match!
      return columnFacets.every(columnFacet => {
        const { column, facets, keyword } = columnFacet

        const val = _.get(model, column.key)
        if (!val) { // note: this is because we don't have that property (like viewdetails?)
          return true
        }
        const lowerVal = Array.isArray(val) ? val.join() : val.toLowerCase()

        // if there's no checked facets or it matches a checked facet
        return (facets.length === 0 || facets.includes(val)) &&
          // and there's no column keyword or it includes the column keyword
          (!keyword || keyword.length === 0 || lowerVal.includes(keyword))
      }) && (sectionTable.keyword.length === 0 || sectionTable.TableLayout.Columns.some(column => {
        const val = _.get(model, column.key)
        if (val === undefined) { // note: this is because we don't have that property (like viewdetails?)
          return false
        }
        const lowerVal = val ? (Array.isArray(val) ? val.join() : val.toLowerCase()) : ''
        return regexKeyword.test(lowerVal)
      }))
    })
  },
}
