import { MutationTree } from 'vuex'
import { ResponseArray } from '../types'
import { blankOrder, blankOrderArray } from './constants'
import State, { IOrder, ISearchFilters, IOrderDocument } from './types'
import { SelectedShippingCost } from '../cart/types'
import _ from 'lodash'

export const mutations: MutationTree<State> = {
  ordersLoading(state: State) {
    state.error = false
    state.orders = blankOrderArray
  },
  ordersLoaded(state: State, orders: ResponseArray<IOrder>) {
    state.error = false
    state.orders = orders
  },
  ordersError(state) {
    state.error = true
    state.orders = blankOrderArray
  },
  activeOrderSet(state, activeOrder: IOrder) {
    state.activeOrder = activeOrder
  },
  activeOrderError(state) {
    state.activeOrder = blankOrder
  },
  orderAdding(state) {
    state.isSubmittingOrder = true
    state.submissionError = ''
  },
  orderAdded(state, activeOrder: IOrder) {
    state.activeOrder = activeOrder
    state.isSubmittingOrder = false
  },
  orderAddedError(state, errorMsg) {
    state.isSubmittingOrder = false
    state.error = true
    state.submissionError = errorMsg
  },
  orderDocumentsLoading(state) {
    state.orderDocuments = []
    state.documentsLoading = true
  },
  orderDocumentsSet(state, documents: IOrderDocument[]) {
    state.orderDocuments = documents
    state.documentsLoading = false
  },
  orderDocumentError(state, errorMsg) {
    state.documentsLoading = false
    state.documentsError = errorMsg
  },
  setPage: (state, { page }: { page: number }) => {
    state.page = page
  },
  isSubmittingSet(state, isSubmittingOrder) {
    state.isSubmittingOrder = isSubmittingOrder
  },
  currentOrderSet(state, currentOrder: IOrder) {
    state.currentOrder = currentOrder
    state.validationErrors = {}
  },
  currentOrderError(state) {
    state.currentOrder = _.cloneDeep(blankOrder)
  },
  currentOrderReset(state) {
    state.currentOrder = _.cloneDeep(blankOrder)
    state.shippingCosts = []
    state.validationErrors = {}
  },
  validationErrorsSet(state, { key, list }: any) {
    if (Object.keys(state.validationErrors).length === 0) {
      state.validationErrors = { [key]: { ...list } }
    } else {
      state.validationErrors[key] = { ...list }
    }
  },
  validationErrorsError(state) {
    state.validationErrors = {}
  },
  shippingCostSet(state, cost: SelectedShippingCost) {
    if (state.shippingCosts.length === 0) {
      state.shippingCosts.push({
        ShippingStrategyId: cost.ShippingStrategyId,
        ShippingCost: cost.ShippingCost,
      })
    } else {
      const existingCostIndex = state.shippingCosts.findIndex(
        (x) => x.ShippingStrategyId === cost.ShippingStrategyId,
      )
      if (existingCostIndex !== -1) {
        state.shippingCosts[existingCostIndex].ShippingCost = cost.ShippingCost
      } else {
        state.shippingCosts.push({
          ShippingStrategyId: cost.ShippingStrategyId,
          ShippingCost: cost.ShippingCost,
        })
      }
    }
  },
  shippingCostReset(state) {
    state.shippingCosts = []
  },
  searchFiltersSet(state: State, searchFilters: ISearchFilters) {
    state.searchFilters = { ...state.searchFilters, ...searchFilters }
  },
}
