<template>
  <b-container class="detail-container">
    <b-row>
      <b-col class="text-center">
        <b-img v-if="tableLayout.DisplayName.toLocaleLowerCase().includes('replacement') || model.Image" class="p-2" fluid :src="model?.Image?.ImagePath + '?width=300&height=300'" @error="event => _loadErrorImage(event, '300','300')"/>
      </b-col>
    </b-row>
    <template v-if="sectionTable.HasCart && !catalog.CartEnabled">
      <div class="my-3 text-center">
        <a :href="generatePartUrl(model)">Order on our Parts Site</a>
      </div>
    </template>
    <template v-for="column in tableLayout.Columns" >
      <!-- TODO: the "list" has to be from the PARENT -->
      <b-row :key="column.SectionTableLayoutColumnId" class="detail-row"
        v-if="isValuePopulated(model, column)"
      >
        <b-col cols="6" sm="6" tag="h4">
          {{ column.DisplayName }}
        </b-col>
        <b-col cols="6" sm="6" v-if="column.ParentFormatType === formatType.List">
          <component :is="column.ParentFormatValue || 'ul'" v-for="value in getObjectValue(model, column)" :key="value.key">
          <!-- TOOD: how do we getValue on the child now? -->
          <!-- Is the Parent here what? -->
          <!-- List vs ObjectList? -->
          <!-- TODO: just {{value}} may be fore mor than just the String type -->
            <component :is="column.FormatValue">
              <template v-if="column.PropertyType == propertyType.String">{{ value }}</template>
              <template v-else>{{ getObjectValue(value, column) }}</template>
            </component>
          </component>
        </b-col>
        <b-col cols="6" sm="6" v-else>
          {{ getObjectValue(model, column) }}
        </b-col>
      </b-row>
    </template>
    <div class="d-flex justify-content-center my-2" v-if="hasCart">
      <b-button
        v-b-modal="'modalQuantity-' + model.PcnCatalogNumber"
        size="md"
        variant="info background-color-primary"
        class="cart-btn"
        @click="setActiveItem(model)"
      >
        <b-icon icon="cart-plus" size="sm" aria-hidden="true"></b-icon>
      </b-button>
      <modal-select-quantity :modalQuantityVisible="modalQuantityVisible" @closeModal="modalQuantityVisible = false"></modal-select-quantity>
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { Model, TableLayout, TableLayoutColumn, SectionTable } from '../store/product/types'
import MxSite from '@/mixins/site'
import modalSelectQuantity from './cart/modal-cart-select-quantity.vue'
import { CartItem } from '@/store/cart/types'
import { blankCartItem } from '@/store/cart/constants'
import { namespace } from 'vuex-class'

const nsCart = namespace('cart')

@Component({
  components: {
    'modal-select-quantity': modalSelectQuantity,
  },
})
export default class extends Mixins(MxSite) {
  @Prop()
  public model!: Model

  @Prop()
  public tableLayout!: TableLayout

  @Prop()
  public hasCart!: boolean

  @Prop()
  public sectionTable!: SectionTable

  @nsCart.Action('setActiveCartItem')
  private setActiveCartItem: any

  public modalQuantityVisible = false

  public setActiveItem(itemObj: any) {
    const cartItem: CartItem = blankCartItem
    // Convert item type to cartItem type
    const item = itemObj
    cartItem.pcnCatalogNumber = item.PcnCatalogNumber || ''
    cartItem.fingoodTpiMfgMod =
      item.FingoodTpiMfgMod ?? item.Details.Description?.toString()
    cartItem.description =
      item.SapModelDescription != null
        ? item.SapModelDescription
        : item.Descriptions && item.Descriptions.length > 0
          ? item?.Descriptions[0]
          : ''
    cartItem.quantity = 0
    cartItem.price = item.TotalList ?? item.price
    cartItem.totalPrice = item.totalPrice ?? cartItem.quantity * cartItem.price
    cartItem.imgThumbnail = item?.Image?.ImagePath || ''
    cartItem.shippingStrategyId = item.ShippingStrategyId
    this.setActiveCartItem({ cartItem }, { item: cartItem })
    this.modalQuantityVisible = true
  }

  public generatePartUrl(model: Model) : string {
    const currentUrl = window.location.href
    const url = new URL(currentUrl)
    url.host = process.env.VUE_APP_PARTS_SITE_URL?.toString() || ''
    const table = (this.sectionTable as SectionTable)
    const params = new URLSearchParams(url.search)
    params.set('sectionTable', table.DisplayName)
    params.set('catalogNumber', model.PcnCatalogNumber)
    params.set('addToCart', '1')
    url.search = params.toString()
    return url.protocol + '//' + url.host + url.pathname + url.search + url.hash
  }

  // Note: we should probably have the number of columns be responsive based on screensize, but for now we are doing 2
  private numberOfColumns = 2

  get rowCount(): number {
    let ret = 0
    if (this.tableLayout && this.tableLayout.Columns && this.tableLayout.Columns.length > 0) {
      ret = Math.ceil(this.tableLayout.Columns.length / this.numberOfColumns)
    }
    return ret
  }

  private getRowColumns(index: number): TableLayoutColumn[] | null {
    let ret: TableLayoutColumn[] | null = null
    if (this.tableLayout.Columns && this.tableLayout.Columns.length > 0) {
      ret = this.tableLayout.Columns.slice((index - 1) * this.numberOfColumns, index * this.numberOfColumns)
    }
    return ret
  }
}
</script>
<style scoped>
ul {
  padding-left: 0;
  margin-bottom: 0;
}

li {
  text-align: left;
}

.detail-row:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.detail-card {
  margin-top: -20px;
}

.product-details-scroll {
  overflow: auto;
  max-height: 484px;
}

h4,
.h4 {
  font-weight: bold;
  line-height: 1.2;
  font-size: 15px;
  margin-bottom: 0.3rem;
}

h2,
.h2 {
  font-weight: bold;
  line-height: 1.5;
  font-size: 20px;
}

.image-soon{
  height:300px;
  width:300px;
}
</style>
