var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.loading)?_c('div',{staticClass:"checkout-shipping-cost-form"},[(_vm.showOtherCosts)?_c('p',[_vm._v(" "+_vm._s(_vm.shippingStrategy.Description)+" ")]):_vm._e(),_c('div',{staticClass:"my-2"},[_c('b-badge',{staticClass:"background-color-primary right",attrs:{"variant":"primary"}},[_vm._v(_vm._s(_vm.shippingStrategy.DisplayName))]),_vm._l((_vm.shippingStrategy.Costs),function(option){return _c('div',{key:option.ShippingStrategyCostId},[(_vm.selectedShippingCostId === option.ShippingStrategyCostId || _vm.showOtherCosts)?_c('div',{class:[
        'border rounded p-3 mb-3 d-flex align-items-center flex-wrap flex-sm-nowrap',
        _vm.selectedShippingCostId === option.ShippingStrategyCostId ? 'border-primary' : '',
      ]},[_c('input',{staticClass:"mx-3",attrs:{"type":"radio","name":`${_vm.shippingStrategy.ShippingStrategyId}_${option.ShippingStrategyCostId}`,"id":`${_vm.shippingStrategy.ShippingStrategyId}_${option.ShippingStrategyCostId}`,"disabled":_vm.selectedShippingCostId !== option.ShippingStrategyCostId},domProps:{"value":option.ShippingStrategyCostId,"checked":_vm.selectedShippingCostId === option.ShippingStrategyCostId}}),_c('div',{class:[
          ' d-flex align-items-center',
          _vm.selectedShippingCostId !== option.ShippingStrategyCostId ? 'text-muted' : '',
        ],attrs:{"for":option.ShippingStrategyCostId}},[_c('b',{staticClass:"mr-5"},[_vm._v(_vm._s(_vm.formatCost(option.ShippingCost))+" ")]),_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(option.Description)+" ")])])]):_vm._e()])})],2),_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(0),_c('span',{staticClass:"text-muted small"},[_vm._v(" Shipping method and cost are automatically determined based on total cost of order. "),_c('b-button',{attrs:{"variant":"link p-0","size":"sm"},on:{"click":_vm.toggleShowMoreOptions}},[_vm._v(_vm._s(_vm.showOtherCosts ? 'Hide Costs' : 'See Costs'))])],1)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',[_c('i',{staticClass:"fa fa-info-circle mr-2 info-icon color-primary"})])
}]

export { render, staticRenderFns }