<template>
  <b-card no-body>
    <b-card-header class="py-1" header-tag="h3">
      <slot name="categoryHeader" v-bind="category"></slot>
      {{ category.DisplayName }}
    </b-card-header>
    <b-card-body class="p-1 w-auto">
      Preview Disabled for the Category Level.
    </b-card-body>
  </b-card>

</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'

import LoadingText from '@/components/utility/loading-text.vue'

import MxSite from '@/mixins/site'

import { Category } from '@/store/model-viewer/types'

@Component({
  components: {
    'loading-text': LoadingText,
  },
})
export default class extends MxSite {
  @Prop()
  public category!: Category

  @Prop({ default: () => [] })
  public fields!: any[]
}
</script>
