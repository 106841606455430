<template>
  <b-modal
      v-model="show"
      id="address-verification-modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @cancel="close()"
      size="lg"
      @close="close()">
    <template #modal-header><h4>Please confirm the addresses.</h4></template>
    <div class="mb-2">
        We found a better match for the addresses provided. Do you want to use the corrected address?
    </div>
    <div class="ml-3" v-if="validatedShipToAddress">
      <div class="row">
        <div class="col-md-12">
          <b-form-group>
            <template #label><b v-if="useSameAddress">Bill To/</b><b>Ship To:</b></template>
            <b-form-radio-group stacked label="?"
              v-model="selectedShipToAddress"
              class="row"
            >
              <b-form-radio :value="validatedShipToAddress" class="col-5 ml-3">
                <b-card no-body>
                  <b-card-header
                    :header-bg-variant="selectedShipToAddress == validatedShipToAddress ? 'primary': 'white'"
                    :header-text-variant="selectedShipToAddress == validatedShipToAddress ? 'white': 'dark'"
                  >
                    Yes, use the corrected address
                  </b-card-header>
                  <b-card-body class="rounded border p-2" :body-border-variant="selectedShipToAddress == validatedShipToAddress ? 'primary': 'white'">
                    <div>{{ validatedShipToAddress.Address1 }} {{ validatedShipToAddress.Address2 ?? '' }}</div>
                    <div>{{ validatedShipToAddress.City }}, {{ validatedShipToAddress.State }}, {{ validatedShipToAddress.Country }}</div>
                    <div>{{ validatedShipToAddress.Zip }}</div>
                  </b-card-body>
                </b-card>
              </b-form-radio>
              <b-form-radio :value="shipToAddress" class="col-5 mr-3">
                <b-card no-body>
                  <b-card-header
                    :header-bg-variant="selectedShipToAddress == shipToAddress ? 'primary': 'white'"
                    :header-text-variant="selectedShipToAddress == shipToAddress ? 'white': 'dark'"
                  >
                    No, use the original address
                  </b-card-header>
                  <b-card-body class="rounded border p-2" :body-border-variant="selectedShipToAddress == shipToAddress ? 'primary': 'white'">
                    <div>{{ shipToAddress.Address1 }} {{ shipToAddress.Address2 ?? '' }}</div>
                    <div>{{ shipToAddress.City }}, {{ shipToAddress.State }}, {{ shipToAddress.Country }}</div>
                    <div>{{ shipToAddress.Zip }}</div>
                  </b-card-body>
                </b-card>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="ml-3 mt-2" v-if="!useSameAddress && validatedBillToAddress">
      <div class="row">
        <div class="col-md-12">
          <b-form-group>
            <template #label><b>Bill To:</b></template>
            <b-form-radio-group stacked label="?"
              v-model="selectedBillToAddress"
              class="row"
            >
              <b-form-radio :value="validatedBillToAddress" class="col-5 ml-3">
                <b-card no-body>
                  <b-card-header
                    :header-bg-variant="selectedBillToAddress == validatedBillToAddress ? 'primary': 'white'"
                    :header-text-variant="selectedBillToAddress == validatedBillToAddress ? 'white': 'dark'"
                  >
                    Yes, use the corrected address
                  </b-card-header>
                  <b-card-body class="rounded border p-2" :body-border-variant="selectedBillToAddress == validatedBillToAddress ? 'primary': 'white'">
                    <div>{{ validatedBillToAddress.Address1 }} {{ validatedBillToAddress.Address2 ?? '' }}</div>
                    <div>{{ validatedBillToAddress.City }}, {{ validatedBillToAddress.State }}, {{ validatedBillToAddress.Country }}</div>
                    <div>{{ validatedBillToAddress.Zip }}</div>
                  </b-card-body>
                </b-card>
              </b-form-radio>
              <b-form-radio :value="billToAddress" class="col-5 mr-3">
                <b-card no-body>
                  <b-card-header
                    :header-bg-variant="selectedBillToAddress == billToAddress ? 'primary': 'white'"
                    :header-text-variant="selectedBillToAddress == billToAddress ? 'white': 'dark'"
                  >
                    No, use the original address
                  </b-card-header>
                  <b-card-body class="rounded border p-2" :body-border-variant="selectedBillToAddress == billToAddress ? 'primary': 'white'">
                    <div>{{ billToAddress.Address1 }} {{ billToAddress.Address2 ?? '' }}</div>
                    <div>{{ billToAddress.City }}, {{ billToAddress.State }}, {{ billToAddress.Country }}</div>
                    <div>{{ billToAddress.Zip }}</div>
                  </b-card-body>
                </b-card>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <b-button
        type="button" variant="primary"
        @click.prevent="onAddressConfirmation">
        Continue
      </b-button>
      <b-button
        type="button"
        class="btn btn-secondary"
        @click.prevent="close()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>
<script lang="ts">
import MxSite from '@/mixins/site'
import { Component, Mixins, Prop, Emit } from 'vue-property-decorator'
import { IOrderAddress } from '@/store/orders/types'

@Component({})
export default class AddressVerification extends Mixins(MxSite) {
  @Prop() useSameAddress!: boolean

  @Prop() show!: boolean

  @Prop() shipToAddress!: IOrderAddress

  @Prop() billToAddress!: IOrderAddress

  @Prop({ default: null }) validatedShipToAddress!: IOrderAddress

  @Prop({ default: null }) validatedBillToAddress!: IOrderAddress

  @Prop() public onSelected!: (shipToAddress: IOrderAddress, billToAddress: IOrderAddress | null) => void

  public selectedShipToAddress: IOrderAddress | null = null

  public selectedBillToAddress: IOrderAddress | null = null

  public mounted() {
    this.selectedShipToAddress = this.validatedShipToAddress
      ? this.validatedShipToAddress
      : this.shipToAddress

    this.selectedBillToAddress = this.validatedBillToAddress
      ? this.validatedBillToAddress
      : this.billToAddress
  }

  onAddressConfirmation() {
    this.onSelected(this.selectedShipToAddress!, this.selectedBillToAddress)
  }

  @Emit('close')
  public close() { }
}
</script>
