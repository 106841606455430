import { MutationTree } from 'vuex'
import { ArrayLoading } from '../types'
import { loadingEditor, loadingEntityObject } from './constants'
import State, { EntityObjectLookup, EditorLookup, EntityObject, Editor } from './types'

export const mutations: MutationTree<State> = {
  entityObjectsLoaded(state, entityObjects: ArrayLoading<EntityObject>) {
    state.Error = false
    state.entityObjects = entityObjects
  },
  entityObjectsError(state) {
    state.Error = true
    state.entityObjects = loadingEntityObject
  },
  editorsLoaded(state, editors: ArrayLoading<Editor>) {
    state.Error = false
    state.editors = editors
  },
  editorsError(state) {
    state.Error = true
    state.editors = loadingEditor
  },
  setEntityObjectLookup(state, entityObjectLookup: EntityObjectLookup) {
    state.entityObjectLookup = entityObjectLookup
  },
  setEditorLookup(state, editorLookup: EditorLookup) {
    state.editorLookup = editorLookup
  },
  setPreloaded(state, { value }) {
    state.preloaded = value
  },
}
