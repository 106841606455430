<template>
  <div class="shopping-cart">
    <div class="action-bar">
      <div class="selected-info">
        <span class="items">
          Items (<b>{{ totalSelectedQty }}</b
          >)
        </span>
        <span class="total-price"
          >Sub Total: <b>${{ totalSelectedPrice.toFixed(2) }}</b></span
        >
      </div>
      <div class="actions">
        <button
          v-if="lineActions.includes(LineActionEnum.Delete)"
          class="btn-flat btn-red mobile-p-1"
          :disabled="selectedItems.length === 0"
          @click="deleteSelectedItems()"
        >
          <span class="hide-for-mobile mr-2">Delete Selected Lines</span
          ><span class="fa fa-trash"></span>
        </button>
        <button
          v-if="lineActions.includes(LineActionEnum.Duplicate)"
          class="btn-flat"
          @click="onDuplicateItems()"
          :disabled="selectedItems.length === 0"
        >
          <span class="hide-for-mobile mr-2">Duplicate Selected Lines</span>
          <b-icon icon="files" />
        </button>
        <button
          v-if="actionText"
          class="btn-flat color-primary mobile-p-1"
          @click="handleAction"
        >
          <span class="hide-for-mobile mr-2">{{ actionText }}</span>
          <span class="fa fa-plus"></span>
        </button>
      </div>
    </div>
    <item-table
      :fields="fields"
      :items="items"
      :selectedRows="selectedItems"
      :selectedItems="selectedItems"
      :selectAll="selectAll"
      :handleDelete="deleteItem"
      :handleCheckboxChange="updateCheckBox"
      :handleSelectAll="selectAllItems"
      :handleQtyChange="changeQty"
      :handleBrowse="handleBrowse"
      :handleDuplicate="onDuplicate"
      :handleSearch="handleSearch"
      :handleSelect="handleSelect"
      :searchResult="modelSearchResults"
      :lineActions="lineActions"
    />
    <div class="checkout-bar">
      <button
        v-if="showCheckout"
        class="btn-checkout"
        @click="checkout()"
        :disabled="totalSelectedQty === 0"
      >
        <div>
          Subtotal ({{ totalSelectedQty }} items) :
          <b>${{ totalSelectedPrice.toFixed(2) }}</b>
        </div>
        <div>Checkout <span class="fa fa-arrow-right ml-2"></span></div>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import ItemTable from '@/components/table/item-table.vue'
import MxSite from '@/mixins/site'
import { Cart, CartItem } from '@/store/cart/types'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import type { VisibleModelSearchRequest } from '@/store/search/types'
import { blankVisibleModelSearchRequest } from '@/store/search/constants'
import { Model } from '@/store/product/types'
import { ResponseArray } from '@/store/types'
import { blankResponseArray } from '@/store/constants'
import { blankCartItem, LineAction } from '@/store/cart/constants'

const nsCart = namespace('cart')
const nsSearch = namespace('search')
const nsMultiplier = namespace('multiplier')

@Component({
  components: {
    ItemTable,
  },
})
export default class CartItems extends Mixins(MxSite) {
  @nsCart.Getter('cart')
  public cart!: Cart

  @nsCart.Action('deleteCartItem')
  private deleteCartItem: any

  @nsCart.Action('setActiveCartItem')
  private setActiveCartItem: any

  @nsCart.Action('updateCartItemQty')
  private updateCartItemQty: any

  @nsCart.Action('saveCartItem')
  public saveCartItem: any

  @nsCart.Action('deleteCartItems')
  private deleteCartItems: any

  @nsSearch.Action('searchVisibleModels')
  private searchVisibleModels: any

  @nsSearch.State('visibleModelsResults')
  public visibleModelsResults!: ResponseArray<Model>

  @nsMultiplier.Getter('getMultiplierPrice')
  private getMultiplierPrice: any

  @nsMultiplier.State('customerMultipliers')
  private customerMultipliers: any

  public items: CartItem[] = []

  @Prop()
  public actionText!: string

  @Prop()
  public showCheckout!: boolean

  @Prop({ default: () => [LineAction.Delete, LineAction.Duplicate] })
  public lineActions!: LineAction[]

  public LineActionEnum = LineAction

  public fields = [
    {
      key: 'details',
      label: 'Toggle Details',
      thClass: 'responsive-col-hide text-left',
      tdClass: 'responsive-col-hide text-left',
      thStyle: { width: '10%' },
    },
    {
      key: 'checkbox',
      label: '',
      thClass: 'text-center item-th',
      tdClass: 'text-center',
      thStyle: { width: '5%' },
    },
    {
      key: 'imgThumbnail',
      label: '',
      thClass: 'responsive-col text-left',
      tdClass: 'responsive-col text-left',
      thStyle: { width: '5%' },
    },
    {
      key: 'browseModel',
      label: '',
      thClass: 'text-center item-th',
      tdClass: 'text-center',
      thStyle: { width: 'auto' },
    },
    {
      key: 'pcnCatalogNumber',
      label: 'PCN',
      thClass: 'text-left',
      tdClass: 'responsive-col-size text-left',
      thStyle: 'resp-th-30',
    },
    {
      key: 'fingoodTpiMfgMod',
      label: 'Model #',
      thClass: 'responsive-col text-left',
      tdClass: 'responsive-col text-left',
      thStyle: 'resp-th-40',
    },
    {
      key: 'description',
      label: 'Description',
      thClass: 'responsive-col text-left',
      tdClass: 'responsive-col text-left',
      thStyle: { width: '15%' },
    },
    {
      key: 'inventory',
      label: 'Inventory',
      thClass: 'responsive-col text-left',
      tdClass: 'responsive-col text-left',
      thStyle: { width: '15%' },
    },
    {
      key: 'price',
      label: 'Unit Price',
      thClass: 'responsive-col text-right',
      tdClass: 'responsive-col text-right',
      thStyle: { width: '10%' },
      formatter: (val: any) => {
        if (val == null) {
          return ''
        } else if (isNaN(val)) {
          return val
        } else {
          return `$${parseFloat(val).toFixed(2)}`
        }
      },
    },
    {
      key: 'quantity',
      label: 'Qty',
      thClass: 'responsive-col text-right',
      tdClass: 'responsive-col text-right',
      thStyle: { width: '10%', minWidth: '80px' },
    },
    {
      key: 'totalPrice',
      label: 'Total Price',
      thClass: 'text-right',
      tdClass: 'responsive-col-size text-right',
      thStyle: 'resp-th-20',
      formatter: (val: any) => {
        if (val == null) {
          return ''
        } else if (isNaN(val)) {
          return val
        } else {
          return `$${parseFloat(val).toFixed(2)}`
        }
      },
    },
    {
      key: 'actions',
      label: '',
      thClass: 'responsive-col text-left',
      tdClass: 'responsive-col text-left',
      thStyle: { width: 'auto' },
    },
  ]

  public selectAll = false

  public selectedRows: CartItem[] = []
  public modelSearchResults: ResponseArray<CartItem> = { ...blankResponseArray, loading: false }

  public get isInputDisabled() {
    return (
      (!this._isCustomerSite(this.site.Name) &&
        !this._isCustomerAdminSite(this.role ?? '') &&
        !this._isSalesRepSite(this.site.Name))
    )
  }

  created() {
    if (this.isInputDisabled) {
      this.items = [...this.cart.items]
    } else {
      this.items = [...this.cart.items, blankCartItem]
    }
    this.selectedRows = [...this.items]
    this.selectAll = true
  }

  @Watch('cart.items', { deep: true })
  public itemsUpdated(newArr: CartItem[]) {
    if (this.isInputDisabled) {
      this.items = [...newArr]
    } else {
      this.items = [...newArr, blankCartItem]
    }
    this.selectedRows = [...newArr]
    this.selectAll = true
  }

  @Watch('visibleModelsResults', { deep: true })
  public visibleModelsResultsUpdated(results: ResponseArray<Model>) {
    const resultItems: CartItem[] = []
    results.List.forEach(async (model) => {
      const price = this.getMultiplierPrice(model.PcnCatalogNumber, model.ProductHierarchy, model.TotalList)
      resultItems.push({
        pcnCatalogNumber: model.PcnCatalogNumber || '',
        SapModelDescription: model.SapModelDescription || '',
        fingoodTpiMfgMod: model.FingoodTpiMfgMod || '',
        description: model.SapModelDescription || '',
        quantity: 1,
        price: price || 0,
        totalPrice: price || 0,
        imgThumbnail: model.Image?.ImagePath || '',
        shippingStrategyId: model.ShippingStrategyId || 0,
        originalPrice: parseFloat(model.TotalList) || 0,
        productHierarchy: model.ProductHierarchy || '',
      })
    })
    this.modelSearchResults = { ...results, List: resultItems }
  }

  @Watch('customerMultipliers', { deep: true })
  public multiplierChanged() {
    this.items.forEach((item) => {
      if ((item.originalPrice || 0) > 0) {
        const price = this.getMultiplierPrice(item.pcnCatalogNumber, item.productHierarchy, item.originalPrice)
        item.price = price
        item.totalPrice = price * item.quantity
      }
    })
  }

  public get selectedItems(): CartItem[] {
    return this.items.filter((item) => this.selectedRows.includes(item) && item.pcnCatalogNumber)
  }

  public get totalSelectedQty(): number {
    return this.selectedItems.reduce((total, item) => total + item.quantity, 0)
  }

  public get totalSelectedPrice(): number {
    return this.selectedItems.reduce(
      (total, item) => total + item.quantity * item.price,
      0,
    )
  }

  public checkout() {
    this.$router.push({ name: 'checkout' })
  }

  public deleteSelectedItems() {
    const confirmedDelete = confirm(
      'Are you sure you want to delete all items?',
    )
    if (confirmedDelete) {
      this.deleteCartItems(this.selectedItems)
      this.selectedItems.forEach((item) => {
        const index = this.items.findIndex((i) => i === item)
        if (index !== -1) {
          this.items.splice(index, 1)
        }
      })
      this.selectedRows = []
      this.selectAll = false
    }
  }

  public deleteItem(item: CartItem) {
    if (confirm('Do you really want to delete?')) {
      const index = this.items.findIndex(
        (x) => x.id === item.id,
      )
      this.items.splice(index, 1)
      this.deleteCartItem(item)
    }
  }

  public updateCheckBox(item: CartItem) {
    const index = this.selectedRows.findIndex(
      (x) => x.id === item.id,
    )
    if (index >= 0) {
      this.selectedRows.splice(index, 1)
    } else {
      this.selectedRows.push(item)
    }
  }

  public selectAllItems(event: any) {
    this.selectAll = event.target.checked
    if (this.selectAll) {
      this.selectedRows = [...this.items]
    } else {
      this.selectedRows = []
    }
  }

  public changeQty(event: any, item: CartItem) {
    const newQuantity = Number(event.target.value)
    item.quantity = newQuantity
    this.updateCartItemQty(item)
  }

  public handleAction() {
    this.$emit('handleAction')
  }

  public handleBrowse(cartItem: CartItem) {
    this.$emit('handleBrowse')
    this.setActiveCartItem({ cartItem })
  }

  public handleSelect(cartItem: CartItem) {
    this.setActiveCartItem({ cartItem }).then(() => {
      this.saveCartItem({ cartItem })
        .catch(() => {
          this.toastError('Fail to add item to Order')
        })
    })
  }

  public handleSearch(keyword: string) {
    const search: VisibleModelSearchRequest = {
      ...blankVisibleModelSearchRequest,
      Keyword: keyword,
    }
    this.searchVisibleModels(search)
  }

  public onDuplicate(cartItem: CartItem) {
    this.saveCartItem({ cartItem: { ...cartItem, id: '' } })
  }

  public onDuplicateItems() {
    this.selectedRows.forEach(cartItem => {
      this.saveCartItem({ cartItem: { ...cartItem, id: '' } })
    })
  }
}
</script>

<style scoped lang="scss">
.shopping-cart {
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
}
.action-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px 4px 8px;
  background-color: #f4f7f9;
  position: sticky;
  top: 0;
  z-index: 10;

  .selected-info {
    display: flex;
    align-items: center;
    gap: 20px;

    .items {
      width: 15vh;
      text-align: center;
      border-right: 1px solid #a6c2ef;
    }
  }
  .actions {
    display: flex;
  }
}

.checkout-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px;
}

.btn-checkout {
  background-color: #0b67b2;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  border: none;
  padding: 8px 12px 8px 12px;
  border-radius: 3px;
}

.btn-checkout:hover {
  background-color: #0f8aee;
}

.btn-checkout:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  background-color: gray;
}
</style>
