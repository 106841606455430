<template>
  <div v-if="section && sectionTab">
    <b-card :class="'col-12 col-sm-12'" no-body class="p-0" v-for="sectionItem in sectionTab.Items" :key="'sectionItem-card-' + sectionItem.key">
      <b-card-header :key="'sectionItem-header-' + sectionItem.key"
        class="py-0 px-1" header-tag="h3" v-if="sectionItem.DisplayName">
        {{sectionItem.DisplayName}}
      </b-card-header>
      <b-card-body :key="'sectionTable-body-' + sectionItem.key"
        v-if="sectionItem.SectionTableId" class="p-1 w-auto"
      >
        <section-table :product="product" :sectionTable="sectionItem" :key="'sectionTable-table-' + sectionItem.key"></section-table>
      </b-card-body>

      <b-card-body :key="'sectionList-body-' + sectionItem.key"
        v-if="sectionItem.SectionItemType == 'List' && sectionItem.PropertyName && product[sectionItem.PropertyName] && product[sectionItem.PropertyName].length > 0" class="p-1 w-auto"
      >
        <ul class='text-list'>
          <template v-for="(listItem, listIndex) in product[sectionItem.PropertyName]">
          <li :key="'list-item-' + sectionItem.key + '-' + listIndex" v-if="listItem.trim() !== ''">
            {{listItem}}
          </li>
          </template>
        </ul>
      </b-card-body>
      <b-card-body :key="'sectionList-body-' + sectionItem.key"
        v-if="sectionItem.SectionItemType == 'List' && sectionItem.PropertyName == 'Items' && sectionItem.Items && sectionItem.Items.length > 0" class="p-1 w-auto"
      >
        <ul class='text-list'>
          <template v-for="(listItem, listIndex) in sectionItem.Items">
            <li :key="'list-item-' + sectionItem.key + '-' + listIndex" v-if="listItem.trim() !== ''">
              {{listItem}}
            </li>
          </template>
        </ul>
      </b-card-body>
      <b-card-body :key="'sectionDocument-body-' + sectionItem.key" v-if="sectionItem.SectionItemType == 'Document'" class="p-1 w-auto">
        <img src="//www.weebly.com/weebly/images/file_icons/pdf.png" width="36" height="36" style="float: left; position: relative; left: 0px; top: 0px; margin: 0 15px 15px 0; border: 0;">
        <b-link :href="sectionItem.DocumentPath" target="new">
          Download File
        </b-link>
      </b-card-body>
      <b-card-body :key="'sectionImage-body-' + sectionItem.key"
        v-if="sectionItem.SectionItemType == 'Image'" class="p-1 w-auto text-center"
      >
        <b-img fluid :src="sectionItem.ImagePath + '?width=' + sectionItem.Width + '&height=' + sectionItem.Height" :alt="'' + sectionItem.ImageId" :width="sectionItem.Width" :height="sectionItem.Height"></b-img>
        <b-card-text>
          {{sectionItem.Caption}}
        </b-card-text>
      </b-card-body>
      <b-card-body :key="'sectionRichText-body-' + sectionItem.key"
        v-if="sectionItem.SectionItemType == 'RichText'" class="p-1 w-auto"
      >
        <div v-html="sectionItem.RichText"></div>
      </b-card-body>
      <b-card-body :key="'sectionSpinSirv-body-' + sectionItem.key"
        v-if="sectionItem.SectionItemType == 'SpinSirv'" class="p-1 w-auto"
      >
        <div class="Sirv" :data-src="sectionItem.Url"></div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Product, Section, SectionTab, SideBarItem } from '../store/product/types'
import SectionTable from '@/components/section-table-sm.vue'
import LoadingText from '@/components/utility/loading-text.vue'

const nsProduct = namespace('product')

@Component({
  components: {
    'section-table': SectionTable,
    'loading-text': LoadingText,
  },
})
export default class extends Vue {
  @Prop()
  public product!: Product | null

  @Prop()
  public section!: Section | null

  @Prop()
  public sectionTab!: SectionTab | null

  @nsProduct.Mutation('clearActiveModel')
  private clearActiveModel: any

  @nsProduct.State('sideBarModel')
  private sideBarModel!: SideBarItem
}
</script>

<style scoped>
h3 {
  line-height: 1.5;
  font-size: 20px;
}

.tab-section-name {
    font-weight: 700;
    border: 0;
    padding: 15px 15px;
}
</style>
