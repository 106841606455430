<template>
  <div>
    <div class="d-flex align-items-center">
      <h5 class="my-2">
        <b>Order Review</b>
      </h5>
      <b-button variant="link" @click="isExpanded = !isExpanded" :aria-expanded="isExpanded" aria-controls="order-items" class="ml-auto text-dark p-0">
        <b-icon :icon="isExpanded ? 'chevron-up' : 'chevron-down'" />
      </b-button>
    </div>
    <div class="mb-3">
      <span>{{ totalQty }} item(s) in cart</span>
    </div>
    <b-collapse id="order-items" v-model="isExpanded">
      <div v-for="(groupedItems, shippingStrategyId) in itemsByShippingStrategy" :key="shippingStrategyId">
        <b-badge variant="primary" class="background-color-primary w-100">{{ shippingStrategyId }}</b-badge>
        <div v-for="(item, index) in groupedItems" :key="index" class="order-item d-flex align-items-center border-bottom pb-3 mb-2">
          <div class="item-img mr-2 text-center">
            <b-img :src="item.imgThumbnail" alt="Item Image" fluid v-if="item.imgThumbnail" />
          </div>
          <div class="item-details flex-grow-1">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <b-row>
                <b-col class="col-12">{{ item.pcnCatalogNumber }} - {{ item.fingoodTpiMfgMod }}</b-col>
                <b-col class="col-12 small">{{ item.description }}</b-col>
              </b-row>
              <b-button variant="link" size="lg" class="m-1 p-0" @click="deleteItem(item)">
                <b-icon icon="x" variant="danger" />
              </b-button>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <b-button variant="outline-light  text-dark" size="sm" class="mr-1" @click="incrementQuantity(item)">
                  <b-icon icon="plus" />
                </b-button>
                <span class="mx-3">{{ item.quantity }}</span>
                <b-button variant="outline-light  text-dark" size="sm" class="ml-1" @click="decrementQuantity(item)">
                  <b-icon icon="dash" />
                </b-button>
              </div>
              <span class="ml-2"><b>{{ formatPrice(item.price * item.quantity) }}</b></span>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { BIcon, BImg } from 'bootstrap-vue'
import { CartItem } from '@/store/cart/types'

@Component({
  components: { BIcon, BImg },
})
export default class OrderReview extends Vue {
  @Prop()
  public cartItems!: CartItem[]

  @Prop()
  public handleDelete!: (item: CartItem) => void

  @Prop()
  public handleQtyChange!: (item: CartItem) => void

  public items: CartItem[] = []
  public itemsByShippingStrategy: Record<number, CartItem[]> = {}

  created() {
    this.items = [...this.cartItems]
    this.groupItemsByShippingStrategy()
  }

  public get totalQty(): number {
    return this.items.reduce((total, item) => total + item.quantity, 0)
  }

  public isExpanded = true

  public deleteItem(item: CartItem) {
    if (confirm('Do you really want to delete?')) {
      const index = this.items.findIndex(
        (x) => x.pcnCatalogNumber === item.pcnCatalogNumber,
      )
      this.items.splice(index, 1)
      this.handleDelete(item)
    }
    this.groupItemsByShippingStrategy()
  }

  public incrementQuantity(item: CartItem) {
    item.quantity++
    this.handleQtyChange(item)
  }

  public decrementQuantity(item: CartItem) {
    if (item.quantity > 1) {
      item.quantity--
      this.handleQtyChange(item)
    }
  }

  public formatPrice(price: number) {
    return `$${price.toFixed(2)}`
  }

  groupItemsByShippingStrategy() {
    this.itemsByShippingStrategy = this.items.reduce((groups, item) => {
      const shippingStrategyId = item.shippingStrategyId ?? 'Parts'

      if (!groups[shippingStrategyId]) {
        groups[shippingStrategyId] = []
      }

      groups[shippingStrategyId].push(item)
      return groups
    }, {})
  }
}
</script>

<style scoped>
.rotate-icon {
  transform: rotate(180deg);
}

#order-items {
  height: 30vh;
  overflow-y: auto;
}

.item-icon {
  font-size: 2rem;
}

.item-img {
  width: 60px;
  min-width: 60px;
}

.item-details {
  height: 100%;
}
</style>
