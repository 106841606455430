import { GetterTree } from 'vuex'
import { RootState } from '../types'
import State, { ICountry, ICountryState } from './types'

export const getters: GetterTree<State, RootState> = {
  countries(state): ICountry[] {
    const { countries } = state
    return countries || []
  },
  countryStates(state): ICountryState[] {
    const { countryStates } = state
    return countryStates || []
  },
}
