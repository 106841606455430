export const SiteRoles = {
  customerAdmin: 'orders-admin',
  customerPortal: 'customerportal',
  salesRepPortal: 'orders',
}

export function isCustomerAdminSite(role: string | undefined) {
  return role === SiteRoles.customerAdmin
}

export function isCustomerSite(role: string | undefined) {
  return role === SiteRoles.customerPortal
}

export function isSalesRepSite(role: string | undefined) {
  return role === SiteRoles.salesRepPortal
}
