import { GetterTree } from 'vuex'
import State from './types'
import { RootState, EntityLevel } from '../types'

export const getters: GetterTree<State, RootState> = {
  getEntityLevelEffectivePermission: ({ editorLookup: { category, productClass, manufacturingSeries, product } }: State) => ({ entityLevel }: { entityLevel: EntityLevel }) => {
    if (!entityLevel || entityLevel === -1) {
      return product.EffectivePermission
    }
    switch (entityLevel) {
      case EntityLevel.Product:
        return product.EffectivePermission
      case EntityLevel.ManufacturingSeries:
        return manufacturingSeries.EffectivePermission
      case EntityLevel.ProductClass:
        return productClass.EffectivePermission
      case EntityLevel.Category:
        return category.EffectivePermission
    }
  },

}
