<template>
  <footer v-if="site.Name === 'parts'" class="container-fluid">
    <hr/>
    <div class="centered">
      <a href="https://www.tpicorp.com/online-purchases-terms--conditions.html"
       class="footer-link"
       target="_blank">
        TPI Corporation Terms and Conditions for Replacement Parts and Online Purchases
      </a>
    </div>
  </footer>
  <footer class="container-fluid" v-else-if="_isCustomerSite(site.Name)">
    <hr/>
    <div class="centered">
      <div>
        Supported browsers:
      </div>
      <div>
        <a href="https://www.google.com/chrome" target="_blank">
          <img :src="chromeIcon" alt="chrome" class="logo" />
        </a>
        <a href="https://www.microsoft.com/en-us/edge" target="_blank">
          <img :src="edgeIcon" alt="edge" class="logo" />
        </a>
        <a href="https://www.apple.com/safari/" target="_blank">
          <img :src="safariIcon" alt="safari" class="logo" />
        </a>
        <a href="https://www.mozilla.org/en-US/firefox/" target="_blank">
          <img :src="firefoxIcon" alt="firefox" class="logo" />
        </a>
        <a href="https://www.opera.com/" target="_blank">
          <img :src="operaIcon" alt="opera" class="logo" />
        </a>
      </div>
    </div>
  </footer>
</template>
<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'

import MxSite from '@/mixins/site'

@Component({
})

export default class extends Mixins(MxSite) {
  public chromeIcon = require('@/assets/images/chrome_icon.png')
  public edgeIcon = require('@/assets/images/edge_icon.png')
  public safariIcon = require('@/assets/images/safari_icon.png')
  public firefoxIcon = require('@/assets/images/firefox_icon.png')
  public operaIcon = require('@/assets/images/opera_icon.png')
}
</script>

<style>
.centered {
  text-align: center;
}

.footer-link {
  font-size: 12px;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

a.footer-link:visited {
  color: gray;
}

.logo {
  height: 32px;
  margin: 4px;
}
</style>
