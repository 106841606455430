import { ArrayLoading } from '@/store/types'

import { loading, none } from '@/store/constants'

import {
  ActiveSchedule, Schedule,
} from './types'

import { active, blankEditBase } from '@/store/editor/constants'

import _ from 'lodash'

export const blankSchedule: Schedule = _.merge({
  ScheduleId: -1,
  Name: '',
  Frequency: '',
  FrequencyValue: 0,
  FrequencyDate: new Date(),
  Enabled: false,
  ParameterJson: '', // confirm what another attributes need to instance here
}, blankEditBase)

export const loadingSchedule: ArrayLoading<Schedule> = _.assign([], loading)

export const noneSchedule: Schedule = _.assign({}, blankSchedule, none)

export const activeScheduleNone: ActiveSchedule = _.assign({}, noneSchedule, active, none)
