<template>
  <div>
    <b-form-file
      ref="fileUploadForm"
      multiple
      v-model="inputFiles"
      :state="Boolean(inputFiles)"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
      @input="filesSelected"
      accept="application/pdf,.csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/*"
    ></b-form-file>

    <div class="container mb-3" v-if="selectedDocumentFiles && selectedDocumentFiles.length > 0">
      <b-row>
        <b-col md="12" v-for="(fileItem, index) in selectedDocumentFiles" :key="index">
          <b-row class="p-1 mt-1 mr-1 border border-dark rounded">
            <b-col class="pt-1" md="10">
              <i :class="getFileIcon(fileItem.type)" aria-hidden="true"></i>
              {{ fileItem.name }} {{ fileSize(fileItem.size) }}
            </b-col>
            <b-col class="text-right" md="2">
              <b-input-group size="sm">
                <b-input-group-append>
                  <b-input-group-text @click="removeFileAt(index)" style="cursor:pointer">
                    <b-icon-trash variant="danger" />
                  </b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Mixins, Emit } from 'vue-property-decorator'

import MxEditor from '@/mixins/editor'

@Component
export default class extends Mixins(MxEditor) {
  @Prop({ default: [] })
  public value!: File[]

  private fileUploadForm: any
  public inputFiles: File[] = []

  public get selectedDocumentFiles(): File[] {
    return this.value
  }

  public fileSize(size: number): string {
    if (!+size) return '0 bytes'
    const decimals = 2
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['bytes', 'kb', 'mb']
    const i = Math.floor(Math.log(size) / Math.log(k))

    return `${parseFloat((size / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`
  }

  public getFileIcon(fileType: string) {
    if (fileType === 'application/pdf') {
      return 'fa fa-file-pdf-o'
    } else if (fileType.includes('word')) {
      return 'fa fa-file-word-o'
    } else if (fileType.startsWith('image/')) {
      return 'fa fa-file-image-o'
    } else if (fileType.includes('excel') || fileType.includes('sheet')) {
      return 'fa fa-file-excel-o'
    } else if (fileType.startsWith('text/')) {
      return 'fa fa-file-text-o'
    } else if (fileType === 'application/zip' || fileType === 'application/x-compressed') {
      return 'fa fa-file-archive-o'
    } else {
      return 'fa fa-file-o'
    }
  }

  @Emit('input')
  public removeFileAt(index: number) {
    return this.selectedDocumentFiles.filter((s, i) => i !== index)
  }

  @Emit('input')
  public filesSelected(): File[] {
    let files:File[] = []
    if (this.selectedDocumentFiles && this.selectedDocumentFiles.length > 0) {
      files = files.concat(this.selectedDocumentFiles)
    }
    if (this.inputFiles && this.inputFiles.length > 0) {
      for (const file of this.inputFiles) {
        if (file.size > 15 * 1024 * 1024) {
          alert(`"${file.name} ${this.fileSize(file.size)}" file size exceeds the maximum of 15mb`)
        } else {
          files.push(file)
        }
      }
    }
    this.fileUploadForm = this.$refs.fileUploadForm
    this.fileUploadForm.reset()
    return files
  }

  @Emit('close')
  private close() {
    // do nothing but Emit.
  }
}

</script>
<style scoped>

</style>
