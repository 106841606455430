import { ArrayLoading } from '@/store/types'
import { loading } from '@/store/constants'

import {
  Editor,
  EditorField,
  EditorSection,
  EffectiveRolePermission,
  EntityObject,
  EntityProperty,
} from './types'

import _ from 'lodash'

export const loadingEntityObject: ArrayLoading<EntityObject> = _.assign([], loading)
export const loadingEditor: ArrayLoading<Editor> = _.assign([], loading)
export const loadingEditorSection: ArrayLoading<EditorSection> = _.assign([], loading)

// because you can't use before exists!
export const blankEntityObject: EntityObject = {
  EntityObjectId: -1,
  EntityName: '',
  DisplayName: '',
  DisplayDescription: '',
  Properties: [],
  idProperties: [],
  titleProperties: [],
}

export const blankEntityProperty: EntityProperty = {
  EntityPropertyId: -1,
  EntityPropertyName: '',
  PropertyName: '',
  PropertyType: '',
  DisplayName: '',
  DisplayDescription: '',
  IsId: false,
  IsTitle: false,
  selectName: 'Choose a Field',
  EntityObject: blankEntityObject,
  IsKey: false,
}

export const blankEffectiveRolePermission: EffectiveRolePermission = {
  RoleId: -1,
  CanRead: false,
  CanUpdate: false,
  CanCreate: false,
  CanDelete: false,
  CanExecute: false,
}

export const blankEditor: Editor = {
  EditorId: -1,
  EditorName: '',
  EntityName: '',
  HideBlankFields: false,
  DisplayName: '',
  DisplayDescription: '',
  Sections: [],
  EntityObject: blankEntityObject,
  EffectivePermission: blankEffectiveRolePermission,
  Icon: '',
  fields: [],
}

export const blankEditorSection: EditorSection = {
  EditorSectionId: -1,
  EditorSectionName: '',
  DisplayName: '',
  DisplayDescription: '',
  OrderBy: 0,
  SectionType: '',
  Icon: '',
  Fields: [],
  EffectivePermission: blankEffectiveRolePermission,
  Editor: blankEditor,
  open: true,
}

export const blankEditorField: EditorField = {
  EditorFieldId: -1,
  EntityPropertyName: '',
  EditorType: '',
  DisplayName: '',
  DisplayDescription: '',
  OrderBy: -1,
  IsRequired: false,
  EditorSize: 0,
  EntityProperty: blankEntityProperty,
  PropertyEditor: blankEditor,
  EffectivePermission: blankEffectiveRolePermission,
  Validations: [],
  Values: [],
  DefaultValue: '',
  Section: blankEditorSection,
}
