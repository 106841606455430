<template>
  <div class="section" v-if="categories">
    <div class="container">
      <div class="row">
        <b-link
          class="col-lg-6 col-md-6 category-col"
          v-for="category in categories"
          :key="category.CategoryId"
          :to="getTopologyTo(catalogId, isPreview || false, category)"
        >
          <div class="shop">
            <div class="shop-img">
              <img
                @load="onImageLoad()"
                :src="(category.Images[0] ?? { ImagePath: COMING_SOON_IMG_PATH }).ImagePath + '?width=300&height=300'"
                :alt="category.DisplayName"
              />
              <div v-if="isImageLoading" class="image-loading">
                <b-icon icon="camera" class="camera-icon"></b-icon>
              </div>
            </div>
            <div class="shop-body">
              <h4>{{ category.DisplayName }}</h4>
            </div>
          </div>
        </b-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import MenuState, { Category } from '@/store/menu/types'
import { COMING_SOON_IMG_PATH } from '@/helpers/image.helper'

import MxSite from '@/mixins/site'

const nsMenu = namespace('menu')

@Component({
  components: {},
})
export default class extends Mixins(MxSite) {
  @nsMenu.State('categories')
  public state!: MenuState

  @nsMenu.Action('getMenu')
  public getMenu: any

  @nsMenu.Getter('categories')
  public categories!: Category[]

  public isImageLoading = true
  public onImageLoad() {
    this.isImageLoading = false
  }

  public mounted() {
    this.hideBreadcrumbDisplay(true)
  }

  public COMING_SOON_IMG_PATH = COMING_SOON_IMG_PATH
}
</script>

<style scoped>
.home-button {
  width: 100%;
  height: 130px;
}

.full-height {
  display: flex;
  flex-direction: column;
}

.fill {
  flex: 1;
}

.section {
  padding-top: 10px;
  padding-bottom: 30px;
}

@media (min-width: 1024px) {
  body {
    padding-top: 30px;
  }
}

.shop {
  position: relative;
  overflow: hidden;
  margin: 15px 0px;
}

.shop .shop-img {
  position: relative;
  background-color: #fff;
  z-index: -1;
  padding: 30px;
}

.shop .shop-img > img {
  width: 100%;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.shop:hover .shop-img > img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.shop .shop-body {
  background-color: #0b67b2;
  padding: 4px 8px;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-radius: 5px;
}

.shop .shop-body h4 {
  color: #fff;
}

.shop .shop-body .cta-btn {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
}

.image-loading {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  height: 300px;
  width: 300px;
  margin: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.camera-icon {
  font-size: 64px;
  color: lightgrey;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@media (min-width: 767px) {
  .category-col {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .shop {
    max-width: 300px;
  }
}

@media (max-width: 767px) {
  .shop-body {
    position: absolute;
    top: 0;
    width: 75%;
    padding: 30px;
    z-index: 10;
    text-shadow: 1px 1px 2px black;
  }
  .shop:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    width: 60%;
    background: #0b67b2;
    opacity: 0.9;
    -webkit-transform: skewX(-45deg);
    -ms-transform: skewX(-45deg);
    transform: skewX(-45deg);
  }

  .shop:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1px;
    width: 100%;
    background: #0b67b2;
    opacity: 0.9;
    -webkit-transform: skewX(-45deg) translateX(-100%);
    -ms-transform: skewX(-45deg) translateX(-100%);
    transform: skewX(-45deg) translateX(-100%);
  }
  .shop {
    max-height: 100px;
    margin: 10px 0px;
  }
  .shop .shop-body {
    padding: 10px;
    width: 60%;
    background-color: transparent;
  }

  .shop .shop-body h4 {
    font-size: x-large;
  }

  .shop .shop-body .cta-btn {
    display: none;
  }

  .shop .shop-img {
    padding: 0;
  }

  .shop .shop-img > img {
    width: 100px;
    float: right;
  }
}
</style>
