<template>
  <b-tr>
    <b-td v-if="hasHeaderSlot">
      <slot name="rowHeader" v-bind="model"></slot>
    </b-td>
    <b-td v-for="field in fields" :key="field.key">
      <template v-if="field.key === 'Inventory'">
        <modal-inventory :item="model"></modal-inventory>
      </template>
      <template v-else>
        {{ displayValue(field) }}
      </template>
    </b-td>
  </b-tr>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'
import _ from 'lodash'
import LoadingText from '@/components/utility/loading-text.vue'
import modalInventory from '@/components/inventory/modal-inventory.vue'

import MxSite from '@/mixins/site'

import { Model } from '@/store/model-viewer/types'
import { blankModel } from '@/store/model-viewer/constants'

@Component({
  components: {
    'loading-text': LoadingText,
    'modal-inventory': modalInventory,
  },
})
export default class extends MxSite {
  @Prop({ default: () => blankModel })
  public model!: Model

  @Prop({ default: () => [] })
  public fields!: any[]

  get hasHeaderSlot() {
    return (
      Boolean(this.$slots.rowHeader) ||
      Boolean(typeof this.$scopedSlots.rowHeader === 'function')
    )
  }

  public displayValue(field) {
    if (field.formatter !== undefined && _.isFunction(field.formatter)) {
      return field.formatter(this.model[field.column], field.key, this.model)
    } else {
      return this.model[field.column]
    }
  }
}
</script>
