<template>
  <div class="shopping-cart">
    <cart-items
      actionText="Continue Shopping"
      :showCheckout="true"
      @handleAction="$router.push({ name: 'catalog' })"
      :lineActions="lineActions"
    />
  </div>
</template>

<script lang="ts">
import CartItems from '@/components/cart/cart-items.vue'
import MxSite from '@/mixins/site'
import { LineAction } from '@/store/cart/constants'
import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    CartItems,
  },
})
export default class ShoppingCart extends Mixins(MxSite) {
  public lineActions = [LineAction.Delete]
}
</script>

<style scoped lang="scss">
.shopping-cart {
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
}
</style>
