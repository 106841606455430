<template>
  <b-row role="button" @click="CartClick">
    <b-col>
      <div class="Cart">
        {{ itemsQuantity }}
      </div>
      <b-icon
        class="cart-icon"
        :icon="itemsQuantity > 0 ? 'cart-fill' : 'cart'"
        aria-hidden="true"
      >
      </b-icon>
    </b-col>
  </b-row>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const nsCart = namespace('cart')

@Component({
  components: {},
})
export default class SearchComp extends Vue {
  @nsCart.Getter('itemsQuantity')
  public itemsQuantity!: number

  public CartClick() {
    this.$router.push({ name: 'cart' })
  }
}
</script>

<style scoped>
.Cart {
  background-color: orange;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
  position: absolute;
  color: white;
  font-weight: bolder;
  right: 10px;
  border: 1px solid #fff;
}

.cart-icon {
  font-size: 36px;
  color: white;
}
</style>
