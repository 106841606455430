import { ActionTree } from 'vuex'
import { Route } from 'vue-router'
import State, { Site, Division, Catalog } from './types'

import { Api } from '@/api/Api'

const api = new Api()

export const actions: ActionTree<State, any> = {
  setActiveRoute({ commit }, { route }: { route: Route }) {
    commit('setActiveRoute', { route })
  },
  getCatalogs({ commit }): any {
    commit('catalogsLoading')

    const url = '/site/Catalogs'

    return api.fetch({
      url,
    })
      .then(response => {
        const catalogs: Catalog[] = response.List
        commit('catalogsLoaded', { catalogs })
        return catalogs
      }).catch(() => {
        commit('catalogsError')
      })
  },
  getDivisions({ commit }): any {
    commit('divisionsLoading')

    const url = '/site/Divisions'

    return api.fetch({
      url,
    })
      .then(response => {
        const divisions: Division[] = response.List
        commit('divisionsLoaded', { divisions })
        return divisions
      }).catch(() => {
        commit('divisionsError')
      })
  },
  getSites({ commit }): any {
    commit('sitesLoading')

    const url = '/site'

    return api.fetch({
      url,
    })
      .then(response => {
        const sites: Site[] = response.Sites
        sites.forEach(site => { site.disabled = false })
        commit('sitesLoaded', { sites })
        return sites
      }).catch(() => {
        commit('sitesError')
      })
  },
  getSite({ commit }): any {
    commit('siteLoading')

    const url = '/site/' + location.hostname

    return api.fetch({
      url,
    })
      .then(response => {
        const site: Site = response
        commit('siteLoaded', { site })
        commit('setDivision', { divisionName: site.DivisionName })
        return site
      }).catch(() => {
        commit('siteError')
      })
  },
}
