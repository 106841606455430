import { ActionTree } from 'vuex'
import State, { Category } from './types'
import { RootState } from '../types'
import { Api } from '@/api/Api'

const api = new Api()
const getCatalogId = (rootState: RootState) => !rootState.site.CatalogId ? 5 : rootState.site.CatalogId

export const actions: ActionTree<State, RootState> = {
  getMenu({ commit, rootState }, { catalogId, isPreview }: { catalogId?: number, isPreview?: boolean }): any {
    const currentCatalogId = catalogId ?? getCatalogId(rootState)
    if (!currentCatalogId) return

    return api.fetch({
      url: '/menu/' + (catalogId ?? getCatalogId(rootState)) + '?isPreview=' + (isPreview ?? false),
    })
      .then(response => {
        const payload: Category[] = response.Categories
        commit('menuLoaded', payload)
        return payload
      }).catch(() => {
        commit('menuError')
      })
  },
}
