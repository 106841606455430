<template>
  <div v-if="site.SiteId >= 0">
    <sales-rep-header v-if="['customerportal', 'orders'].includes(site.Name) && catalogId === null" />
    <customer-admin-header v-else-if="_isCustomerAdminSite() && catalogId === null" />
    <request-header v-else-if="['cr'].includes(site.Name) && catalogId === null" />
    <main-header v-else-if="catalogId" />
    <main-header v-else />
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import { State, namespace } from 'vuex-class'
import { Category } from '@/store/menu/types'
import SearchInput from '../search/search-input.vue'
import LoadingText from '@/components/utility/loading-text.vue'
import CartComponent from '../cart/cart-component.vue'
import LoginComponent from '@/components/security/login.vue'

import MxSite from '@/mixins/site'
import SaleRepHeader from '@/components/utility/sale-rep-header.vue'
import MainHeader from '@/components/utility/main-header.vue'
import CustomerAdminHeader from '@/components/utility/customer-admin-header.vue'
import RequestHeader from '@/components/utility/request-header.vue'
import { isCustomerAdminSite } from '@/helpers/sites.helper'

const nsMenu = namespace('menu')

@Component({
  components: {
    SearchInput,
    LoadingText,
    CartComponent,
    'c-login': LoginComponent,
    'sales-rep-header': SaleRepHeader,
    'customer-admin-header': CustomerAdminHeader,
    'request-header': RequestHeader,
    'main-header': MainHeader,
  },
})
export default class extends Mixins(MxSite) {
  @nsMenu.State('version')
  private version!: number

  @nsMenu.State('hidden')
  private hidden!: boolean

  @nsMenu.Mutation('setVersion')
  private setVersion!: any

  @nsMenu.Getter('categories')
  private categories!: Category[]

  @nsMenu.Getter('activeCategory')
  private activeCategory!: Category

  @State('isLoginAllowed')
  private isLoginAllowed!: boolean

  public _isCustomerAdminSite() {
    return isCustomerAdminSite(this.role)
  }
}
</script>

<style>
/* note: couldn't target the .dropdown-menu from any attribute */
.tpi-menu-bar .dropdown-menu {
  width: 100%;
}

.tpi-bnav-bar {
  background-color: #ffffff;
}
</style>

<style scoped>
.nav-item {
  position: static;
  display: inline-block;
}

@media (max-width: 375px) {
  .navbar-brand {
    max-width: 80%;
    margin-right: 0;
  }
  .navbar-brand img {
    max-width: 100%;
  }
}

/*

.product-class {
  position: absolute;
  width: 100%;
  top: 100%;
}

.navbar {
  min-height: 50px;
  margin-bottom: 20px;
  z-index: 9000;
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0);
  font-weight: 500;
}
.nav-item {
  position: static;
}
.bg-gray {
  background: #e8e8e8;
}
.dropdown-menu {
  max-height: 500px;
  overflow-y: auto;
  min-width: 200px;
}
.dropdown-menu.columns-3 {
  min-width: 600px;
}
.dropdown-menu.columns-4 {
  width: 100%;
}
.dropdown-menu li a {
  padding: 5px 15px;
  font-weight: 300;
}

.multi-column-dropdown {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.multi-column-dropdown li a {
  display: block;
  clear: both;
  line-height: 1.428571429;
  color: #333;
  white-space: normal;
}
.multi-column-dropdown li a:hover {
  text-decoration: none;
  color: #262626;
  background-color: #999;
}

@media (max-width: 767px) {
  .dropdown-menu.multi-column {
    min-width: 240px !important;
    overflow-x: hidden;
  }
} */
</style>
