<template>
  <div>
    <!-- Section Title and Icon -->
    <div class="d-flex align-items-center">
      <h5 class="my-2">
        <b>Order Review</b>
      </h5>
      <b-button
        variant="link"
        @click="isExpanded = !isExpanded"
        :aria-expanded="isExpanded"
        aria-controls="order-summary"
        class="ml-auto text-dark p-0"
      >
        <b-icon :icon="isExpanded ? 'chevron-up' : 'chevron-down'" />
      </b-button>
    </div>
    <!-- Summary Details Section -->
    <b-collapse id="order-summary" v-model="isExpanded">
      <div class="border-bottom pb-3 mb-2">
        <!-- Subtotal Row -->
        <div class="d-flex justify-content-between">
          <span>Subtotal</span>
          <span>{{ formatCost(subtotal) }}</span>
        </div>

        <!-- Shipping Row -->
        <div class="d-flex justify-content-between">
          <span>Shipping</span>
          <span>{{ formatCost(shipping) }}</span>
        </div>
      </div>

      <!-- Grand Total Row -->
      <div class="d-flex justify-content-between font-weight-bold mt-3">
        <span>Estimated Total Before Tax</span>
        <span>{{ formatCost(grandTotal) }}</span>
      </div>
    </b-collapse>

    <form @submit.prevent="submit(orderComment)">
      <!-- Order Comment Section -->

      <div class="my-3">
        <label for="order-comment" class="text-muted small"
          >Order Comment</label
        >
        <textarea
          id="order-comment"
          class="form-control"
          rows="2"
          v-model="orderComment"
          placeholder="Type here..."
        ></textarea>
      </div>

      <!-- Privacy & Terms Policy Checkbox -->
      <div class="d-flex align-items-center">
        <input
          type="checkbox"
          class="mr-3"
          v-model="privacyAndTermsPolicy"
          required
        />
        <div class="text-muted small">
          Please check to acknowledge our
          <a href="https://www.tpicorp.com/legal-statement.html" target="_blank"
            >Privacy &amp; Terms Policy</a
          >.
        </div>
      </div>
      <!-- Validation Errors-->
      <ul
        class="my-2"
        v-for="(error, errorLabel) in validationErrors"
        :key="errorLabel"
        style="color: #cd0200;"
      >
        <template v-if="Object.keys(error).length > 0">
          <li>
            <span class="d-flex justify-content-between align-items-center">
              <b>{{ errorLabel}} Errors</b>
              <b-badge variant="danger" pill>
                {{ Object.keys(error).length }}
              </b-badge>
            </span>
            <ul v-for="(value, key) in error" :key="key">
              <li>{{ value }}</li>
            </ul>
          </li>
        </template>
      </ul>
      <!-- Pay Button -->
      <b-button
        block
        variant="primary"
        class="my-3 background-color-primary"
        type="submit"
      >
        Pay {{ formatCost(grandTotal) }}
      </b-button>
      <b-button
        variant="link p-0"
        size="sm"
        :aria-expanded="showReturn ? 'true' : 'false'"
        aria-controls="return-policy-collapse"
        @click="showReturn = !showReturn"
        class="mb-2"
      >
        Return Policy
        <b-icon :icon="showReturn ? 'chevron-up' : 'chevron-down'"></b-icon>
      </b-button>
      <b-collapse id="return-policy-collapse" v-model="showReturn">
        <p>We cannot ship to a P.O Box - We must have physical address</p>
        <p>Please contact factory at:</p>
        <b-list-group>
          <b-list-group-item>
            <b-icon icon="envelope" class="mr-2"></b-icon
            ><a href="mailto:parts@tpicorp.com"
              >parts@tpicorp.com</a
            ></b-list-group-item
          >
          <b-list-group-item>
            <b-icon icon="telephone" class="mr-2"></b-icon
            ><a href="tel:+18006823398">1-800-682-3398</a>
          </b-list-group-item>
          <b-list-group-item class="d-flex align-items-center">
            <b-icon icon="clock" class="mr-2"></b-icon>
            <div>
              Business Hours <br />
              Monday - Friday <br />
              8:00am - 5:00pm EST.
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </form>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const nsOrders = namespace('orders')

@Component({})
export default class BillingSummary extends Vue {
  @Prop()
  public subtotal!: number

  @Prop()
  public shipping!: number

  @Prop()
  public tax!: number

  @Prop()
  public formatCost!: (cost: number) => string

  @Prop()
  public submit!: (comment: string) => void

  @nsOrders.Getter('validationErrors')
  public validationErrors!: []

  public showReturn = false
  public orderComment = ''
  public privacyAndTermsPolicy = false
  public isExpanded = true

  public toggleShowReturn() {
    this.showReturn = !this.showReturn
  }

  public get grandTotal(): number {
    return this.subtotal + this.shipping
  }
}
</script>
