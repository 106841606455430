<template>
  <b-modal
    :size="isMobile ? 'xl' : 'lg'"
    v-model="show"
    id="order-confirmation-modal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
    hide-header
  >
    <div class="order-confirmation-container">
      <img :src="celebrationImg" class="order-confirmation-img" />
      <div class="order-text-container">
        <h4 class="order-confirmation-title">Your order is being processed.</h4>
        <span class="order-confirmation-subtitle mt-3">
          You will be receiving a confirmation email (<span
            class="confirmation-email"
            >{{ orderConfirmation.Email }}</span
          >) with order details
        </span>
      </div>
      <b-button
        block
        variant="outline-primary"
        class="primary-outline-btn btn-round"
        @click.prevent="handleViewOrderDetails"
      >
        View Order Details
      </b-button>
    </div>
  </b-modal>
</template>
<script lang="ts">
import MxSite from '@/mixins/site'
import { IOrderConfirmation } from '@/store/orders/types'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { isMobile, handleResize } from '@/common/helpers/responsive-helper'

@Component({})
export default class OrderConfirmation extends Mixins(MxSite) {
  public celebrationImg = require('@/assets/images/celebration.png')
  @Prop({ type: Object }) orderConfirmation!: IOrderConfirmation
  @Prop({ type: Boolean, required: true }) show!: string

  public isMobile = isMobile()

  public created() {
    handleResize((isMobile) => {
      this.isMobile = isMobile
    })
  }

  public handleViewOrderDetails() {
    if (this.orderConfirmation.PublicKey) {
      this.$router.push({
        name: 'order_summary',
        params: { invoiceNo: this.orderConfirmation.PublicKey },
      })
    }
  }
}
</script>

<style scoped>
.order-confirmation-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
  height: 40vh;
  text-align: center;
}

.order-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.order-confirmation-title {
  font-weight: bold;
}

.confirmation-email {
  color: #0b67b2;
  font-weight: bold;
}

@media only screen and (max-width: 1024px) {
  .order-confirmation-container {
    height: auto !important;
  }
}
</style>
