import { search } from './search'
import { menu } from './menu'
import { cart } from './cart'
import { product } from './product'
import { cache } from './cache'
import { common } from './common'
import { orders } from './orders'
import { entity } from './entity'

export function getModules() {
  return {
    search,
    menu,
    product,
    cart,
    cache,
    common,
    orders,
    entity,
  }
}

export function getPersistModules() {
  return ['menu', 'cart', 'common']
}
