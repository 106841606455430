<template functional>
  <div>
    <div class="d-flex w-100 justify-content-center">
      <strong>Loading...</strong>
    </div>
    <div class="d-flex w-100 justify-content-center mb-3">
      <b-spinner small type="grow"></b-spinner>
      <b-spinner small type="grow"></b-spinner>
      <b-spinner small type="grow"></b-spinner>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
  },
})
export default class extends Vue {
}
</script>
