export function isMobile() {
  return window.innerWidth <= 767
}

export function handleResize(callback: (isMobile: boolean) => void) {
  window.addEventListener('resize', () => {
    const mobile = isMobile()
    callback(mobile)
  })
}
