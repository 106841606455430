<template>
    <div>
      <div v-for="(statusUpdate, index) in statusUpdates" :key="'tracking-status-update-' + statusUpdate.Id" class="step" :class="{ completed: index > 0, active: index == 0 }">
          <div class="v-stepper">
              <div class="circle"></div>
              <div v-if="index < statusUpdates.length - 1" class="line"></div>
          </div>

          <div class="content pb-3">
            <h5>{{ statusUpdate.Status }}</h5>
            <div class="secondary-info pb-2">{{ getFormatedDate(statusUpdate.Date) }}</div>
            <div class="pb-2">{{ statusUpdate.Message }}</div>
            <div v-if="statusUpdate.Address.City">{{ statusUpdate.Address.City }}, {{ statusUpdate.Address.State }}</div>
          </div>
      </div>
    </div>
</template>

<script lang="ts">
import moment from 'moment'
import MxSite from '@/mixins/site'
import { TrackingUpdate } from '@/store/orders/types'
import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: {
  },
})

export default class extends Mixins(MxSite) {
  @Prop()
  public statusUpdates!: TrackingUpdate[]

  public getFormatedDate(date: Date) {
    return moment.utc(date).local()
  }
}
</script>
<style>
  .secondary-info {
    font-size: 12px;
  }

  .pb-2 {
    padding-bottom: 2px;
  }

  .pb-3 {
    padding-bottom: 3px;
  }

  .step {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: cream;
  }

  .v-stepper {
    position: relative;
  }

  .step .circle {
    background-color: white;
    border: 3px solid gray;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: inline-block;
  }

  .step .line {
    top: 23px;
    left: 10px;
    height: 100%;
    position: absolute;
    border-left: 3px solid #3399f3;
  }

  .step.completed .circle {
    visibility: visible;
    background-color: #3399f3;
    border-color: #3399f3;
  }

  .step.active .circle {
    visibility: visible;
    border-color: #3399f3;
  }

  .content {
    margin-left: 20px;
    display: inline-block;
  }
</style>
